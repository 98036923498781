// PhysicianDataContext.js

import { createContext, useContext, useState } from 'react';
import { referringPhysician } from '../data';

const PhysicianDataContext = createContext();

export const usePhysicianData = () => useContext(PhysicianDataContext);

export const PhysicianDataProvider = ({ children }) => {
  const [physicianData, setPhysicianData] = useState(referringPhysician);
  const [editablePhysicianData, setEditablePhysicianData] = useState([]);

  return (
    <PhysicianDataContext.Provider value={{ physicianData, setPhysicianData, editablePhysicianData, setEditablePhysicianData }}>
      {children}
    </PhysicianDataContext.Provider>
  );
};


