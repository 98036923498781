import Divider from '../../components/Divider'
import ReportHeader from '../../components/ReportHeader'

export default function MCT_EventData() {
  return (
    <div className="min-h-screen">
      <ReportHeader
        boxInfo="EventData "
        startDate=" 01/01/2022 "
        patientInfo="Robert Downey/M/12/02/1985    "
        boxInfoBg="#FDE9E9"
      />
      <Divider bgColor="bg-[#A83232]" className="mb-6" />
      <div className="min-h-[500px] border-[2px] w-full border-[#A83232] rounded-lg mb-12 pt-9 px-6 pb-28 ">
        <div className="border-[.5px] border-[#231F20] min-h-[100px] rounded-md mb-10"></div>
        <div className="border-[.5px] border-[#231F20] min-h-[100px] rounded-md"></div>
      </div>
      <Divider bgColor="bg-[#A83232]" className="mb-6" />
    </div>
  )
}
