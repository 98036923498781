export function WriteAndDownloadFile(filename: string, content: string) {
  var element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(content)
  );
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export async function getFileFromUrl(
  url: any,
  name: any,
  defaultType = "image/jpeg"
) {
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, {
    type: data.type || defaultType,
  });
}

export function ConvertSecondsIntoDayTimeFormat(Seconds: any) {
  var DisplayTime = "";
  var TimeDistance = Seconds * 1000; // Convert to ms
  if (Seconds <= 0) {
    DisplayTime = "0d:0h:0m:0s";
  } else {
    //calculations for days, hours, minutes and seconds
    var days = Math.floor(TimeDistance / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (TimeDistance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((TimeDistance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((TimeDistance % (1000 * 60)) / 1000);

    DisplayTime = days + "d " + hours + "h " + minutes + "m " + seconds + "s";
  }
  return DisplayTime;
}

export function ConvertSecondsIntoTimeFormat(Seconds: any) {
  var DisplayTime = "";
  var TimeDistance = Seconds * 1000; // Convert to ms
  if (Seconds <= 0) {
    DisplayTime = "00:00:00";
  } else {
    //calculations for days, hours, minutes and seconds
    var days = Math.floor(TimeDistance / (1000 * 60 * 60 * 24));
    var hours: any = Math.floor(
      (TimeDistance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes: any = Math.floor(
      (TimeDistance % (1000 * 60 * 60)) / (1000 * 60)
    );
    var seconds: any = Math.floor((TimeDistance % (1000 * 60)) / 1000);

    if (hours < 10) {
      hours = "0" + hours;
    }

    if (minutes < 10) {
      minutes = "0" + minutes;
    }

    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    DisplayTime = hours + ":" + minutes + ":" + seconds;
  }
  return DisplayTime;
}

export function ConvertTimeFormatIntoSeconds(DisplayTime: any) {
  var splits = DisplayTime.split(":", 3);
  if (!splits) {
    return 0;
  }

  if (splits.length < 3) {
    return 0;
  }

  var Hours = Number(splits[0]);
  var Minutes = Number(splits[1]);
  var Seconds = Number(splits[2]);

  var TotalSeconds = Hours * 3600 + Minutes * 60 + Seconds;
  // console.log(
  //   "TotalSeconds: " +
  //     TotalSeconds +
  //     " , " +
  //     ConvertSecondsIntoTimeFormat(TotalSeconds)
  // );
  return TotalSeconds;
}

export const ECGClassification = {
  Sinus_Rhythm: 1, // Sinus Rhythm
  Sinus_bradycardia: 2, //Sinus bradycardia
  Sinus_Brady: 3, //Sinus Brady
  Sinus_Tachycardia: 4, //Sinus Tachycardia
  First_Degree_Heart_Block: 5, //1st Degree Heart Block
  Abberant_Conduction_Present: 6, //Abberant Conduction Present
  Third_Degree_Heart_Block: 7, //3rd Degree Heart Block
  Atrial_Fibrillation_with_Aberration: 8, // Atrial Fibrillation with Aberration
  Idioventricular_Rhythm: 9, //Idioventricular Rhythm
  Ventricular_Tachycardia_OR_SVT_with_aberration: 10, //Ventricular Tachycardia OR SVT with aberration
  Ventricular_Fibrillation: 11, //Ventricular Fibrillation
  Atrial_Fibrillation: 12, //Atrial Fibrillation
  Atrial_Flutter_Variable_Block: 13,//Atrial Flutter (Variable Block)
  Atrial_Flutter_Fixed_Block: 14, //Atrial Flutter (Fixed Block)
  Atrial_Tachycardia: 15, // Atrial Tachycardia
  Supraventricular_Tachycardia_SVT: 16, //Supraventricular Tachycardia (SVT)
  Junctional_Tachycardia: 17, //Junctional Tachycardia
  Junctional_Rhythm: 18, // Junctional Rhythm
  Accelerated_Junctional: 19, // Accelerated Junctional
  Asystole:20, //Asystole
  Junctional_Bradycardia: 21, //Junctional Bradycardia
  //---------new added -- new to check their tag value ------///
  Sinus_Arrhythmia: 22, //SinusArrhythmia
  Accelerated_Idio_ventricular_rhythm:23,
  ventricular_standstill : 24,
};

/**
  * Extract an ASCII string from an ArrayBuffer
  * @param {ArrayBuffer} buffer - the buffer
  * @param {Number} strLength - number of chars in the string we want
  * @param {Number} byteOffset - the offset in number of bytes
  * @return {String} the string, or null in case of error
  *
 function getString8FromBuffer( buffer: any, strLength: any, byteOffset=0 ){
  if( byteOffset < 0){
    console.warn("The byte offset cannot be negative.");
    return null;
  }

  if( !buffer || !(buffer instanceof ArrayBuffer)){
    console.warn("The buffer must be a valid ArrayBuffer.");
    return null;
  }

  if( (strLength + byteOffset) > buffer.byteLength ){
    console.warn("The string is too long to be writen in this buffer.");
    return null;
  }

  return String.fromCharCode.apply(null, new Uint8Array(buffer, byteOffset, strLength));
}

*/
