import ReportLayout from '../../components/ReportLayout'
import MTCPage1 from './MCTPage1'
import MTC_HeartRateDistribution from './MTC_HeartRateDistribution'
import MCT_DataTrends from './MCT_DataTrends'
import MCT_EventData from './MCT_EventData'
import MCT_Report from './MCT_Report'
import MCT_RV_RRInterval from './MCT_RV_RRInterval'
import MCT_DocumentedStrips from './MCT_DocumentedStrips'
export default function MCTSummaryReport() {
  return (
    <ReportLayout>
      <MTCPage1 />
      <MTC_HeartRateDistribution />
      <MCT_DataTrends />
      <MCT_EventData />
      <MCT_Report />
      <MCT_RV_RRInterval />
      <MCT_DocumentedStrips />
    </ReportLayout>
  )
}
