import axios from "axios";
const axiosAuth = axios.create();

//we intercept every requests
axiosAuth.interceptors.request.use(
  async function (config) {
    //anything you want to attach to the requests such as token
      config.headers.Authorization = "f2aaf5c49e07eeede061";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//we intercept every response
axiosAuth.interceptors.response.use(
  async function (response) {
    return response;
  },
  (error) => {
    //check for authentication or anything like that
    if (error.response.status === 401) {

    }
    return Promise.reject(error);
  }
);

export default axiosAuth;
