export class Annotation {
  private ID: any;
  private DataType: any;
  private Type: any;
  private Date: any;
  private StartTime: any;
  private Duration: any;
  private Beats: any;
  private BPM: any;
  private Report: any;
  private ReportOption: any;
  private MCTEvent: any;

  constructor(ID: any,DataType: any,Type: any,Date: any,StartTime: any,Duration: any,Beats: any,BPM: any,Report: any,ReportOption: any,MCTEvent: any) {
    this.ID = ID;
    this.DataType = DataType;
    this.Type = Type;
    this.Date = Date;
    this.StartTime = StartTime;
    this.Duration = Duration;
    this.Beats = Beats;
    this.BPM = BPM;
    this.Report = Report;
    this.ReportOption = ReportOption;
    this.MCTEvent = MCTEvent;
  }

  getBPM(): string {
    return `${this.BPM}`;
  }

  getBPMWithUnit(): string {
    return `${this.BPM}bpm`;
  }

}




