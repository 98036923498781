import axios from "axios";

// const API_URL = 'http://localhost:3005/v1'
const API_URL = "https://9lvgksq7s2.execute-api.ap-south-1.amazonaws.com/v1";
const instance = axios.create({
  baseURL: API_URL,
});


export const createFileEntry = (body: any) => {
  return instance.post("/file-entries", body);
};
export const getAllFileEntries = () => instance.get("/file-entries");

export const getFileEntryById = (id: string) =>
  instance.get(`/file-entries/${id}`);

export const getAnnotations = (params: any) =>
  instance.get(`/annotations`, {
    params,
  });

export const getReportHTML = (data: any) => instance.post(`/report`, data);

export const getEventAnnotations = (fileId: string,body: any) => {
  return instance.post("/"+fileId+"/annotations", body);
};

export const getECGValuesByIdAndSETime = (fileId: string,params: any) =>
  instance.get(`/file-entries/${fileId}/ecg`, {
    params,
  });

  export const getBPMMinutes= (fileId: string,params: any) =>
    instance.get(`/file-entries/${fileId}/bpmMinutes`, {
      params,
    });