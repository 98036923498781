import React, { useEffect, useState } from "react";
import ECG from "./Graphs/ECG";
import Ectopy from "./Graphs/Ectopy";
type TemplatesProps = {
  templatesShowHide: boolean;
  mmvValueSplitView: any;
  selectedDataSetAll: any;
  ECGTemplates: any;
  AllEctopies: any;
  isEDFData: any;
  GraphColors: {
    DefaultLineColor: string,
    VELineColor: string,
    SVELineColor: string,
  };
  getBeatDataInRangeEDF: Function;
  ShowHideTemplates: Function;
  ViewMeasurement: Function;
};

function Templates(props: TemplatesProps) {
  const [showEctopyCharts, setshowEctopyCharts] = useState(false);

  const [showaccordionVE, SetshowaccordionVE] = useState(true);
  const ShowHideAccordionVE = () => {
    SetshowaccordionVE(true);
    SetshowaccordionSVE(false);
  };

  const [showaccordionSVE, SetshowaccordionSVE] = useState(false);
  const ShowHideAccordionSVE = () => {
    SetshowaccordionSVE(true);
    SetshowaccordionVE(false);
  };

  const TemplateBoxType = {
    VE: 1,
    SVE: 2,
  };

  const [BoxType, SetBoxType] = useState(1);
  const [BoxTypeName, SetBoxTypeName] = useState("VE");
  const [ClickedBoxInfo, SetClickedBoxInfo] = useState("");
  const [TemplateVType, SetTemplateVType] = useState(1);
  const [TemplateClass, SetTemplateClass] = useState(1);

  const [ShowBeatView, SetShowBeatView] = useState(false);
  const ShowHideBeatView = () => {
    SetShowBeatView(!ShowBeatView);
  };

  const [beatView, setBeatView] = useState(4);

  function setBeatValue(p_intValue: number) {
    setBeatView(p_intValue);
    SetShowBeatView(!ShowBeatView);
  }

  function GetVTAndSVECount(p_intType: number) {
    var l_count = 0;
    for (let i = 0; i < props.ECGTemplates.length; i++) {
      if (props.ECGTemplates[i].VType === p_intType) {
        l_count++;
      }
    }
    return l_count;
  }

  return (
    <div className="ecg--templates">
      {showEctopyCharts && (
        <div
          className={`ecg--templates--right ${
            beatView === 6 ? "expand--right" : ""
          }`}
        >
          <div className="templates--left--inn">
            <div className="template--close__head">
              <div className="template--selection template--selection__view">
                <a>
                  {BoxTypeName}, {ClickedBoxInfo}
                </a>
              </div>
              <div className="beats--views">
                <div className="beats--views__inn">
                  <a className="beats--view__open" onClick={ShowHideBeatView}>
                    <img
                      src="/images/gear.svg"
                      width="20px"
                      height="20px"
                      alt="down"
                    />
                  </a>
                  {ShowBeatView ? (
                    <div className="beats--view__drop">
                      <a
                        onClick={() => setBeatValue(1)}
                        className={`${beatView === 1 ? "SelectedView" : ""}`}
                      >
                        Beat View 1x
                      </a>
                      <a
                        onClick={() => setBeatValue(2)}
                        className={`${beatView === 2 ? "SelectedView" : ""}`}
                      >
                        Beat View 2x
                      </a>
                      <a
                        onClick={() => setBeatValue(3)}
                        className={`${beatView === 3 ? "SelectedView" : ""}`}
                      >
                        Beat View 3x
                      </a>
                      <hr />
                      <a
                        onClick={() => setBeatValue(4)}
                        className={`${beatView === 4 ? "SelectedView" : ""}`}
                      >
                        Event View 1x
                      </a>
                      <a
                        onClick={() => setBeatValue(5)}
                        className={`${beatView === 5 ? "SelectedView" : ""}`}
                      >
                        Event View 2x
                      </a>
                      <a
                        onClick={() => setBeatValue(6)}
                        className={`${beatView === 6 ? "SelectedView" : ""}`}
                      >
                        Event View 3x
                      </a>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>

            <Ectopy
              AllEctopies={props.AllEctopies}
              TemplateVType={TemplateVType}
              TemplateClass={TemplateClass}
              selectedDataSetAll={props.selectedDataSetAll}
              beatView={beatView}
              mmvValueSplitView={props.mmvValueSplitView}
              getBeatDataInRangeEDF={props.getBeatDataInRangeEDF}
              ViewMeasurement={props.ViewMeasurement}
              isEDFData={props.isEDFData}
              GraphColors={props.GraphColors}
            />
          </div>
        </div>
      )}
      <div className="ecg--templates--left">
        <div className="template--close__head">
          <div className="template--selection">
            <a
              className={` ${showaccordionVE ? "accord--show" : ""}`}
              onClick={ShowHideAccordionVE}
            >
              VE, {GetVTAndSVECount(1)}
            </a>
            <a
              className={` ${showaccordionSVE ? "accord--show" : ""}`}
              onClick={ShowHideAccordionSVE}
            >
              SVE, {GetVTAndSVECount(2)}
            </a>
          </div>
          <a
            className="template--close"
            onClick={() => props.ShowHideTemplates(false, false, false)}
          >
            <img src="/images/close.svg" alt="cross" width="9" height="9" />
          </a>
        </div>
        <div
          className="templates--left--inn"
          onClick={() => setshowEctopyCharts(true)}
        >
          {showaccordionVE ? (
            <ECG
              BoxType={TemplateBoxType.VE}
              ECGTemplates={props.ECGTemplates}
              AllEctopies={props.AllEctopies}
              mmvValueSplitView={props.mmvValueSplitView}
              TemplateVType={TemplateVType}
              TemplateClass={TemplateClass}
              templatesShowHide={props.templatesShowHide}
              SetBoxTypeName={SetBoxTypeName}
              SetBoxType={SetBoxType}
              SetClickedBoxInfo={SetClickedBoxInfo}
              SetTemplateVType={SetTemplateVType}
              SetTemplateClass={SetTemplateClass}
              showEctopyCharts={showEctopyCharts}
            />
          ) : (
            <></>
          )}
        </div>

        <div
          className="templates--left--inn"
          onClick={() => setshowEctopyCharts(true)}
        >
          {showaccordionSVE ? (
            <ECG
              BoxType={TemplateBoxType.SVE}
              ECGTemplates={props.ECGTemplates}
              AllEctopies={props.AllEctopies}
              mmvValueSplitView={props.mmvValueSplitView}
              TemplateVType={TemplateVType}
              TemplateClass={TemplateClass}
              templatesShowHide={props.templatesShowHide}
              SetBoxTypeName={SetBoxTypeName}
              SetBoxType={SetBoxType}
              SetClickedBoxInfo={SetClickedBoxInfo}
              SetTemplateVType={SetTemplateVType}
              SetTemplateClass={SetTemplateClass}
              showEctopyCharts={showEctopyCharts}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default Templates;
