import React, { useState, useRef, useEffect } from "react";
import * as d3 from "d3";
import * as Utility from "../../Shared/Utility";
import { verify } from "crypto";

type HREventChartProps = {
  beatDivShowHide: boolean;
  mmsValueSplitView: any;
  globalW: any;
  SelectedDataSet: any;
  BPMDataSet: any[];
  DataStartDate: Date;
  DataEndDate: Date;
  handleMoveGraph: Function;
  setintervalSplitView: Function;
  SelectedTime: any;
  lastClick: any;
  setlastClick: Function;
  AppArea: any;
  afibData: any;
  sinusData: any;
  highestHR: any;
  lowestHR: any;
  summary:any;
};

function HREventChart(props: HREventChartProps) {
  const svgBPMGraphRef = useRef<any>();
  const [clickPositionX, setclickPositionX] = useState(-1);
  const [sliderValue, setSliderValue] = useState(0);
  const [hours, setHours] = useState("");

  //CheckMaxHR
  const [checkMaxHR, setcheckMaxHR] = useState(false);
  function checkMaxHRClick() {
    setcheckMaxHR(!checkMaxHR);
  }
  const [checkMinHR, setcheckMinHR] = useState(false);
  function checkMinHRClick() {
    setcheckMinHR(!checkMinHR);
  }
  const [checkAfib, setcheckAfib] = useState(false);
  function checkAfibClick() {
    setcheckAfib(!checkAfib);
  }
  const [checkSinus, setcheckSinus] = useState(false);
  function checkSinusClick() {
    setcheckSinus(!checkSinus);
  }

  const [zoomActive, setzoomActive] = useState(true);
  const zoomActiveRef = useRef<any | null>(null);
  zoomActiveRef.current = zoomActive;

  function changeToPointer() {
    setzoomActive(false);
  }
  function changeToZoom() {
    setzoomActive(true);
  }

  const [currentHRTransform, setcurrentHRTransform] = useState({
    k: 1,
    x: 0,
    y: 0,
  });
  const currentHRTransformRef = useRef<any | null>(null);
  currentHRTransformRef.current = currentHRTransform;

  const [dragStartedHR, setdragStartedHR] = useState(false);
  const dragStartedHRRef = useRef<any | null>(null);
  dragStartedHRRef.current = dragStartedHR;

  const [dragStartHRX, setdragStartHRX] = useState(0);
  const dragStartHRXRef = useRef<any | null>(null);
  dragStartHRXRef.current = dragStartHRX;

  const [gridWidth, setgridWidth] = useState(0);
  const gridWidthRef = useRef<any | null>(null);
  gridWidthRef.current = gridWidth;

  const [zoomedIndex, setzoomedIndex] = useState(-1);
  const zoomedIndexRef = useRef<any | null>(null);
  zoomedIndexRef.current = zoomedIndex;

  let svgLeft = 0;
  let svgTop = 0;
  let range = 0;
  let margin = { top: 10, right: 40, bottom: 25, left: 30 };

  function getSVGx(x: any) {
    return x - svgLeft;
  }

  function getSVGy(y: any) {
    return y - svgTop;
  }

  function getTimeFromXPixel(x: any) {
    let graphX = x - margin.left;
    // console.log("range : " + range);
    // console.log(
    //   "currentHRTransformRef.current.x : " + currentHRTransformRef.current.x
    // );
    // console.log(
    //   "currentHRTransformRef.current.k : " + currentHRTransformRef.current.k
    // );
    // console.log("gridWidthRef.current : " + gridWidthRef.current);
    // console.log("graphX : " + graphX);
    var refinedTransformX = currentHRTransformRef.current.x;
    if (refinedTransformX < 0) {
      refinedTransformX =
        currentHRTransformRef.current.x +
        margin.left / currentHRTransformRef.current.k;
    }
    //console.log("refinedTransformX : " + refinedTransformX);
    // console.log("range in getTimeFromXPixel : " + range);
    let newStartTime = Math.floor(
      ((graphX - refinedTransformX) / gridWidthRef.current) * range
    );
    return newStartTime;
  }

  function getXPixelFromTime(inputTime: any) {
    let x = 0;

    x = (inputTime / range) * gridWidthRef.current;

    return x + margin.left;
  }

  const MouseDown = (e: any) => {
    console.log(zoomActiveRef.current);
    if (!zoomActiveRef.current) {
      let localX = getSVGx(e.pageX);
      // console.log("localX : " + localX);
      setclickPositionX(localX);
      let newStartTime = getTimeFromXPixel(localX);
      // console.log("newStartTime : " + newStartTime);
      props.handleMoveGraph(newStartTime, 0);
      props.setlastClick(props.AppArea.BPMGraph);
    }
  };

  function getNearestIndexFromGridX(x: any) {
    let newStartTime = getTimeFromXPixel(x);
    let index = Math.floor(newStartTime /*/ 6*/);
    if (index >= 0 && index < props.BPMDataSet.length) {
      return index;
    }
    return -1;
  }

  function zoomed(event: any) {
    if (zoomActiveRef.current) {
      const { transform } = event;
      // console.log("trasform in zoomed");
      // console.log(transform);
      let newZoom = transform.k;
      let log2OfZoom = Math.log2(newZoom);
      let newSliderPosition = log2OfZoom * 20;

      setcurrentHRTransform(transform);

      setSliderValue(newSliderPosition);
    }
  }

  // Update zoom when slider value changes by either +,- or by sliding
  useEffect(() => {
    let powerOf2 = sliderValue / 20;
    let newZoom = Math.pow(2, powerOf2);

    let newX = (-gridWidthRef.current * (newZoom - 1)) / 2.0;

    let newTransform = {
      k: newZoom,
      x: newX,
      y: 0,
    };

    setcurrentHRTransform(newTransform);
  }, [sliderValue]);

  function updateRangeDisplay(rangeInMilliSeconds: number) {
    // console.log(rangeInMilliSeconds);
    var newRangeDisplay = "";
    if (rangeInMilliSeconds >= 3600000) {
      let hourCount = Math.round(rangeInMilliSeconds / 3600000);
      newRangeDisplay = hourCount + " hours";
    } else if (rangeInMilliSeconds >= 60000) {
      let minuteCount = Math.round(rangeInMilliSeconds / 60000);
      if (minuteCount === 60) {
        newRangeDisplay = "1 hour";
      } else {
        newRangeDisplay = minuteCount + " mins";
      }
    } else {
      let secondCount = Math.round(rangeInMilliSeconds / 1000);
      newRangeDisplay = secondCount + " seconds";
    }
    //console.log(newRangeDisplay);
    setHours(newRangeDisplay);
  }

  // This is for drawing the HRTrends view
  useEffect(() => {
    //  if(moveStartChanged) {
    //  if (moveStartTime > 0) {
    //    props.handleMoveGraph(moveStartTime, 0);
    //  }
    //}
    // console.log("HREventChart data check");
    // console.log(props.BPMDataSet);
    if (props.BPMDataSet && props.BPMDataSet.length > 0) {
      //      var BottomDivWidth = document.getElementById("BPMGraphDiv")?.clientWidth;
      let w = props.globalW - 20;
      let heightOfSVG = 160;
      let maxHR = 200;
      //      if (BottomDivWidth) {
      //        w = BottomDivWidth - 10;
      //      }
      let localGridWidth = w - (margin.left + margin.right);
      setgridWidth(localGridWidth);

      let localIntervalSplitView = w / (props.mmsValueSplitView * 5);
      props.setintervalSplitView(localIntervalSplitView);

      // console.log("drawing chart");
      //  console.log("w: " + w + " , gridWidth: " + localGridWidth);
      let svgBPMGraph = d3
        .select(svgBPMGraphRef.current)
        .attr("width", w) //globalW
        .attr("height", heightOfSVG)
        //.on("mousemove", MouseMove)
        .on("mousedown", MouseDown)
        .style("overflow", "hidden");
      // .on("wheel", function (d: any) {
      //   console.log("wheel delta start");
      //   console.log(d.wheelDelta);
      //   console.log("wheel delta end");
      // var direction = d3.event.wheelDelta < 0 ? 'down' : 'up';
      // zoom(direction === 'up' ? d : d.parent);
      //        });
      //        .on("mousedown", MouseDown);
      //        .style("overflow", "hidden");

      let boundingRect: any = document
        .getElementById("bpmGraphSVG")
        ?.getBoundingClientRect();

      svgLeft = boundingRect.left;
      svgTop = boundingRect.top;

      var startIndex = 0;
      var endIndex = props.BPMDataSet.length - 1;

      //  let totalPoints = props.BPMDataSet.length;
      let totalTime =
        props.BPMDataSet[props.BPMDataSet.length - 1]._id -
        props.BPMDataSet[0]._id;
      // console.log("totalTime : " + totalTime);
      // console.log("first point : " + props.BPMDataSet[0][0]);
      // console.log(
      //   "last point : " + props.BPMDataSet[props.BPMDataSet.length - 1][0]
      // );

      if (currentHRTransformRef.current.k > 1) {
        let timeToShow = Math.floor(
          totalTime / currentHRTransformRef.current.k
        );

        let spaceForOneTimeUnit = localGridWidth / timeToShow;
        let startTimeAdjustment = Math.floor(
          currentHRTransformRef.current.x / spaceForOneTimeUnit
        );

        let startTimeAdjustmentAbs = Math.abs(startTimeAdjustment);
        var newStartIndex = 0;
        while (
          newStartIndex < props.BPMDataSet.length - 1 &&
          props.BPMDataSet[newStartIndex]._id <= startTimeAdjustmentAbs
        ) {
          newStartIndex++;
        }

        startIndex = newStartIndex;
        let endTimeToShow = props.BPMDataSet[newStartIndex]._id + timeToShow;
        var newEndIndex = newStartIndex;
        while (
          newEndIndex < props.BPMDataSet.length - 1 &&
          props.BPMDataSet[newEndIndex]._id <= endTimeToShow
        ) {
          newEndIndex++;
        }
        endIndex = newEndIndex;

        if (startIndex < 0) {
          startIndex = 0;
        }
        if (endIndex > props.BPMDataSet.length - 1) {
          endIndex = props.BPMDataSet.length - 1;
        }
      }

      //      console.log("startIndex : " + startIndex + " , endIndex : " + endIndex);

      range = props.BPMDataSet[endIndex]._id - props.BPMDataSet[startIndex]._id;

      // setting the scaling
      let xScale = d3
        .scaleLinear()
        .domain([
          props.BPMDataSet[startIndex]._id,
          props.BPMDataSet[endIndex]._id,
        ])
        .range([margin.left, w - margin.right]);

      let yScale = d3
        .scaleLinear()
        .domain([0, maxHR])
        .range([heightOfSVG - margin.bottom, margin.top]);

      let StartDateTime = new Date(props.DataStartDate);

      StartDateTime.setSeconds(props.BPMDataSet[startIndex]._id*60);

      let EndDateTime = new Date(props.DataEndDate);
      EndDateTime.setSeconds(props.BPMDataSet[endIndex]._id*60 + 1);

      // Find the Range being shown and set the Range shown
      let rangeInMilliSeconds = EndDateTime.getTime() - StartDateTime.getTime();
      updateRangeDisplay(rangeInMilliSeconds);

      // Making another scale to show the x axis - not using this as the main scale since converting seconds to datetime would be inefficient
      let xScaleTime = d3
        .scaleTime()
        .domain([StartDateTime, EndDateTime])
        .range([margin.left, w - margin.right]);

      d3.selectAll(".bpmGraphView").remove();
      d3.selectAll("#bpmGraphViewLeftAxisEvent").remove();
      d3.selectAll("#bpmGraphViewBottomAxisEvent").remove();

      svgBPMGraph
        .append("g")
        .attr("id", "bpmGraphViewLeftAxisEvent")
        .attr("transform", `translate(${margin.left},0)`)
        .call(d3.axisLeft(yScale).ticks(2));

      svgBPMGraph
        .append("g")
        .attr("id", "bpmGraphViewBottomAxisEvent")
        .attr("transform", `translate(0,${heightOfSVG + -margin.bottom})`)
        .call(d3.axisBottom(xScaleTime));

      // Start of Zoom area
      var zoom = d3
        .zoom()
        .scaleExtent([1, 32])
        .extent([
          [margin.left, 0],
          [w - margin.right, heightOfSVG],
        ])
        // .translateExtent([
        //   [margin.left, -Infinity],
        //   [w - margin.right, Infinity],
        // ])
        .on("zoom", zoomed);

      svgBPMGraph.call(zoom);

      // End of Zoom area

      d3.selectAll(".gridlineBPMEvent").remove();
      // add the gridlines
      svgBPMGraph
        .selectAll(".gridlineBPMEvent")
        .data(yScale.ticks())
        .enter()
        .append("line")
        .attr("class", "gridlineBPMEvent")
        .attr("x1", margin.left)
        .attr("y1", (d: number) => yScale(d))
        .attr("x2", margin.left + localGridWidth)
        .attr("y2", (d: number) => yScale(d))
        .attr("stroke", "#EEE");

      if (
        props.BPMDataSet &&
        props.BPMDataSet.length > 1 &&
        endIndex >= startIndex
      ) {
        let filteredData = props.BPMDataSet.filter(
          (_, index) => index >= startIndex && index <= endIndex
        );

        // Draw the dots
        // svgBPMGraph
        //   .selectAll("circle")
        //   .raise()
        //   .data(filteredData)
        //   .enter()
        //   .append("circle")
        //   .attr("class", "bpmGraphView")
        //   .attr("cx", (d: any) => xScale(d[0]))
        //   .attr("cy", (d: any) => yScale(d[1]))
        //   .attr("r", 0.4);

        // var lastSkipped = false;
        // var lastValue = -10000;
        // for (let i = 0; i < filteredData.length; i++) {
        //   if (
        //     Math.abs(filteredData[i][1] - lastValue) < 20000 &&
        //     !lastSkipped
        //   ) {
        //     lastSkipped = true;
        //   } else {
        //     svgBPMGraph
        //       .append("circle")
        //       .attr("class", "bpmGraphView")
        //       .attr("cx", xScale(filteredData[i][0]))
        //       .attr("cy", yScale(filteredData[i][1]))
        //       .attr("r", 0.4);

        //     lastSkipped = false;
        //   }
        //   lastValue = filteredData[i][1];
        // }

        let skipCount = 0;
        let skip = 0;
        // if (filteredData.length > 40000) {
        //   skipCount = 4;
        // } else if (
        //   filteredData.length < 40000 &&
        //   filteredData.length >= 30000
        // ) {
        //   skipCount = 3;
        // } else if (
        //   filteredData.length < 30000 &&
        //   filteredData.length >= 20000
        // ) {
        //   skipCount = 2;
        // }
        // else if (filteredData.length < 20000 &&
        //   filteredData.length >= 10000) {
        //   skipCount = 1;
        // } else if (filteredData.length < 10000) {
        //   skipCount = 0;
        // }

        for (let i = 0; i < filteredData.length; i++) {
          if (skip !== skipCount) {
            skip++;
          } else {
            svgBPMGraph
              .append("circle")
              .attr("class", "bpmGraphView")
              .attr("cx", xScale(filteredData[i]._id))
              .attr("cy", yScale(filteredData[i].avgBPM))
              .attr("r", 0.4);

            skip = 0;
          }
        }
        // Since there is data, there would be a Day and/or Night - Work out the details
        let filteredDataLength = filteredData.length;
        let startFloor = Math.floor(filteredData[0]._id / 360);
        let endFloor = Math.floor(
          filteredData[filteredDataLength - 1]._id / 360
        );
        let startMod = filteredData[0]._id % 360;
        let endMod = filteredData[filteredDataLength - 1]._id % 360;
        let totalTime =
          filteredData[filteredDataLength - 1]._id - filteredData[0]._id;
        // console.log("totalTime : " + totalTime);
        //    console.log("startFloor: " + startFloor + ", endFloor: " + endFloor);
        //    console.log("startMod: " + startMod + ", endMod: " + endMod);
        // console.log("filteredDataLength: " + filteredDataLength);
        let pixelsPerUnitTime = localGridWidth / totalTime;

        var allZones = [];
        // Each Zone has its StartPoint, EndPoint, Type
        //  var newZone = { startPoint: 0, endPoint: 0, type: "Night" };
        var currentStartPoint = 0;
        var currentEndPoint = 0;
        var currentType = "Night";

        if (startFloor === 1 || startFloor === 2) {
          currentType = "Day";
        }
        var lastFloor = startFloor;
        var startAdjustment = startMod + startFloor * 21600;
        // console.log("===");
        for (let index = startFloor; index <= endFloor; index++) {
          //   console.log("lastFloor : " + lastFloor + ", index : " + index);
          if (lastFloor === 0 && index === 1) {
            currentEndPoint = (360 - startAdjustment) * pixelsPerUnitTime;
            var newZone1 = {
              startPoint: currentStartPoint,
              endPoint: currentEndPoint,
              type: currentType,
            };
            allZones.push(newZone1);

            currentStartPoint = (360 - startAdjustment) * pixelsPerUnitTime;
            currentType = "Day";
          } else if (lastFloor === 2 && index === 3) {
            currentEndPoint = (1080 - startAdjustment) * pixelsPerUnitTime; //64800
            // console.log("currentEndPoint : " + currentEndPoint);
            var newZone2 = {
              startPoint: currentStartPoint,
              endPoint: currentEndPoint,
              type: currentType,
            };
            allZones.push(newZone2);

            currentStartPoint = (1080 - startAdjustment) * pixelsPerUnitTime;
            currentType = "Night";
          }
          lastFloor = index;
        }

        // Close the last Zone
        currentEndPoint = gridWidth;
        // Insert the last Zone
        var newZoneLast = {
          startPoint: currentStartPoint,
          endPoint: currentEndPoint,
          type: currentType,
        };
        allZones.push(newZoneLast);

        //   console.log(allZones);
        for (let i = 0; i < allZones.length; i++) {
          var fillColor = "rgba(48, 136, 255,0.6)";
          var imageLink = "/images/moon.png";
          if (allZones[i].type === "Day") {
            fillColor = "rgba(254, 172, 51,0.6)";
            imageLink = "/images/sun.png";
          }
          let thisWidth = allZones[i].endPoint - allZones[i].startPoint;
          if (thisWidth > 0) {
            svgBPMGraph
              .append("rect")
              .attr("class", "bpmGraphView")
              .attr("x", margin.left + allZones[i].startPoint)
              .attr("y", margin.top)
              .attr("width", thisWidth)
              .attr("height", 1)
              .attr("fill", fillColor);
            if (thisWidth > 15) {
              var myimage = svgBPMGraph
                .append("image")
                .attr("xlink:href", imageLink)
                .attr("class", "bpmGraphView")
                .attr("width", 15)
                .attr("height", 15)
                .attr(
                  "x",
                  margin.left +
                    (allZones[i].startPoint + allZones[i].endPoint) / 2
                )
                .attr("y", 12);
            }
          }
        }
        
      }

      d3.selectAll(".clickBPMGraphLineEvent").remove();
      d3.selectAll(".clickBPMGraphExternalEvent").remove();
/*
      if (
        props.lastClick === props.AppArea.BPMGraph &&
        clickPositionX > margin.left &&
        clickPositionX < margin.left + localGridWidth &&
        !zoomActiveRef.current
      ) {
        svgBPMGraph
          .append("rect")
          .attr("class", "clickBPMGraphLineEvent")
          .attr("x", clickPositionX - 1)
          .attr("y", margin.top + 15)
          .attr("width", 2)
          .attr("height", heightOfSVG - (margin.top + margin.bottom + 15))
          .attr("fill", "rgba(70, 130, 180,0.6)");

        let index = getNearestIndexFromGridX(clickPositionX);

        if (index >= 0) {
          svgBPMGraph
            .append("text")
            .attr("class", "clickBPMGraphLineEvent")
            .text(props.BPMDataSet[index].avgBPM + " bpm")
            .attr("fill", "#666666")
            .attr("font-size", "11px")
            .attr("x", clickPositionX - 16)
            .attr("y", 20);
        }
      } else {
        //console.log("props.SelectedTime : " + props.SelectedTime);
        let timeInSeconds = Utility.ConvertTimeFormatIntoSeconds(
          props.SelectedTime
        );
        //console.log("timeInSeconds : " + timeInSeconds);
        if (timeInSeconds > 0) {
          svgBPMGraph
            .append("rect")
            .attr("class", "clickBPMGraphExternalEvent")
            .attr("x", xScale(timeInSeconds))
            .attr("y", margin.top)
            .attr("width", 2)
            .attr("height", heightOfSVG - (margin.top + margin.bottom))
            .attr("fill", "rgba(70, 130, 180,0.6)");
        }
      }
*/
      let rightLimit = margin.left + localGridWidth;
let tempMinHR = 3;
let tempMaxHR = 5;

      if (checkMaxHR) {
        let thisX = xScale(props.highestHR._id);
        if (thisX > margin.left && thisX < rightLimit) {
          svgBPMGraph
            .append("rect")
            .attr("class", "bpmGraphView")
            .attr("x", thisX)
            .attr("y", margin.top)
            .attr("width", 2)
            .attr("height", heightOfSVG - (margin.top + margin.bottom))
            .attr("fill", "rgba(186, 225, 255,0.8)");
        }
      }

      if (checkMinHR) {
        let thisX = xScale(props.lowestHR._id);
        if (thisX > margin.left && thisX < rightLimit) {
          svgBPMGraph
            .append("rect")
            .attr("class", "bpmGraphView")
            .attr("x", thisX)
            .attr("y", margin.top)
            .attr("width", 2)
            .attr("height", heightOfSVG - (margin.top + margin.bottom))
            .attr("fill", "rgba(235, 255, 0,0.8)");
        }
      }
/*
      if (checkAfib) {
        //  console.log(props.afibData);
        if (props.afibData.length > 0) {
          for (let i = 0; i < props.afibData.length; i++) {
            let thisX = xScale(props.afibData[i].StartTime);
            if (thisX > margin.left && thisX < rightLimit) {
              svgBPMGraph
                .append("rect")
                .attr("class", "bpmGraphView")
                .attr("x", thisX)
                .attr("y", margin.top)
                .attr("width", 2)
                .attr("height", heightOfSVG - (margin.top + margin.bottom))
                .attr("fill", "rgba(255, 228, 175,0.8)");
            }
          }
        }
      }

      if (checkSinus) {
        //  console.log(props.sinusData);
        if (props.sinusData.length > 0) {
          for (let i = 0; i < props.sinusData.length; i++) {
            let thisX = xScale(props.sinusData[i].StartTime);
            if (thisX > margin.left && thisX < rightLimit) {
              svgBPMGraph
                .append("rect")
                .attr("class", "bpmGraphView")
                .attr("x", thisX)
                .attr("y", margin.top)
                .attr("width", 2)
                .attr("height", heightOfSVG - (margin.top + margin.bottom))
                .attr("fill", "rgba(181, 255, 177,0.8)");
            }
          }
        }
      } */
    }
  }, [
    props.beatDivShowHide,
    clickPositionX,
    props.SelectedTime,
    props.globalW,
    props.BPMDataSet,
    props.afibData,
    props.sinusData,
    props.highestHR,
    props.lowestHR,
    checkMaxHR,
    checkMinHR,
    checkAfib,
    checkSinus,
    currentHRTransform,
    zoomActive,
  ]);

  function onSliderChange(e: any) {
    setSliderValue(parseInt(e));
  }

  function updateSlider(value: number) {
    if (value === -1) {
      if (sliderValue >= 5) {
        setSliderValue(sliderValue - 5);
      } else {
        setSliderValue(0);
      }
    } else if (value === 1) {
      if (sliderValue <= 95) {
        setSliderValue(sliderValue + 5);
      } else {
        setSliderValue(100);
      }
    }
  }
  return (
    <div>
      <div className="monitor-bottom">
        <div className="hr-monitor">
          <div className="monitor-child">
            <a
              className={`${checkMaxHR === true ? "max-hr-bac" : ""}`}
              onClick={() => checkMaxHRClick()}
            ></a>
            <div className="monitor-text">
              <p>Max HR</p>
              <span>{props.highestHR.avgBPM.toFixed(2)} bpm</span>
            </div>
          </div>
          <div className="monitor-child">
            <a
              className={`${checkMinHR === true ? "min-hr-bac" : ""}`}
              onClick={() => checkMinHRClick()}
            ></a>
            <div className="monitor-text">
              <p>Min HR</p>
              <span>{props.lowestHR.avgBPM.toFixed(2)} bpm</span>
            </div>
          </div>
          <div className="monitor-child">
            <a
              className={`${checkAfib === true ? "afib-flu-bac" : ""}`}
              onClick={() => checkAfibClick()}
            ></a>
            <div className="monitor-text">
              <p>AFib / Flutter</p>
              <span>
                {props.summary?.classification?.Afib > 1
                  ? props.summary?.classification?.Afib + " examples"
                  : props.summary?.classification?.Afib + " example"}
              </span>
            </div>
          </div>
          <div className="monitor-child">
            <a
              className={`${checkSinus === true ? "sinus-bac" : ""}`}
              onClick={() => checkSinusClick()}
            ></a>
            <div className="monitor-text">
              <p>Sinus</p>
              <span>
                {props.summary?.subClassification['Sinus arrhythmia'] > 1
                  ? props.summary?.subClassification['Sinus arrhythmia'] + " examples"
                  : props.summary?.subClassification['Sinus arrhythmia'] + " example"}
              </span>
            </div>
          </div>
        </div>
        <div className="range-slider-main">
          <div className="pan-click">
            {zoomActiveRef.current && (
              <a onClick={() => changeToPointer()}>
                <img src="/images/pan.svg" width="18" height="18" alt="cross" />
              </a>
            )}
            {!zoomActiveRef.current && (
              <a onClick={() => changeToZoom()}>
                <img
                  src="/images/click.svg"
                  width="18"
                  height="18"
                  alt="cross"
                />
              </a>
            )}
          </div>
          <span>{hours}</span>
          <a onClick={() => updateSlider(-1)}>-</a>
          <input
            type="range"
            onChange={(e: any) => onSliderChange(e.target.value)}
            min="0"
            max="100"
            value={sliderValue}
          ></input>
          <a onClick={() => updateSlider(1)}>+</a>
        </div>
      </div>
      <span className="histo-hr">HR</span>
      <div className="ecg--trends__right sm-height">
        <svg id="bpmGraphSVG" ref={svgBPMGraphRef}></svg>
      </div>
      <div className="monitor-bottom no-display">
        <div className="hr-monitor">
          {/* <div className="monitor-child">
            <a className="other-beats"></a>
            <div className="monitor-text">
              <p>Other Beats</p>
              <span>55 beats</span>
            </div>
          </div>
          <div className="monitor-child">
            <a className="psvc"></a>
            <div className="monitor-text">
              <p>PSVC</p>
              <span>131 beats</span>
            </div>
          </div>
          <div className="monitor-child">
            <a className="pvc"></a>
            <div className="monitor-text">
              <p>PVC</p>
              <span>347 beats</span>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default HREventChart;
