import Divider from '../../components/Divider'
import ReportHeader from '../../components/ReportHeader'
import ReportInfoBox from '../../components/ReportInfoBox'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
//import faker from 'faker'

// chartJs
// d3-js
//
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

export default function MTC_HeartRateDistribution() {
  return (
    <div>
      <ReportHeader />
      <Divider className="mb-12" />
      <RateDistribution />
      <RateSummary />
      <DataSummary />
      <Divider />
    </div>
  )
}
function BoxWithHeading({
  className,
  children,
  heading = ' Rate Distribution',
  height = 772,
}) {
  return (
    <div
      style={{ height: height }}
      className="  border-[2px] rounded-2xl border-report-skyblue w-full min-h-[400px] overflow-hidden mb-16  "
    >
      <heading className="flex text-white text-4xl font-semibold items-center justify-center bg-report-skyblue h-16">
        {heading}
      </heading>
      <div className={className}>{children}</div>
    </div>
  )
}

function RateDistribution() {
  return (
    <BoxWithHeading
      heading="Rate Distribution"
      className="h-full w-full  flex  "
    >
      <div className=" w-1/2 h-full  flex flex-col items-center justify-center px-6 ">
        <BarChartRateDistribution />
        <span className="mt-4">Rhythm</span>
      </div>

      <div className=" w-1/2 h-full  flex flex-col items-center justify-center  px-6 ">
        <BarChartRateDistribution />
        <span className="mt-4">Atrial Fibrillation/Flutter</span>
      </div>
    </BoxWithHeading>
  )
}
function RateSummary() {
  const options = {
    title: {
      text: 'My chart',
    },
    series: [
      {
        data: [1, 2, 3],
      },
    ],
  }

  const options2 = {
    chart: {
      type: 'columnrange',
      inverted: true,
    },

    accessibility: {
      description:
        'Image description: A column range chart compares the monthly temperature variations throughout 2017 in Vik I Sogn, Norway. The chart is interactive and displays the temperature range for each month when hovering over the data. The temperature is measured in degrees Celsius on the X-axis and the months are plotted on the Y-axis. The lowest temperature is recorded in March at minus 10.2 Celsius. The lowest range of temperatures is found in December ranging from a low of minus 9 to a high of 8.6 Celsius. The highest temperature is found in July at 26.2 Celsius. July also has the highest range of temperatures from 6 to 26.2 Celsius. The broadest range of temperatures is found in May ranging from a low of minus 0.6 to a high of 23.1 Celsius.',
    },

    title: {
      text: 'Temperature variation by month',
    },

    subtitle: {
      text:
        'Observed in Vik i Sogn, Norway, 2021 | ' +
        'Source: <a href="https://www.vikjavev.no/ver/" target="_blank">Vikjavev</a>',
    },

    xAxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
    },

    yAxis: {
      title: {
        text: 'Temperature ( °C )',
      },
    },

    tooltip: {
      valueSuffix: '°C',
    },

    plotOptions: {
      columnrange: {
        dataLabels: {
          enabled: true,
          format: '{y}°C',
        },
      },
    },

    legend: {
      enabled: false,
    },

    series: [
      {
        name: 'Temperatures',
        data: [
          [-13.9, 5.2],
          [-16.7, 10.6],
          [-4.7, 11.6],
          [-4.4, 16.8],
          [-2.1, 27.2],
          [5.9, 29.4],
          [6.5, 29.1],
          [4.7, 25.4],
          [4.3, 21.6],
          [-3.5, 15.1],
          [-9.8, 12.5],
          [-11.5, 8.4],
        ],
      },
    ],
  }

  return (
    <BoxWithHeading
      height={600}
      heading="Rate Summary"
      className="h-full w-full   flex px-10 font-inter pt-6 pb-3    "
    >
      <div className=" w-full h-[calc(100%-75px)]   mr-6   ">
        <div className="  "> Day and Night Burden Data</div>
        <div className="h-full w-full pt-12  ">
          {/* <HighchartsReact  highcharts={Highcharts} options={options2} /> */}
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      </div>
      <ReportInfoBox height={395} width={436} className=" my-auto text-base">
        <p className="mb-5">20d 12h 41m</p>
        <p className="mb-12">36.69 %</p>
        <div className="mb-5 font-bold">Heart rate range:</div>
        <div className="flex flex-col gap-6">
          <p className="whitespace-nowrap">
            {'<60 (14%), 60-110 (20%),110-150 (6%), >150 (66%)'}
          </p>
          <p>{'<60 (12%), 60-110 (30%),110-150 (6%), >150 (26%)'}</p>
          <p>{'<60 (13%), 60-110 (25%),110-150 (6%), >150 (46%)'}</p>
        </div>
      </ReportInfoBox>
    </BoxWithHeading>
  )
}

function DataSummary() {
  const tableEleStyle =
    'w-1/4 border-[#9CA1AA]  flex items-center justify-center flex-col  h-20  border-[0.25px]'
  const tableHeadings = [
    {
      label: 'Events',
    },
    {
      label: 'Day',
      time: '(06 Am - 10 Pm)',
    },
    {
      label: 'Night',
      time: '(10 Pm - 06 Am)',
    },
    {
      label: 'Total',
    },
  ]
  const summaryData = [
    {
      events: 'rden',
      day: '40.45%',
      night: '38.10%',
      Total: '78.55%',
    },
    {
      events: 'Duration',
      day: '10d 10h 11m',
      night: '10d 2h 30m',
      Total: '20d 12h 41m',
    },
    {
      events: 'Episodes',
      day: '4',
      night: '3',
      Total: '7',
    },
  ]

  return (
    <BoxWithHeading height={363} heading="Data Summary">
      <div>
        <div className="flex gap-1">
          {tableHeadings.map((data, index) => (
            <>
              <div
                key={index}
                className="w-1/4 border-r-[#9BA1AA] border-l-[#9BA1AA] flex items-center justify-center flex-col  h-20 bg-[#CEEBEE] border-[0.25px] "
              >
                <div>
                  <th>{data.label}</th>
                  <p>{data.time}</p>
                </div>
              </div>
            </>
          ))}
        </div>
        {/* table */}
        <div className="flex flex-col gap-1">
          {summaryData.map((data, index) => (
            <div key={index} className="flex gap-1 ">
              <div className={tableEleStyle}>{data.events}</div>
              <div className={tableEleStyle}>{data.day}</div>
              <div className={tableEleStyle}>{data.night}</div>
              <div className={tableEleStyle}>{data.Total}</div>
            </div>
          ))}
        </div>
      </div>
    </BoxWithHeading>
  )
}

function BarChartRateDistribution() {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    // Legend,
  )
  const labels = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
  ]
  const data = {
    labels,
    datasets: [
      {
        data: {},//labels.map(() => faker.datatype.number({ min: 0, max: 35 })),
        backgroundColor: '#6D6E71',
      },
    ],
  }

  return <Bar dataKey="artarial" options={{ responsive: true }} data={data} />
}
