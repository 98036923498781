//import PatientReportLayout from '../PatientReportLayout'
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { MdRefresh } from "react-icons/md";
import classnames from "classnames";
import graphImg from "./images/graph.png";
import tableImg from "./images/table.png";
import dailyHrGraph from "./images/dailyHrGraph.png";
export default function HistogramPage2() {
  return (
    //<PatientReportLayout>
    <>
      <div className="flex gap-2">
        <DateAndCalender />
      </div>
      <HourlyHrGraph />
      <DayNightTable />
      <DailyHRGraph />
    </>
    // </PatientReportLayout>
  );
}

function DateAndCalender() {
  return (
    <div className="flex gap-3  w-[268px] h-[38px]  bg-white items-center font-medium rounded justify-center  cursor-pointer shadow-[0px_20px_40px_rgba(206,206,206,0.25)]">
      <span>05/02/2023</span>
      <BsFillArrowRightCircleFill />
      <span>06/02/2023</span>
      <MdRefresh className="text-lg" />
    </div>
  );
}

function HourlyHrGraph() {
  return (
    <div
      className="mt-3 w-full h-[782px] bg-white rounded
  shadow-[0px_20px_40px_rgba(121,121,121,0.06)] px-5 pb-10 pt-7 "
    >
      <div className="px-16 ">
        <h2 className="font-bold text-xl mb-5">Hourly HR Graph</h2>
        <div className="flex items-center justify-between">
          <Bmp title="Min HR (bpm)" value={68} />
          <Bmp title="Avg HR (bpm)" value={54} />
          <Bmp title="Max HR (bpm)" value={99} />
        </div>
      </div>
      <Graph />
    </div>
  );
}

function Bmp({ title, value }) {
  const style =
    "px-6 h-[38px] w-[187px] border-[1px] border-[#E9E9E9] rounded-lg";
  return (
    <div>
      <p className="font-semibold mb-2  ">{title}</p>
      <div className="gap-3 flex flex-col 2xl:flex-row items-center">
        <input className={style} value={value} type="number" name="" id="" />
        <input className={style} type="text" name="" id="" />
      </div>
    </div>
  );
}

function Graph() {
  return (
    <div className="w-full min-w-full bg-gray-50 mt-3 relative  h-[590px] border-1">
      <img src={graphImg} layout="fill" />
    </div>
  );
}

function DayNightTable() {
  return (
    <div
      className="mt-3  w-full h-[782px] bg-white rounded
shadow-[0px_20px_40px_rgba(121,121,121,0.06)] px-20 py-10 "
    >
      <div className="h-full w-full bg-gray-50 relative ">
        <img layout="fill" src={tableImg} />
      </div>
    </div>
  );
}

function DailyHRGraph() {
  return (
    <div
      className="mt-3  w-full h-[463px] bg-white rounded
shadow-[0px_20px_40px_rgba(121,121,121,0.06)]  "
    >
      <div className="border-b-[1px] border-[#E9E9E9] h-[51px] pl-[77px] py-[13px]">
        <h2 className="font-bold text-xl mb-5">Lorentz Plot</h2>
      </div>
      <div className="pb-[43px] pr-5 pl-10">
        <div className="w-full h-[271px] relative mt-[71px]   bg-gray-50">
          <img src={dailyHrGraph} layout="fill" />
        </div>
      </div>
    </div>
  );
}
