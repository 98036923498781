import React, { useState, useRef, useEffect } from "react";
import * as d3 from "d3";
import * as Utility from "../Shared/Utility";

type HRTrendsProps = {
  hrTrendsShowHide: boolean;
  mmsValueSplitView: any;
  globalW: any;
  lastClick: any;
  setlastClick: Function;
  SelectedDataSet: any;
  SelectedTime: any;
  allMinutesHR: any;
  handleMoveGraph: Function;
  setintervalSplitView: Function;
  AppArea: any;
};

function HRTrendView(props: HRTrendsProps) {
  const svgHRTrendRef = useRef<any>();

  const [clickPositionX, setclickPositionX] = useState(-1);

  let svgLeft = 0;
  let svgTop = 0;
  let gridWidth = 0;
  let range = 0;
  let margin = { top: 10, right: 40, bottom: 25, left: 30 };

  function getSVGx(x: any) {
    return x - svgLeft;
  }

  function getSVGy(y: any) {
    return y - svgTop;
  }

  function getTimeFromXPixel(x: any) {
    let graphX = x - margin.left;
    let clickedIndex = Math.floor((graphX / gridWidth) * range);
    var newStartTime = 0;
    if (clickedIndex < props.allMinutesHR.length) {
      // newStartTime = props.allMinutesHR[clickedIndex].totalMinutes * 60 + 30;
       newStartTime = props.allMinutesHR[clickedIndex]._id * 60 + 30;
    }
    return newStartTime;
  }

  function getIndexFromXPixel(x: any) {
    let graphX = x - margin.left;
    let clickedIndex = Math.floor((graphX / gridWidth) * range);
    if (clickedIndex >= 0 && clickedIndex < props.allMinutesHR.length) {
      return clickedIndex;
    }
    return -1;
  }

  function getXPixelFromTime(inputTime: any) {
    let x = 0;

    let selectedIndex = 0;
    let timeInMinutes = Math.floor(inputTime / 60);
    //   console.log("Time In Minutes : " + timeInMinutes);
    for (let i = 0; i < props.allMinutesHR.length; i++) {
      //console.log(props.allMinutesHR[i].totalMinutes + " : " + timeInMinutes);
      if (props.allMinutesHR[i]._id === timeInMinutes) {
        selectedIndex = i;
        break;
      }
    }
    //   console.log("selected Index : " + selectedIndex);
    x = (selectedIndex / range) * gridWidth;

    return x + margin.left;
  }

  const MouseDown = (e: any) => {
    let localX = getSVGx(e.pageX);
    setclickPositionX(localX);
    let newStartTime = getTimeFromXPixel(localX);
    props.handleMoveGraph(newStartTime, 0);
    props.setlastClick(props.AppArea.HRTrend);
  };

  // This is for drawing the HRTrends view
  useEffect(() => {
    if (props.hrTrendsShowHide === true) {
      //  var BottomDivWidth =
      //    document.getElementById("MainBottomDiv")?.clientWidth;
      let w = props.globalW - 40;
      let heightOfHRTrendView = 220;
      let maxHR = 200;
      // if (BottomDivWidth) {
      //  w = BottomDivWidth - 50;
      //}
      gridWidth = w - (margin.left + margin.right);

      let localIntervalSplitView = w / (props.mmsValueSplitView * 5);
      props.setintervalSplitView(localIntervalSplitView);

      let svgHRTrend = d3
        .select(svgHRTrendRef.current)
        .attr("width", w) //globalW
        .attr("height", heightOfHRTrendView)
        .on("mousedown", MouseDown);
      //        .style("overflow", "hidden");

      let boundingRect: any = document
        .getElementById("hrTrendSVG")
        ?.getBoundingClientRect();

      svgLeft = boundingRect.left;
      svgTop = boundingRect.top;

      range = props.allMinutesHR.length;

      // setting the scaling
      let xScaleHRTrendView = d3
        .scaleLinear()
        .domain([0, props.allMinutesHR.length - 1])
        .range([margin.left, w - margin.right]);

      let yScaleHRTrendView = d3
        .scaleLinear()
        .domain([0, maxHR])
        .range([heightOfHRTrendView - margin.bottom, margin.top]);

      d3.selectAll(".hrTrendView").remove();
      d3.selectAll("#hrTrendViewLeftAxis").remove();
      d3.selectAll("#hrTrendViewRightAxis").remove();

      svgHRTrend
        .append("g")
        .attr("id", "hrTrendViewLeftAxis")
        .attr("transform", `translate(${margin.left},0)`)
        .call(d3.axisLeft(yScaleHRTrendView));

      svgHRTrend
        .append("g")
        .attr("id", "hrTrendViewRightAxis")
        .attr("transform", `translate(${w + -margin.right},0)`)
        .call(d3.axisRight(yScaleHRTrendView));

      d3.selectAll(".gridlineHRTrend").remove();
      // add the gridlines
      svgHRTrend
        .selectAll(".gridlineHRTrend")
        .data(yScaleHRTrendView.ticks())
        .enter()
        .append("line")
        .attr("class", "gridlineHRTrend")
        .attr("x1", margin.left)
        .attr("y1", (d: number) => yScaleHRTrendView(d))
        .attr("x2", margin.left + gridWidth)
        .attr("y2", (d: number) => yScaleHRTrendView(d))
        .attr("stroke", "#EEE");

      if (props.allMinutesHR && props.allMinutesHR.length > 1) {
        var curveFunc = d3
          .area()
          .x(function (d: any, i: any) {
            return xScaleHRTrendView(i);
          }) // Position of both line breaks on the X axis
          .y1(function (d: any, i: any) {
            return yScaleHRTrendView(d.avgBPM);
          }) // Y position of top line breaks
          .y0(yScaleHRTrendView(0));

        svgHRTrend
          .append("path")
          .attr("d", curveFunc(props.allMinutesHR))
          .attr("class", "hrTrendView")
          .attr("stroke", "rgba(148, 169, 180,0.8)")
          .attr("fill", "rgba(148, 169, 180,0.8)");

        let medianIndex = Math.round((props.allMinutesHR.length - 1) / 2);
        let medianValue = props.allMinutesHR[medianIndex].avgBPM;
        let firstRegionText =
          "HR: " +
          Math.floor(props.allMinutesHR[0].avgBPM) +
          " - " +
          Math.floor(medianValue - 1) +
          " bpm";
        let secondRegionText =
          "HR: " +
          Math.floor(medianValue - 1) +
          " - " +
          Math.floor(medianValue + 1) +
          " bpm";
        let thirdRegionText =
          "HR: " +
          Math.floor(medianValue + 1) +
          " - " +
          Math.floor(props.allMinutesHR[props.allMinutesHR.length - 1].avgBPM) +
          " bpm";

        let graphWidth = w - (margin.left + margin.right);
        let firstText = graphWidth / 2 - 20;
        let secondMiddle = margin.left + graphWidth / 2;

        //First text at bottom of graph
        svgHRTrend
          .append("text")
          .text(firstRegionText)
          .attr("class", "hrTrendView")
          .attr("fill", "#555")
          .attr("font-size", "10px")
          .attr("x", graphWidth / 4 + margin.left)
          .attr("text-anchor", "middle")
          .attr("y", heightOfHRTrendView - 10);

        //Middle text at bottom of graph
        svgHRTrend
          .append("text")
          .text(secondRegionText)
          .attr("class", "hrTrendView")
          .attr("fill", "#555")
          .attr("font-size", "10px")
          .attr("x", graphWidth / 2 + margin.left)
          .attr("text-anchor", "middle")
          .attr("y", heightOfHRTrendView - 10);
        //Last text at bottom of graph
        svgHRTrend
          .append("text")
          .text(thirdRegionText)
          .attr("class", "hrTrendView")
          .attr("fill", "#555")
          .attr("font-size", "10px")
          .attr("x", graphWidth - graphWidth / 4 + margin.left)
          .attr("text-anchor", "middle")
          .attr("y", heightOfHRTrendView - 10);
        //Rectangle in  iddle of graph
        svgHRTrend
          .append("rect")
          .attr("class", "hrTrendView")
          .attr("x", graphWidth / 2 - 5 + margin.left)
          .attr("y", 5)
          .attr("width", 10) // width of the rectangle
          .attr("height", heightOfHRTrendView - 29) // height of the rectangle
          .style("fill", "#eee"); // color of the rectangle

        // Add the rectangle to the SVG

        let rectWidth = 25;
        let rectHeight = 25;

        let rectX = margin.left + 20;
        let rectY = margin.top;

        svgHRTrend
          .append("rect")
          .attr("x", rectX)
          .attr("y", rectY)
          .attr("class", "hrTrendView")
          .attr("width", rectWidth)
          .attr("fill", "#60BAF1")
          .attr("height", rectHeight)
          .style("stroke", "#888")
          .style("stroke-width", "2px")
          .attr("rx", "3");

        // Add text to the rectangle
        svgHRTrend
          .append("text")
          .text("All")
          .attr("class", "hrTrendView")
          .attr("x", rectX + rectWidth / 2)
          .attr("y", rectY + rectHeight / 2)
          .attr("text-anchor", "middle")
          .attr("fill", "#555")
          .attr("font-size", "12px")
          .attr("dominant-baseline", "middle");

        // Add text to the rectangle
//ZI: commented
       /* for (let i = 0; i < props.SelectedDataSet.length; i++) {
          let margin = 35;

          svgHRTrend
            .append("rect")
            .attr("class", "hrTrendView")
            .attr("x", rectX + margin * (i + 1))
            .attr("y", rectY)
            .attr("width", rectWidth)
            .attr("fill", "#ccc")
            .attr("height", rectHeight)
            .style("stroke", "#888")
            .style("stroke-width", "2px")
            .attr("rx", "3");

          svgHRTrend
            .append("text")
            .text(i + 1)
            .attr("class", "hrTrendView")
            .attr("x", rectX + rectWidth / 2 + margin * (i + 1))
            .attr("y", rectY + rectHeight / 2)
            .attr("text-anchor", "middle")
            .attr("fill", "#555")
            .attr("font-size", "12px")
            .attr("dominant-baseline", "middle");
        }*/
      }

      d3.selectAll(".clickHRTrendView").remove();
      d3.selectAll(".clickHRTrendViewExternal").remove();
      //console.log("b clickPositionX : " + clickPositionX);
      if (
        props.lastClick === props.AppArea.HRTrend &&
        clickPositionX > margin.left &&
        clickPositionX < margin.left + gridWidth
      ) {
        //console.log("clickPositionX : " + clickPositionX);
        svgHRTrend
          .append("rect")
          .attr("class", "clickHRTrendView")
          .attr("x", clickPositionX - 1)
          .attr("y", margin.top + 15)
          .attr("width", 2)
          .attr(
            "height",
            heightOfHRTrendView - (margin.top + margin.bottom + 15)
          )
          .attr("fill", "rgba(70, 130, 180,0.6)");

        let index = getIndexFromXPixel(clickPositionX);

        if (index >= 0) {
          svgHRTrend
            .append("text")
            .attr("class", "clickHRTrendView")
            .text(Math.round(props.allMinutesHR[index].avgBPM) + " bpm")
            .attr("fill", "#666666")
            .attr("font-size", "11px")
            .attr("x", clickPositionX - 16)
            .attr("y", 20);
        }
      } else {
        //      console.log(Utility.ConvertTimeFormatIntoSeconds(props.SelectedTime));
        let timeInSeconds = Utility.ConvertTimeFormatIntoSeconds(
          props.SelectedTime
        );
        if (timeInSeconds > 0) {
          //console.log("clickPositionX : " + clickPositionX);
          // getXPixelFromTime(props.SelectedTime)
          svgHRTrend
            .append("rect")
            .attr("class", "clickHRTrendViewExternal")
            .attr("x", getXPixelFromTime(timeInSeconds))
            .attr("y", margin.top)
            .attr("width", 2)
            .attr("height", heightOfHRTrendView - (margin.top + margin.bottom))
            .attr("fill", "rgba(70, 130, 180,0.6)");
        }
      }
    }
  }, [props.hrTrendsShowHide, clickPositionX, props.SelectedTime,props.globalW]);

  return (
    <div className="ecg--trends__right">
      <svg id="hrTrendSVG" ref={svgHRTrendRef}></svg>
    </div>
  );
}

export default HRTrendView;
