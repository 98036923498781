//import PatientReportLayout from '../PatientReportLayout'
import classnames from "classnames";
import { notificationData } from "./data";

export default function NotificationCriteriaPage2() {
  const talbeHeadingStyle = "font-bold text-lg";
  const tdStyle = "text-[#616161] text-xl";
  return (
    //<PatientReportLayout>
    <>
      <div
        className="mt-3  w-full  bg-white rounded
shadow-[0px_20px_40px_rgba(121,121,121,0.06)]  "
      >
        <div className="border-b-[1px] border-[#E9E9E9] h-[51px] pl-[77px] py-[13px]">
          <h2 className="font-bold text-xl mb-5">
            Notification Protocol Amendments
          </h2>
        </div>
        <div className="pb-[98px]  pt-[28px]  pr-[164px] pl-[77px]  max-h-fit">
          <div className=" h-full  w-full  ">
            {/* table headings */}
            <div className=" gap-8  justify-items-start grid grid-cols-4  mb-4    ">
              <th className={talbeHeadingStyle}>(MCT Notification) Rhythm</th>
              <th className={classnames(talbeHeadingStyle, "w-[189px]")}>
                Inbox Reportable (Weekly Summary)
              </th>
              <th className={classnames(talbeHeadingStyle, "w-[189px]")}>
                Urgent Notification (Office Hour)
              </th>
              <th className={classnames(talbeHeadingStyle, "w-[196px]")}>
                Emgent Notification (Immediate)
              </th>
            </div>
            {/* table data  */}
            <div className="flex flex-col gap-4">
              {notificationData.map((data, index) => (
                <div
                  key={index}
                  className=" gap-16  justify-items-start grid grid-cols-4"
                >
                  <td className={classnames(tdStyle)}>{data.rhythm}</td>
                  <td className={classnames(tdStyle)}>
                    {data.inboxReportable}
                  </td>
                  <td className={classnames(tdStyle)}>
                    {data.urgentNotification}
                  </td>
                  <td className={classnames(tdStyle)}>
                    {data.emgentNotification}
                  </td>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
    //</PatientReportLayout>
  );
}
