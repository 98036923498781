// Create a context
import React, { createContext, useContext, useState, ReactNode } from "react";

type GlobalStateContextType = {
  BPMSelectionArray: any;
  setBPMSelectionArray: Function;
  fileId: string;
  setFileId: Function;
  fileEntryObject: any;
  setFileEntryObject: Function;
};

const GlobalStateContext = createContext<GlobalStateContextType | undefined>(
  undefined
);

export const GlobalStateProvider = ({ children }: any) => {
  const [BPMSelectionArray, setBPMSelectionArray] = useState<any>([]);
  const [fileId, setFileId] = useState<string>("");
  const [fileEntryObject, setFileEntryObject] = useState<any>([]);
  return (
    <GlobalStateContext.Provider
      value={{ BPMSelectionArray, setBPMSelectionArray,fileId, setFileId ,fileEntryObject, setFileEntryObject}}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error("useGlobalState must be used within a GlobalStateProvider");
  }
  return context;
};
