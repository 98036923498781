// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, signInWithCustomToken, signInWithEmailAndPassword, signOut as firebaseSignout, getIdTokenResult } from 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getStorage, getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { getDatabase, ref as realRef, update, onValue, set } from 'firebase/database'
import {getFirestore} from "firebase/firestore";
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAfQQB2Kqx056dw5xGXVesDcuJERqqk0r4",
    authDomain: "pulse-gate.firebaseapp.com",
    projectId: "pulse-gate",
    storageBucket: "pulse-gate.appspot.com",
    messagingSenderId: "255038871494",
    appId: "1:255038871494:web:3a61e5498b152e5716c4c7",
    measurementId: "G-KNCWYBV5NZ",
    databaseURL:'https://pulse-gate-default-rtdb.asia-southeast1.firebasedatabase.app',
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const signIn = (email: string, password:string) => signInWithEmailAndPassword(auth, email, password);
export const signout = () => firebaseSignout(auth);
export const signInWithToken = (token: string) => signInWithCustomToken(auth, token);
export const storage = getStorage(app);
export const database = getDatabase(app)
export const firestoreDb = getFirestore(app)
export const getRef = (path: string) => ref(storage, path)
export const uploadFile = (filename: string, file: File, type: string) => {
    console.log(filename, file, type)
    return new Promise((resolve, reject) => {
        uploadBytesResumable(getRef(filename), file, {
            contentType: type
        }).then((snapshot) => resolve(snapshot))
    })
}
export const getUrlToDownload = (path: string) => {
    return new Promise((resolve, reject) => {
        getDownloadURL(getRef(path)).then(url => {
            console.log({ url })
            resolve(url)
        }).catch(err => reject(err))
    });
}
//
// // get event last viewed time
// export const getChunkTime = (eventId,chunk) =>{
//     console.log(eventId,chunk)
//     return new Promise((resolve,reject)=>{
//         const chunkRef = realRef(database,`chunksAccess/${eventId}/${chunk}`);
//         onValue(chunkRef, (snapshot) => {
//             if(snapshot.exists()){
//                 return resolve(snapshot.val());
//             }else{
//                 return resolve()
//             }
//         });
//     })
// }
//
// // update last viewed time
// export const setChunkTime = (eventId,chunk) =>{
//     return new Promise((resolve,reject)=>{
//         const chunkRef = realRef(database,`chunksAccess/${eventId}/${chunk}`)
//         update(chunkRef,{
//             lastViewedAt: new Date()
//         }).then((res)=>{
//             return resolve()
//         }).catch((err)=>{
//             return reject(err)
//         })
//     })
// }

export default app;
