// PatientDataContext.js
import { patientInformation } from "../data";
import { createContext, useContext, useState } from 'react';

const PatientDataContext = createContext();

export const usePatientData = () => useContext(PatientDataContext);

export const PatientDataProvider = ({ children }) => {
  const [patientData, setPatientData] = useState(patientInformation);
  const [editableData, setEditableData] = useState([]);

  return (
    <PatientDataContext.Provider value={{ patientData, setPatientData, editableData, setEditableData }}>
      {children}
    </PatientDataContext.Provider>
  );
};




