/** @format */

export const notificationData = [
  {
    rhythm: "Ventricular Fibrillation/Torsades de Pointes",
    inboxReportable: "1st Event-notify",
    urgentNotification: "1st Event-notify",
    status: "no"
  },
  {
    rhythm: "Rapid Ventricular Tachycardia",
    inboxReportable: `< 200 bpm Asymptomatic
    < 180 bpm Symptomatic`,
    urgentNotification: `< 200 bpm Asymptomatic
    < 180 bpm Symptomatic`,
    status: "no"
  },
  {
    rhythm: "Sustained Ventricular Tachycardia",
    inboxReportable: `< 180 bpm Asymptomatic
    < 175 bpm Symptomatic`,
    urgentNotification: `< 180 bpm Asymptomatic
    < 175 bpm Symptomatic`,
    status: "yes"
  },
  {
    rhythm: `< 180 bpm Asymptomatic
    < 175 bpm Symptomatic`,
    inboxReportable: "All Events",
    urgentNotification: "All Events",
    status: "yes"
  },
  {
    rhythm: `Bradycardia (all rhythms)	
    `,
    inboxReportable: `< 40 bpm Asymptomatic
    < 40 bpm Symptomatic`,
    urgentNotification: `>/= 30 secs Asymptomatic
    Symptomatic N/A`,
    status: "yes"
  },
  {
    rhythm: "Tachycardia (all rhythms)",
    inboxReportable: `< 180 bpm Asymptomatic
    < 160 bpm Symptomatic`,
    urgentNotification: `>/= 30 secs Asymptomatic
    Symptomatic N/A`,
    status: "yes"
  },
  {
    rhythm: `New Onset Atrial Fibrillation/Flutter`,
    inboxReportable: "1st Event-notify (business hrs only)",
    urgentNotification: "N/A",
    status: "yes"
  },
  {
    rhythm: `Atrial Fibrillation/Flutter	
    `,
    inboxReportable: `< 140 bpm Asymptomatic
    < 130 bpm Symptomatic`,
    urgentNotification: `>/= 15 secs Asymptomatic
    Symptomatic N/A`,
    status: "no"
  },
  {
    rhythm: "Sinus Rhythm with WPW",
    inboxReportable: "N/A",
    urgentNotification: "N/A",
    status: "yes"
  },
  {
    rhythm: "Pause",
    inboxReportable: "N/A",
    urgentNotification: `>/= 4 secs Asymptomatic
    >/= 3 secs Symptomatic`,
    status: "yes"
  },
  {
    rhythm: "2nd Degree AVB, Type II/AHB",
    inboxReportable: "All Events",
    urgentNotification: "All Events",
    status: "yes"
  },
  {
    rhythm: "3rd Degree AVB/AVD",
    inboxReportable: "All Events",
    urgentNotification: "All Events",
    status: "yes"
  },
  {
    rhythm: "Failure to sense/Capture",
    inboxReportable: "All Events",
    urgentNotification: "All Events",
    status: "yes"
  }
];
