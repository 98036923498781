import React, { useState, useRef, useEffect } from "react";
import * as utility from "../../Shared/Utility";
import * as d3 from "d3";
import AppContext from "../../Context/Context";
import { useGlobalState } from "../../Context/GlobalStateContext";
import { getEventAnnotations } from "../../../utils/axios";
type EventsProps = {
  gridViewInfo: any;
  allAnnotations: any;
  leftMenuEventSelected: any;
  isStripeToogleView: boolean;
  leftMenuType: number;
  setAllAnnotations: Function;
  getBeatDataInRange: Function;
  getPeakDataInRange: Function;
  handleMoveGraph: Function;
  setSelectedView: Function;
};

function Events(props: EventsProps) {
  const globalState = useGlobalState();
  const AnnotationTagType = {
    AF: 1,
    Pause: 2,
    Noise: 3,
    AVB: 4,
    AT: 5,
    SVT: 6,
    VE: 7,
    SVE: 8,
    Normal: 9,
  };

  const isStripeToogleViewRef = useRef<any | null>(null);
  isStripeToogleViewRef.current = props.isStripeToogleView;

  const [selectedStartTime, setSelectedStartTime] = useState(0);
  const selectedStartTimeRef = useRef<any | null>(null);
  selectedStartTimeRef.current = selectedStartTime;
  const [minuteGraphTime, setminuteGraphTime] = useState(0);

  const [tenSecondStartTime, settenSecondStartTime] = useState(-1);
  const tenSecondStartTimeRef = useRef<any | null>(null);
  tenSecondStartTimeRef.current = tenSecondStartTime;

  const [selectedIndex, setSelectedIndex] = useState(-1);

  const [minuteGraphDragStarted, setminuteGraphDragStarted] = useState(false);
  const minuteGraphDragStartedRef = useRef<any | null>(null);
  minuteGraphDragStartedRef.current = minuteGraphDragStarted;

  const [
    minuteGraphClickPixelsFromLeftOfBox,
    setminuteGraphClickPixelsFromLeftOfBox,
  ] = useState(0);
  const minuteGraphClickPixelsFromLeftOfBoxRef = useRef<any | null>(null);
  minuteGraphClickPixelsFromLeftOfBoxRef.current =
    minuteGraphClickPixelsFromLeftOfBox;

  const [minuteGraphWidth, setminuteGraphWidth] = useState(1392);
  const [svgMinuteGraphLeft, setsvgMinuteGraphLeft] = useState(0);

  const [boxEventAnnotations, setBoxEventAnnotations] = useState<any>([]);

  useEffect(() => {
    // console.log("leftMenuEventSelected: " + props.leftMenuEventSelected);
  }, [props.leftMenuEventSelected]);


  let autoFillArray = [
    { Id: 3, Name: "Afib", Code: "A", TagType: 1 },
    { Id: 4, Name: "Sinus rhythm", Code: "H", TagType: 2 },
    { Id: 5, Name: "Sinus Bradycardia", Code: "H", TagType: 2 },
    { Id: 6, Name: "Sinus Tachycardia", Code: "H", TagType: 2 },
    { Id: 7, Name: "Sinus arrhythmia", Code: "H", TagType: 2 },
    { Id: 8, Name: "Isolated SVE", Code: "H", TagType: 2 },
    { Id: 9, Name: "SVE Pair", Code: "H", TagType: 2 },
    { Id: 10, Name: "SVE Run", Code: "H", TagType: 2 },
    { Id: 11, Name: "SVE Run Longest", Code: "H", TagType: 2 },
    { Id: 12, Name: "SVE Run fastest", Code: "H", TagType: 2 },
    { Id: 13, Name: "SVE – Bigeminy", Code: "H", TagType: 2 },
    { Id: 14, Name: "SVE – Trigeminy", Code: "H", TagType: 2 },
    { Id: 15, Name: "SVE – Quadrigeminy", Code: "H", TagType: 2 },
    { Id: 16, Name: "Isolated VE", Code: "H", TagType: 2 },
    { Id: 17, Name: "VE Pair", Code: "H", TagType: 2 },
    { Id: 18, Name: "VE Run", Code: "H", TagType: 2 },
    { Id: 19, Name: "VE Run Longest", Code: "H", TagType: 2 },
    { Id: 20, Name: "VE Run fastest", Code: "H", TagType: 2 },
    { Id: 21, Name: "VE – Bigeminy", Code: "H", TagType: 2 },
    { Id: 22, Name: "VE – Trigeminy", Code: "H", TagType: 2 },
    { Id: 23, Name: "VE – Quadrigeminy", Code: "H", TagType: 2 },
    { Id: 24, Name: "Longest – Pause", Code: "H", TagType: 2 },
    { Id: 25, Name: "First degree AV block", Code: "H", TagType: 2 },
    { Id: 26, Name: "Second degree AV block type 1", Code: "H", TagType: 2 },
    { Id: 27, Name: "Second degree AV block type 2", Code: "H", TagType: 2 },
    { Id: 28, Name: "Third degree AV block", Code: "H", TagType: 2 },
    { Id: 29, Name: "Advanced AV block", Code: "H", TagType: 2 },
    { Id: 30, Name: "Atrial paced rhythm", Code: "H", TagType: 2 },
    { Id: 31, Name: "Ventricular paced rhythm", Code: "H", TagType: 2 },
    { Id: 32, Name: "Dual Paced rhythm", Code: "H", TagType: 2 },
    { Id: 33, Name: "X beats/lead disconnected", Code: "H", TagType: 2 },
  ];

  const [autoFillValue, setAutoFillValue] = useState("");
  const [autoFill, setAutoFill] = useState(autoFillArray);
  const [showAutofill, setShowAutofill] = useState(false);
  const showAutofillRef = useRef<any | null>(null);
  showAutofillRef.current = showAutofill;

  const [selectedAutofillBox, setSelectedAutofillBox] = useState(0);

  const [clientX, setClientX] = useState(0);
  const [clientY, setClientY] = useState(0);

  useEffect(() => {
    document.addEventListener("keydown", detectKeyDown, true);

    const newArray = [...props.allAnnotations];

    if (newArray && newArray.length > 0) {
      newArray[0].IsStripe = true;
      props.setAllAnnotations(newArray);
    }
  }, []);

  const detectKeyDown = (e: any) => {
    let keypressed: any = e.key.toUpperCase();
    if (
      keypressed === "N" ||
      keypressed === "S" ||
      keypressed === "V" ||
      keypressed === "A"
    ) {
      let type = 0;
      if (keypressed === "N") {
        type = AnnotationTagType.Normal;
      } else if (keypressed === "S") {
        type = AnnotationTagType.SVT;
      }
      if (keypressed === "V") {
        type = AnnotationTagType.AVB;
      }
      if (keypressed === "A") {
        type = AnnotationTagType.AF;
      }

      const newArray = [...props.allAnnotations];
      if (isStripeToogleViewRef.current === false && !showAutofillRef.current) {
        for (let index = 0; index < newArray.length; index++) {
          if (newArray[index].StartTime === selectedStartTimeRef.current) {
            newArray[index].Type = type;
          }
        }

        props.setAllAnnotations(newArray);
      }
    }
  };

  useEffect(() => {
    console.log(
      "reloading events - selection length: " +
        globalState.BPMSelectionArray.length
    );
    //props.setAllAnnotations([...props.allAnnotations, tempArray]);
    if (props.allAnnotations.length > 0) {
      loadEventGraphInBox(props.leftMenuType);
    }
  }, [
    props.gridViewInfo,
    props.leftMenuEventSelected,
    props.leftMenuType,
    globalState.BPMSelectionArray,
  ]);

  useEffect(() => {
    // This overwrites the minute graph time every time the selected Start Time is changed
    setminuteGraphTime(selectedStartTime);
  }, [selectedStartTime]);

  // Load the 3 channel graph
  useEffect(() => {
    if (props.allAnnotations.length > 0) {
      var grapgWidth = document.getElementById("ChannelRight")?.clientWidth;
      let boxSizeWidth = grapgWidth ? grapgWidth : 1450;
      let boxSizeHeight = 205;

      // Duration of this box in seconds - hardcoded to 10 seconds
      let boxDuration = 10;

      let boxGraph = d3
        .select("#" + "svgThreeChannelsGraph")
        .attr("width", boxSizeWidth)
        .attr("height", boxSizeHeight)
        .style("overflow", "hidden");

      let uniqueID = "svgThreeChannelsGraph";
      d3.selectAll("." + uniqueID).remove();

      // Define the pattern for the image
      const pattern = boxGraph
        .append("defs")
        .append("pattern")
        .attr("id", "threeChannelBox-pattern")
        .attr("class", "uniqueID")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", (boxSizeHeight / 3) * 2)
        .attr("height", boxSizeHeight / 3)
        .attr("patternUnits", "userSpaceOnUse")
        .append("image")
        .attr("xlink:href", "/images/ECGDots.jpg")
        .attr("width", (boxSizeHeight / 3) * 2)
        .attr("height", boxSizeHeight / 3);

      // Append a rectangle element with the image pattern
      boxGraph
        .append("rect")
        .attr("class", "uniqueID")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", boxSizeWidth)
        .attr("height", boxSizeHeight)
        .attr("fill", "url(#threeChannelBox-pattern)");

      let l_startTime = tenSecondStartTimeRef.current;
      let l_EndTime = l_startTime + boxDuration;

      let Range = props.getBeatDataInRange(l_startTime, l_EndTime, 0) as any;
      let Peaks = getPeakDataInRangeFromAnnotations(
        l_startTime,
        l_EndTime
      ) as any;
      // console.log("peaks data");
      // console.log(Peaks);
      //console.log(Range);

      if (Range.length > 0) {
        let startTime = Range[0][0];
        let endTime = Range[Range.length - 1][0];

        // setting the scaling
        let xScaleBox = d3
          .scaleLinear()
          .domain([startTime, endTime])
          .range([0, boxSizeWidth]);

        let localYRange = 30000 / 10;

        let yScaleBox = d3
          .scaleLinear()
          .domain([-localYRange, localYRange])
          .range([boxSizeHeight / 3, 0]);

        let channelAdjustment = 0;

        for (let j = 1; j < Range.length; j++) {
          boxGraph
            .append("line")
            .attr("class", uniqueID)
            .style("stroke", "black")
            .attr("x1", xScaleBox(Range[j - 1][0]))
            .attr("y1", yScaleBox(Range[j - 1][1]))
            .attr("x2", xScaleBox(Range[j][0]))
            .attr("y2", yScaleBox(Range[j][1]));
        }

        channelAdjustment = boxSizeHeight / 3;

        for (let j = 1; j < Range.length; j++) {
          boxGraph
            .append("line")
            .attr("class", uniqueID)
            .style("stroke", "black")
            .attr("x1", xScaleBox(Range[j - 1][0]))
            .attr("y1", yScaleBox(Range[j - 1][1]) + channelAdjustment)
            .attr("x2", xScaleBox(Range[j][0]))
            .attr("y2", yScaleBox(Range[j][1]) + channelAdjustment);
        }

        channelAdjustment = (boxSizeHeight / 3) * 2;

        for (let j = 1; j < Range.length; j++) {
          boxGraph
            .append("line")
            .attr("class", uniqueID)
            .style("stroke", "black")
            .attr("x1", xScaleBox(Range[j - 1][0]))
            .attr("y1", yScaleBox(Range[j - 1][1]) + channelAdjustment)
            .attr("x2", xScaleBox(Range[j][0]))
            .attr("y2", yScaleBox(Range[j][1]) + channelAdjustment);
        }

        if (Peaks.length > 0) {
          let lastPeakTime = 0;

          let lastPeakX = 0;

          for (let j = 0; j < Peaks.length; j++) {
            let thisPeakX = xScaleBox(Peaks[j].value[0]);

            boxGraph
              .append("text")
              .attr("class", uniqueID)
              .text(Peaks[j].classification)
              .attr("fill", "#666666")
              .attr("font-size", "12px")
              .attr("x", thisPeakX - 6)
              .attr("y", 12);

            if (lastPeakTime > 0) {
              let timeDifference = Peaks[j].value[0] - lastPeakTime;
              let lastSectionBPM = Math.round(60 / timeDifference);
              let bpmX = (thisPeakX + lastPeakX) / 2;

              boxGraph
                .append("text")
                .attr("class", uniqueID)
                .text(lastSectionBPM)
                .attr("fill", "#888888")
                .attr("font-size", "9px")
                .attr("x", bpmX - 6)
                .attr("y", 25);
            }
            lastPeakTime = Peaks[j].value[0];
            lastPeakX = thisPeakX;
          }
        }

        boxGraph
          .append("rect")
          .attr("class", uniqueID)
          .attr("x", boxSizeWidth / 2)
          .attr("y", 0)
          .attr("width", 2)
          .attr("height", 210)
          .attr("fill", "rgba(70, 130, 180,0.6)");
      }
    }
  }, [tenSecondStartTime]);

  function getPeakDataInRangeFromAnnotations(StartTime: any, EndTime: any) {
    let startIndex = -1;
    let endIndex = -1;
    let arrayLength = props.allAnnotations.length;
    if (!arrayLength) return [];

    // Find the start index using binary search
    let left = 0;
    let right = arrayLength - 1;
    if (left === right) return [];
    while (left <= right) {
      const mid = Math.floor((left + right) / 2);
      const midTime = props.allAnnotations[mid].value[0];
      if (midTime >= StartTime) {
        startIndex = mid;
        right = mid - 1;
      } else {
        left = mid + 1;
      }
    }

    // Find the end index using binary search
    left = 0;
    right = arrayLength - 1;
    while (left <= right) {
      const mid = Math.floor((left + right) / 2);
      const midTime = props.allAnnotations[mid].value[0];
      if (midTime <= EndTime) {
        endIndex = mid;
        left = mid + 1;
      } else {
        right = mid - 1;
      }
    }

    // Extract the subset array
    if (startIndex !== -1 && endIndex !== -1 && startIndex <= endIndex) {
      return props.allAnnotations.slice(startIndex, endIndex + 1);
    } else {
      return [];
    }
  }

  function getPeakDataInRangeFromAnnotations_Backup(
    StartTime: any,
    EndTime: any
  ) {
    var StartTimeMinDiff = 1000;
    var StartTimeNearestBeat;
    var StartTimeNearestBeatIndex = -1;

    var EndTimeMinDiff = 1000;
    var EndTimeNearestBeat;
    var EndTimeNearestBeatIndex = -1;

    var StartMainIndex = -1;
    var EndMainIndex = -1;

    for (let i = 0; i < props.allAnnotations.length; i++) {
      let thisPeakTime = props.allAnnotations[i].value[0];
      var StartTimeMin = Math.abs(StartTime - thisPeakTime);
      if (StartTimeMin < StartTimeMinDiff) {
        StartTimeMinDiff = StartTimeMin;
        StartTimeNearestBeat = thisPeakTime;
        StartMainIndex = i;
      }

      var EndTimeMin = Math.abs(EndTime - thisPeakTime);
      if (EndTimeMin < EndTimeMinDiff) {
        EndTimeMinDiff = EndTimeMin;
        EndTimeNearestBeat = thisPeakTime;
        EndMainIndex = i;
      }
    }

    let ReturnArray = [];
    if (EndMainIndex > -1 && StartMainIndex > -1) {
      if (EndMainIndex >= StartMainIndex) {
        ReturnArray = props.allAnnotations.splice(StartMainIndex, EndMainIndex);
      }
    }

    return ReturnArray;
  }

  // This is the mouse down of the pink drabble area in the minute graph
  const MinuteGraphBoxMouseDown = (e: any) => {
    // Set the drag started so that mouse move is triggered
    setminuteGraphDragStarted(true);

    // Get the Click point - Only X since we don't have vertical movement
    let clickX = getMinuteGraphSVGx(e.pageX);

    // Find how many pixels the click was from the left of the box and store it
    let diffOfBoxFromLeftInPixels =
      ((tenSecondStartTimeRef.current - (minuteGraphTime - 30)) *
        minuteGraphWidth) /
      60;
    let localClickPixelsFromLeftOfBox = clickX - diffOfBoxFromLeftInPixels;
    setminuteGraphClickPixelsFromLeftOfBox(localClickPixelsFromLeftOfBox);
  };

  const MinuteGraphMouseUp = (e: any) => {
    setminuteGraphDragStarted(false);
    setminuteGraphClickPixelsFromLeftOfBox(0);
  };

  const MinuteGraphMouseMove = (e: any) => {
    // console.log('MouseMove. X:' + getSVGx(e.pageX) + ', Y:' + getSVGy(e.pageY));
    if (minuteGraphDragStartedRef.current) {
      // Since Drag has started, we need to move the box
      let mouseX = getMinuteGraphSVGx(e.pageX);

      let newXOfStartOfBox =
        mouseX - minuteGraphClickPixelsFromLeftOfBoxRef.current;
      if (newXOfStartOfBox < 0) {
        newXOfStartOfBox = 0;
      }

      if (newXOfStartOfBox > minuteGraphWidth * 0.83333) {
        newXOfStartOfBox = minuteGraphWidth * 0.83333;
      }

      let diffOfBoxFromLeftInSeconds =
        (newXOfStartOfBox / minuteGraphWidth) * 60;
      //  let newStartOfSplitBox = diffOfBoxFromLeftInSeconds + startOfThisZone;
      settenSecondStartTime(
        diffOfBoxFromLeftInSeconds + (minuteGraphTime - 30)
      );
    }
  };

  function getMinuteGraphSVGx(x: any) {
    return x - svgMinuteGraphLeft;
  }

  // Load the minute graph
  useEffect(() => {
    if (props.allAnnotations.length > 0) {
      var grapgWidth = document.getElementById("HRGraphRightMins")?.clientWidth;
      let boxSizeWidth = grapgWidth ? grapgWidth : 1392;
      let boxSizeHeight = 34;

      // We are hardcoding to show 3 seconds of data so 1.5 before and 1.5 after - change these if needed
      let boxStartTime = 30;
      let boxEndTime = 30;

      setminuteGraphWidth(boxSizeWidth);

      let boxGraph = d3
        .select("#" + "svgMinuteGraph")
        .attr("width", boxSizeWidth)
        .attr("height", boxSizeHeight)
        .style("overflow", "hidden")
        .on("mouseup", MinuteGraphMouseUp)
        .on("mousemove", MinuteGraphMouseMove);

      // Pick up the bounding rectangle of the minute graph and set the Left
      let boundingRect: any = document
        .getElementById("svgMinuteGraph")
        ?.getBoundingClientRect();
      setsvgMinuteGraphLeft(boundingRect?.left);

      let uniqueID = "svgMinuteGraph";
      d3.selectAll("." + uniqueID).remove();

      let l_startTime = minuteGraphTime - boxStartTime;
      let l_EndTime = minuteGraphTime + boxEndTime;

      let Range = props.getBeatDataInRange(l_startTime, l_EndTime, 0) as any;
      //console.log(Range);

      if (Range.length > 0) {
        let startTime = Range[0][0];
        let endTime = Range[Range.length - 1][0];

        // setting the scaling
        let xScaleBox = d3
          .scaleLinear()
          .domain([startTime, endTime])
          .range([0, boxSizeWidth]);

        let localYRange = 30000 / 10;

        let yScaleBox = d3
          .scaleLinear()
          .domain([-localYRange, localYRange])
          .range([boxSizeHeight, 0]);

        for (let j = 1; j < Range.length; j++) {
          boxGraph
            .append("line")
            .attr("class", uniqueID)
            .style("stroke", "black")
            .attr("x1", xScaleBox(Range[j - 1][0]))
            .attr("y1", yScaleBox(Range[j - 1][1]))
            .attr("x2", xScaleBox(Range[j][0]))
            .attr("y2", yScaleBox(Range[j][1]));
        }

        // If the tenSecondStartTime is outside of the minute range, set it to the middle of this range
        let localTenSecondStart = 0;
        if (
          tenSecondStartTimeRef.current < l_startTime ||
          tenSecondStartTimeRef.current > l_EndTime
        ) {
          localTenSecondStart = (l_startTime + l_EndTime) / 2 - 5;
          settenSecondStartTime(localTenSecondStart);
        } else {
          localTenSecondStart = tenSecondStartTimeRef.current;
        }

        boxGraph
          .append("rect")
          .attr("class", uniqueID)
          .attr("x", xScaleBox(localTenSecondStart))
          .attr("y", 0)
          .attr("width", boxSizeWidth / 6)
          .attr("height", boxSizeHeight)
          .attr("fill", "rgba(250, 160, 160,0.4)")
          .style("cursor", "move")
          .on("mousedown", MinuteGraphBoxMouseDown);
      }
    }
  }, [minuteGraphTime, tenSecondStartTime]);

  function getFilteredClassifications(p_Type: number) {
    let l_AllEvents = props.allAnnotations.filter(
      (o: any) => o.classification === (p_Type === 12 ? "AF" : "N")
    );

    let returnArray = [];

    if (globalState.BPMSelectionArray.length > 0) {
      for (let j = 0; j < globalState.BPMSelectionArray.length; j++) {
        for (let i = 0; i < l_AllEvents.length; i++) {
          if (
            l_AllEvents[i].value[0] >= globalState.BPMSelectionArray[j].boxST &&
            l_AllEvents[i].value[0] <= globalState.BPMSelectionArray[j].boxET &&
            l_AllEvents[i].bpm >= globalState.BPMSelectionArray[j].boxSBPM &&
            l_AllEvents[i].bpm <= globalState.BPMSelectionArray[j].boxEBPM
          ) {
            returnArray.push(l_AllEvents[i]);
          }
        }
      }
    } else {
      returnArray = l_AllEvents;
    }

    // let arrayLength = l_AllEvents.length;

    // if (props.leftMenuEventSelected <= 2) {
    //   arrayLength = 1;
    // } else if (props.leftMenuEventSelected <= 5) {
    //   arrayLength = 3;
    // }

    return returnArray.slice(0, 50);
  }

  function getFilteredAnnotations(p_Type: number) {
    let l_AllEvents = props.allAnnotations.filter(
      (o: any) => o.Type === p_Type
    );

    let arrayLength = l_AllEvents.length;

    if (props.leftMenuEventSelected <= 2) {
      arrayLength = 1;
    } else if (props.leftMenuEventSelected <= 5) {
      arrayLength = 3;
    }

    return l_AllEvents.slice(0, arrayLength);
  }

  function loadEventGraphInBox(p_Type: number) {
    //    console.log("annotations");
    //    console.log(props.allAnnotations);
    //let loadedType = utility.ECGClassification.Atrial_Fibrillation;

    // p_Type = AnnotationTagType.AF;
    //let l_AllEvents = getFilteredAnnotations(p_Type);
    console.log("loading event graph in box");
    let l_AllEvents = getFilteredClassifications(p_Type);
    console.log("event count : " + l_AllEvents.length);
    if (l_AllEvents && l_AllEvents.length > 0) {
      let firstStartTime = l_AllEvents[0].value[0];

      setSelectedStartTime(firstStartTime);
      l_AllEvents[0].IsStripe = true;
    }

    for (let i = 0; i < l_AllEvents.length; i++) {
      let thisStartTime = l_AllEvents[i].value[0];
      let thisID = "bid" + i;

      //      let thisStartTime = l_AllEvents[i].StartTime;
      //      let thisID = l_AllEvents[i].ID;

      var grapgWidth = document.getElementById("EventsBox")?.clientWidth;
      //let boxSizeWidth = grapgWidth ? grapgWidth : 1450;
      let boxSizeWidth = grapgWidth ? grapgWidth : 220;
      let boxSizeHeight =
        302 / props.gridViewInfo.Rows -
        ((props.gridViewInfo.Rows - 1) * 3 + 13);

      // We are hardcoding to show 3 seconds of data so 1.5 before and 1.5 after - change these if needed
      let boxStartTime = 1.5;
      let boxEndTime = 1.5;

      let boxGraph = d3
        .select("#" + "S" + thisID)
        .attr("width", boxSizeWidth)
        .attr("height", boxSizeHeight)
        .style("overflow", "hidden");

      let uniqueID = "EventsId" + i; // StripsAllAnnotations[i].ID;
      d3.selectAll("." + uniqueID).remove();

      // Define the pattern for the image
      const pattern = boxGraph
        .append("defs")
        .append("pattern")
        .attr("id", "eventBox-pattern")
        .attr("class", uniqueID)
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", boxSizeHeight * 2)
        .attr("height", boxSizeHeight)
        .attr("patternUnits", "userSpaceOnUse")
        .append("image")
        .attr("xlink:href", "/images/Grid3.png")
        .attr("width", boxSizeHeight * 2)
        .attr("height", boxSizeHeight);

      // Append a rectangle element with the image pattern
      boxGraph
        .append("rect")
        .attr("class", uniqueID)
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", boxSizeWidth)
        .attr("height", boxSizeHeight)
        .attr("fill", "url(#eventBox-pattern)");

      let l_startTime = thisStartTime - boxStartTime;
      let l_EndTime = thisStartTime + boxEndTime;

      let Range = props.getBeatDataInRange(l_startTime, l_EndTime, 0) as any;
      let Peaks = getPeakDataInRangeFromAnnotations(
        l_startTime,
        l_EndTime
      ) as any;

      if (Range.length > 0) {
        let startTime = Range[0][0];
        let endTime = Range[Range.length - 1][0];

        // setting the scaling
        let xScaleBox = d3
          .scaleLinear()
          .domain([startTime, endTime])
          .range([0, boxSizeWidth]);

        let localYRange = 30000 / 10;

        let yScaleBox = d3
          .scaleLinear()
          .domain([-localYRange, localYRange])
          .range([boxSizeHeight, 0]);

        // Mark area of Annotation in its own color
        let l_startTimeColor = thisStartTime - 0.2;
        let l_EndTimeColor = thisStartTime + 0.2;

        var lineColor = "green";

        for (let j = 1; j < Range.length; j++) {
          if (
            Range[j - 1][0] >= l_startTimeColor &&
            Range[j - 1][0] <= l_EndTimeColor
          ) {
            lineColor = "orange";
          } else {
            lineColor = "green";
          }

          boxGraph
            .append("line")
            .attr("class", uniqueID)
            .style("stroke", lineColor)
            .attr("x1", xScaleBox(Range[j - 1][0]))
            .attr("y1", yScaleBox(Range[j - 1][1]))
            .attr("x2", xScaleBox(Range[j][0]))
            .attr("y2", yScaleBox(Range[j][1]));
        }

        if (Peaks.length > 0) {
          let lastPeakTime = 0;

          let lastPeakX = 0;

          for (let j = 0; j < Peaks.length; j++) {
            let thisPeakX = xScaleBox(Peaks[j].value[0]);

            boxGraph
              .append("text")
              .attr("class", uniqueID)
              .text(Peaks[j].classification)
              .attr("fill", "#666666")
              .attr("font-size", "12px")
              .attr("x", thisPeakX - 6)
              .attr("y", 12);

            if (lastPeakTime > 0) {
              let timeDifference = Peaks[j].value[0] - lastPeakTime;
              let lastSectionBPM = Math.round(60 / timeDifference);
              let bpmX = (thisPeakX + lastPeakX) / 2;

              boxGraph
                .append("text")
                .attr("class", uniqueID)
                .text(lastSectionBPM)
                .attr("fill", "#888888")
                .attr("font-size", "9px")
                .attr("x", bpmX - 6)
                .attr("y", 32);
            }
            lastPeakTime = Peaks[j].value[0];
            lastPeakX = thisPeakX;
          }
        }
      }
    }
  }


// draw boxes using data from annotations API
  function loadSelectedEventGraphInBox() {
    let l_AllEvents = [...boxEventAnnotations];
    for (let i = 0; i < l_AllEvents.length; i++) {
//      let thisStartTime = l_AllEvents[i].ecgValues[0];
      let thisID = "selectedbid" + i;

      var grapgWidth =
        document.getElementById("SelectedEventsBox")?.clientWidth;
      //let boxSizeWidth = grapgWidth ? grapgWidth : 1450;
      let boxSizeWidth = grapgWidth ? grapgWidth : 220;
      let boxSizeHeight =
        302 / props.gridViewInfo.Rows -
        ((props.gridViewInfo.Rows - 1) * 3 + 13);

      // We are hardcoding to show 3 seconds of data so 1.5 before and 1.5 after - change these if needed
//      let boxStartTime = 1.5;
///      let boxEndTime = 1.5;

      let boxGraph = d3
        .select("#" + "S" + thisID)
        .attr("width", boxSizeWidth)
        .attr("height", boxSizeHeight)
        .style("overflow", "hidden");

      let uniqueID = "SelectedEventsId" + i; // StripsAllAnnotations[i].ID;
      d3.selectAll("." + uniqueID).remove();

      // Define the pattern for the image
      const pattern = boxGraph
        .append("defs")
        .append("pattern")
        .attr("id", "eventBox-pattern")
        .attr("class", uniqueID)
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", boxSizeHeight * 2)
        .attr("height", boxSizeHeight)
        .attr("patternUnits", "userSpaceOnUse")
        .append("image")
        .attr("xlink:href", "/images/Grid3.png")
        .attr("width", boxSizeHeight * 2)
        .attr("height", boxSizeHeight);

      // Append a rectangle element with the image pattern
      boxGraph
        .append("rect")
        .attr("class", uniqueID)
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", boxSizeWidth)
        .attr("height", boxSizeHeight)
        .attr("fill", "url(#eventBox-pattern)");

//      let l_startTime = thisStartTime - boxStartTime;
//      let l_EndTime = thisStartTime + boxEndTime;
      let Range = l_AllEvents[i].ecgValues.splice(0,375);
//      let Range = props.getBeatDataInRange(l_startTime, l_EndTime, 0) as any;
 /*     let Peaks = getPeakDataInRangeFromAnnotations(
        l_startTime,
        l_EndTime
      ) as any;
*/
      if (Range.length > 0) {
     //   let startTime = 0;
     //   let endTime = Range.length - 1;

        // setting the scaling
        let xScaleBox = d3
          .scaleLinear()
          .domain([0, Range.length - 1])
          .range([0, boxSizeWidth]);

        let localYRange = 30000 / 10;

        let yScaleBox = d3
          .scaleLinear()
          .domain([-localYRange/2, localYRange/2])
          .range([boxSizeHeight, 0]);

        // Mark area of Annotation in its own color
        let l_startTimeColor = 175; // thisStartTime - 0.2;
        let l_EndTimeColor = 225; // thisStartTime + 0.2;

        var lineColor = "green";

        for (let j = 1; j < Range.length; j++) {
          if (
            j >= l_startTimeColor &&
            j <= l_EndTimeColor
          ) {
            lineColor = "orange";
          } else {
            lineColor = "green";
          }

          boxGraph
            .append("line")
            .attr("class", uniqueID)
            .style("stroke", lineColor)
            .attr("x1", xScaleBox(j - 1))
            .attr("y1", yScaleBox(Range[j - 1]))
            .attr("x2", xScaleBox(j))
            .attr("y2", yScaleBox(Range[j]));
        }
/*
        if (Peaks.length > 0) {
          let lastPeakTime = 0;

          let lastPeakX = 0;

          for (let j = 0; j < Peaks.length; j++) {
            let thisPeakX = xScaleBox(Peaks[j].value[0]);

            boxGraph
              .append("text")
              .attr("class", uniqueID)
              .text(Peaks[j].classification)
              .attr("fill", "#666666")
              .attr("font-size", "12px")
              .attr("x", thisPeakX - 6)
              .attr("y", 12);

            if (lastPeakTime > 0) {
              let timeDifference = Peaks[j].value[0] - lastPeakTime;
              let lastSectionBPM = Math.round(60 / timeDifference);
              let bpmX = (thisPeakX + lastPeakX) / 2;

              boxGraph
                .append("text")
                .attr("class", uniqueID)
                .text(lastSectionBPM)
                .attr("fill", "#888888")
                .attr("font-size", "9px")
                .attr("x", bpmX - 6)
                .attr("y", 32);
            }
            lastPeakTime = Peaks[j].value[0];
            lastPeakX = thisPeakX;
          }
        } */
      }
    }
  }


  function loadEventGraphInBox_Backup(p_Type: number) {
    console.log("annotations");
    console.log(props.allAnnotations);
    let loadedType = utility.ECGClassification.Atrial_Flutter_Fixed_Block;

    // p_Type = AnnotationTagType.AF;
    //let l_AllEvents = getFilteredAnnotations(p_Type);

    let l_AllEvents = getFilteredClassifications(loadedType);
    console.log("filtered classifications");
    console.log(l_AllEvents);

    if (l_AllEvents && l_AllEvents.length > 0) {
      setSelectedStartTime(l_AllEvents[0].StartTime);
      l_AllEvents[0].IsStripe = true;
    }

    for (let i = 0; i < l_AllEvents.length; i++) {
      // let thisStartTime = l_AllEvents[i].StartTime;
      // let thisID = l_AllEvents[i].ID;

      var grapgWidth = document.getElementById("EventsBox")?.clientWidth;
      //let boxSizeWidth = grapgWidth ? grapgWidth : 1450;
      let boxSizeWidth = grapgWidth ? grapgWidth : 220;
      let boxSizeHeight =
        302 / props.gridViewInfo.Rows -
        ((props.gridViewInfo.Rows - 1) * 3 + 13);

      // We are hardcoding to show 3 seconds of data so 1.5 before and 1.5 after - change these if needed
      let boxStartTime = 1.5;
      let boxEndTime = 1.5;

      let boxGraph = d3
        .select("#" + "S" + l_AllEvents[i].ID)
        .attr("width", boxSizeWidth)
        .attr("height", boxSizeHeight)
        .style("overflow", "hidden");

      let uniqueID = "EventsId" + i; // StripsAllAnnotations[i].ID;
      d3.selectAll("." + uniqueID).remove();

      // Define the pattern for the image
      const pattern = boxGraph
        .append("defs")
        .append("pattern")
        .attr("id", "eventBox-pattern")
        .attr("class", uniqueID)
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", boxSizeHeight * 2)
        .attr("height", boxSizeHeight)
        .attr("patternUnits", "userSpaceOnUse")
        .append("image")
        .attr("xlink:href", "/images/Grid3.png")
        .attr("width", boxSizeHeight * 2)
        .attr("height", boxSizeHeight);

      // Append a rectangle element with the image pattern
      boxGraph
        .append("rect")
        .attr("class", uniqueID)
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", boxSizeWidth)
        .attr("height", boxSizeHeight)
        .attr("fill", "url(#eventBox-pattern)");

      let l_startTime = l_AllEvents[i].StartTime - boxStartTime;
      let l_EndTime = l_AllEvents[i].StartTime + boxEndTime;

      let Range = props.getBeatDataInRange(l_startTime, l_EndTime, 0) as any;
      let Peaks = getPeakDataInRangeFromAnnotations(
        l_startTime,
        l_EndTime
      ) as any;

      if (Range.length > 0) {
        let startTime = Range[0][0];
        let endTime = Range[Range.length - 1][0];

        // setting the scaling
        let xScaleBox = d3
          .scaleLinear()
          .domain([startTime, endTime])
          .range([0, boxSizeWidth]);

        let localYRange = 30000 / 10;

        let yScaleBox = d3
          .scaleLinear()
          .domain([-localYRange, localYRange])
          .range([boxSizeHeight, 0]);

        // Mark area of Annotation in its own color
        let l_startTimeColor = l_AllEvents[i].StartTime - 0.2;
        let l_EndTimeColor = l_AllEvents[i].StartTime + 0.2;

        var lineColor = "green";

        for (let j = 1; j < Range.length; j++) {
          if (
            Range[j - 1][0] >= l_startTimeColor &&
            Range[j - 1][0] <= l_EndTimeColor
          ) {
            lineColor = "orange";
          } else {
            lineColor = "green";
          }

          boxGraph
            .append("line")
            .attr("class", uniqueID)
            .style("stroke", lineColor)
            .attr("x1", xScaleBox(Range[j - 1][0]))
            .attr("y1", yScaleBox(Range[j - 1][1]))
            .attr("x2", xScaleBox(Range[j][0]))
            .attr("y2", yScaleBox(Range[j][1]));
        }

        if (Peaks.length > 0) {
          let lastPeakTime = 0;

          let lastPeakX = 0;

          for (let j = 0; j < Peaks.length; j++) {
            let thisPeakX = xScaleBox(Peaks[j].value[0]);

            boxGraph
              .append("text")
              .attr("class", uniqueID)
              .text(Peaks[j].classification)
              .attr("fill", "#666666")
              .attr("font-size", "12px")
              .attr("x", thisPeakX - 6)
              .attr("y", 12);

            if (lastPeakTime > 0) {
              let timeDifference = Peaks[j].value[0] - lastPeakTime;
              let lastSectionBPM = Math.round(60 / timeDifference);
              let bpmX = (thisPeakX + lastPeakX) / 2;

              boxGraph
                .append("text")
                .attr("class", uniqueID)
                .text(lastSectionBPM)
                .attr("fill", "#888888")
                .attr("font-size", "9px")
                .attr("x", bpmX - 6)
                .attr("y", 32);
            }
            lastPeakTime = Peaks[j].value[0];
            lastPeakX = thisPeakX;
          }
        }
      }
    }
  }

  function boxClicked(startTime: any) {
    hideAutofillIfOpen();
    const newArray = [...props.allAnnotations];
    if (props.isStripeToogleView === false) {
      setSelectedStartTime(startTime);
    } else {
      for (let i = 0; i < newArray.length; i++) {
        if (startTime === newArray[i].StartTime) {
          if (newArray[i].IsStripe === true) {
            newArray[i].IsStripe = false;
          } else {
            newArray[i].IsStripe = true;
          }
          break;
        }
      }
      props.setAllAnnotations(newArray);
    }
  }

  function ReturnAnnotationsCount(p_intType: any) {
    let l_intCount = 0;
    for (let i = 0; i < props.allAnnotations.length; i++) {
      if (props.allAnnotations[i].Type === p_intType) {
        l_intCount++;
      }
    }
    return l_intCount;
  }

  // TODO : Need to set limits on these Next and Previous
  function EventsGraphNext() {
    let newMinuteGraphTime = minuteGraphTime + 10;
    // TODO: Put a check in to test the end of data
    setminuteGraphTime(newMinuteGraphTime);
    settenSecondStartTime(newMinuteGraphTime - 5);
  }
  function EventsGraphPrevious() {
    let newMinuteGraphTime = minuteGraphTime - 10;
    // TODO: Instead of 30, it should be checking start of data + 30
    if (newMinuteGraphTime < 30) {
      newMinuteGraphTime = 30;
    }
    setminuteGraphTime(newMinuteGraphTime);
    settenSecondStartTime(newMinuteGraphTime - 5);
  }

  function hideAutofillIfOpen() {
    setShowAutofill(false);
  }

  function handleRightClick(e: any, startTime: any) {
    e.preventDefault();
    let screenWidth = window.innerWidth;
    var l_clientX = e.clientX;
    if (l_clientX > screenWidth - 300) {
      l_clientX = l_clientX - 250;
    }

    setClientX(l_clientX);
    setClientY(e.clientY);

    setAutoFill(autoFillArray);
    if (e.type === "contextmenu") {
      setSelectedAutofillBox(startTime);
      setShowAutofill(true);
    }
  }

  useEffect(() => {
    if (autoFillValue !== "") {
      filterByKeyUp();
    } else {
      setAutoFill(autoFillArray);
    }
  }, [autoFillValue]);

  function filterByKeyUp() {
    let filterArray = filterByValue(autoFillArray, autoFillValue);
    setAutoFill(filterArray);
  }

  function filterByValue(array: any, value: string) {
    return array.filter(
      (data: any) =>
        JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  function handleAutofillClick(id: number, Code: string, TagType: number) {
    const newArray = [...props.allAnnotations];
    for (let i = 0; i < newArray.length; i++) {
      if (
        selectedAutofillBox > 0 &&
        selectedAutofillBox === newArray[i].StartTime
      ) {
        newArray[i].Type = TagType;
      }
    }
    props.setAllAnnotations(newArray);
    setShowAutofill(false);
  }

  function handleMoveGraph() {
    props.setSelectedView(AppContext.view.Analysis);
    props.handleMoveGraph(selectedAutofillBox, 0);
  }

  useEffect(() => {
    if (
      globalState.BPMSelectionArray &&
      globalState.BPMSelectionArray.length > 0
    ) {
      getEventAnnotation();
    } else {
      setBoxEventAnnotations([]);
    }
  }, [globalState.BPMSelectionArray]);

  useEffect(() => {
    if (boxEventAnnotations && boxEventAnnotations.length > 0) {
      loadSelectedEventGraphInBox();
    }
  }, [boxEventAnnotations]);

  const getEventAnnotation = async () => {
    try {
      const res: any = await getEventAnnotations(
        globalState.fileId,
        globalState.BPMSelectionArray
      );
      console.log("----getEventAnnotations----");
      console.log(res.data);
      setBoxEventAnnotations(res.data.beatAnnotations);
    } catch (e) {
      console.log(e);
    }
  };


  return (
    <>
      {showAutofill && (
        <div
          className="events--right--click"
          style={{ left: clientX, top: clientY }}
        >
          {/* <input
            autoFocus
            type="text"
            onChange={(e) => setAutoFillValue(e.target.value)}
          />
          <div className="auto-fill-ryth custom--scroll">
            {autoFill &&
              autoFill.map((Item: any, index: any) => (
                <a
                  key={index}
                  onClick={() =>
                    handleAutofillClick(Item.Id, Item.Code, Item.TagType)
                  }
                >
                  {Item.Name}
                </a>
              ))}
          </div> */}
          <a onClick={() => handleMoveGraph()}>Show in Analysis page</a>
        </div>
      )}

      <div className="Events--top--main">
        <div className="Events--main">
          <div
            className={
              "Events--inn custom--scroll2" +
              " e-row-" +
              props.gridViewInfo.Rows +
              " e-col-" +
              props.gridViewInfo.Columns
            }
          >
            {props.allAnnotations &&
              props.allAnnotations.length > 0 &&
              globalState.BPMSelectionArray.length == 0 && (
                <>
                  {getFilteredClassifications(props.leftMenuType).map(
                    (Item: any, index: number) => (
                      <React.Fragment key={"bid" + index}>
                        <>
                          {
                            <div
                              className={`Events--box ${
                                (Item.value[0] === selectedStartTime &&
                                  !props.isStripeToogleView) ||
                                (Item.IsStripe && props.isStripeToogleView)
                                  ? "SelectedBox"
                                  : ""
                              }`}
                              onClick={(e) => {
                                boxClicked(Item.value[0]);
                              }}
                              onContextMenu={(e) =>
                                handleRightClick(e, Item.value[0])
                              }
                              id="EventsBox"
                            >
                              <svg id={"Sbid" + index}></svg>

                              <span className="Events--box--num">
                                {index + 1}/
                                {ReturnAnnotationsCount(props.leftMenuType)}
                              </span>

                              {/* {Item.ShowAutofill && (
                              <div className="events--right--click">
                                <input
                                  autoFocus
                                  type="text"
                                  onChange={(e) =>
                                    setAutoFillValue(e.target.value)
                                  }
                                />
                                <div className="auto-fill-ryth custom--scroll">
                                  {autoFill &&
                                    autoFill.map((Item: any, index: any) => (
                                      <a
                                        key={index}
                                        onClick={() =>
                                          handleAutofillClick(
                                            Item.Id,
                                            Item.Code,
                                            Item.TagType
                                          )
                                        }
                                      >
                                        {Item.Name}
                                      </a>
                                    ))}
                                </div>
                              </div>
                            )} */}
                            </div>
                          }
                        </>
                      </React.Fragment>
                    )
                  )}
                </>
              )}

            {boxEventAnnotations && boxEventAnnotations.length > 0 && globalState.BPMSelectionArray.length > 0 && (
              <>
                {boxEventAnnotations.map((Item: any, index: number) => (
                  <React.Fragment key={"bid" + index}>
                    <>
                      {
                        <div className="Events--box" id="SelectedEventsBox">
                          <svg id={"Sselectedbid" + index}></svg>
                          <span className="Events--box--num">
                            {index + 1}/
                            {ReturnAnnotationsCount(props.leftMenuType)}
                          </span>
                        </div>
                      }
                    </>
                  </React.Fragment>
                ))}
              </>
            )}
          </div>
        </div>

        {/*<div className="Events--main Events--inn--graph">*/}
        {/*  <div className="Events--inn">*/}
        {/*    <div className="channel-graph">*/}
        {/*      <div className="Channel-graph--inn">*/}
        {/*        <div className="Channel-left">*/}
        {/*          <span>RR</span>*/}
        {/*          <span>CH 1</span>*/}
        {/*          <span>CH 2</span>*/}
        {/*          <span>CH 3</span>*/}
        {/*        </div>*/}
        {/*        <div id="ChannelRight" className="Channel-right">*/}
        {/*          <svg id="svgThreeChannelsGraph"></svg>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <div className="hr-graph">*/}
        {/*      <div className="hr-graph-left">HR</div>*/}
        {/*      <div className="hr-graph-right">*/}
        {/*        <div id="HRGraphRightMins" className="hr-graph-right-mins">*/}
        {/*          <svg id="svgMinuteGraph"></svg>*/}
        {/*        </div>*/}
        {/*        <div className="hr-graph-right-btns">*/}
        {/*          <a onClick={() => EventsGraphPrevious()}>*/}
        {/*            <img src="/images/a-prev.svg" alt="img" />*/}
        {/*          </a>*/}
        {/*          <a onClick={() => EventsGraphNext()}>*/}
        {/*            <img src="/images/a-next.svg" alt="img" />*/}
        {/*          </a>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </>
  );
}

export default Events;
