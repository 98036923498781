import { HiDocumentReport } from "react-icons/hi";
import { jsPDF } from "jspdf";
import React, {useState} from "react";
import Box from "../../Box";
import Pdf from "react-to-pdf";
import axios from "axios";
import {paylaod} from "./payloaf";
import {getReportHTML} from "../../../../utils/axios";

const ref = React.createRef();
export default function ReportLayout({ children }) {
    const [reportHTML, setReportHTML] = useState('')
    const [loading, setLoading] = useState(false)

    const generateReport = async () =>  {
        try {
            setLoading(true)
            const res = await getReportHTML(paylaod)
            setReportHTML(res.data)
            setLoading(false)
        } catch (e) {
            console.log(e)
            setLoading(false)
        }
    }
  // function SavePdf() {
  //   var doc = new jsPDF('p', 'pt', 'a4')
  //   console.log('onClick is working')
  //   doc.html(document.querySelector('#pdfImg'), {
  //     callback: function (pdf) {
  //       pdf.save('new3.pdf')
  //     },
  //   })
  // }

  return (
    <Box width="w-full" pb="0" pt="0" px="0" className="mt-3 mb-8  ">
      <div className="flex items-center justify-between border-b h-14 px-10 ">
        <h2 className="    flex  items-center text-xl font-bold   ">Reports</h2>
        {/* <GenerateReportBtn onClick={SavePdf} /> */}
          <button
              onClick={generateReport}
              disabled={loading}
              className="h-11 w-[223px] flex items-center justify-center rounded-lg bg-[#27962D] shadow-[0px_20px_40px_rgba(153,153,153,0.25)] gap-2 text-white font-bold"
          >
              <HiDocumentReport className="text-2xl" />
              {loading ? 'Generating...' :<span>Generate Report</span>}
          </button>
      </div>
      <div
        ref={ref}
        className=" px-12 2xl:px-16 pt-24 pb-28 text-[#231F20]"
        id="pdfImg"
      >
        {/* {children} */}
        <div className="report-images">
          <div className={'max-w-[38%]'} dangerouslySetInnerHTML={{
               __html: reportHTML,
          }} />
        </div>
      </div>
    </Box>
  );
}

