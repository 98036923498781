import Divider from '../../components/Divider'
import ReportHeader from '../../components/ReportHeader'
import HightchartsReactGraph from './../../components/HightchartsReactGraph'

export default function MCT_RV_RRInterval() {
  return (
    <div className="min-h-screen">
      <ReportHeader
        boxInfo="Documented strips "
        startDate=" 01/01/2022 "
        patientInfo="Robert Downey/M/12/02/1985    "
        boxInfoBg="#FDE9E9"
      />
      {/* <HightchartsReactGraph options={} style={``} /> */}
      <Divider bgColor="bg-[#A83232]" className="mb-6" />
    </div>
  )
}
