//import PatientReportLayout from '../PatientReportLayout'
import classnames from "classnames";
import { useState, useEffect } from "react";
// data
import {
  patientInformation,
  serviceManagement,
  studySetting,
  referringPhysician,
} from "./data";
import { usePatientData } from "./context/PatientDataContext";
import { usePhysicianData } from "./context/PhysicianDataContext";



// PatientDemographicsPage.js

export default function PatientDemographicsPage() {
  const { patientData, setPatientData } = usePatientData();
  const { physicianData, setPhysicianData } = usePhysicianData();

  return (
    <div className="flex gap-4 w-full ">
      <Left setPatientData={setPatientData} />
      <Right setPhysicianData={setPhysicianData} />
    </div>
  );
}

function Layout({ title, children }) {
  return (
    <div
      className="bg-white rounded-[10px] shadow-[0px_15px_30px_rgba(179,179,179,0.06)]
    w-full min-h-[141px]
    "
    >
      <div className="px-[68px] h-[51px] border-b-[1px] border-[#E9E9E9] font-bold flex items-center">
        {title}
      </div>

      {children}
    </div>
  );
}

function LabelAndValue({ className, data }) {
  return (
    <>
      <div
        className={classnames(
          className,
          "px-2 2xl:px-16 flex flex-wrap gap-y-4 gap-x-8 py-8"
        )}
      >
        {data.map(({ label, value }, index) => (
          <div
            key={index}
            className="flex w-[46%]  justify-between items-start"
          >
            <label className="font-semibold whitespace-nowrap">{label} :</label>
            <value className="">{value} </value>
          </div>
        ))}
      </div>
    </>
  );
}

// Left.js

const Left = () => {
  const { patientData, setPatientData, editableData, setEditableData } =
    usePatientData();

  const [editedPatientInformation, setEditedPatientInformation] = useState([]);

  useEffect(() => {
    setEditedPatientInformation(patientData);
  }, [patientData]);

  const handleInputChange = (index, newValue) => {
    const updatedData = [...editedPatientInformation];
    updatedData[index].value = newValue;
    setEditedPatientInformation(updatedData);
  };

  const handleSave = () => {
    console.log("Before Save:", editedPatientInformation);
    setEditableData(editedPatientInformation);
    console.log("After Save:", editedPatientInformation);
    
  };

  return (
    <div className="flex flex-col gap-[9px] w-1/2">
      <Layout title="Patient Information">
        {editedPatientInformation.map(({ label, value }, index) => (
          <div key={index} className="flex w-[46%] justify-between items-start">
            <label className="font-semibold whitespace-nowrap">{label} :</label>
            <input
              type="text"
              value={value}
              onChange={(e) => handleInputChange(index, e.target.value)}
            />
          </div>
        ))}
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={handleSave}
        >
          Save
        </button>
      </Layout>
      <Layout title="Service Management">
        <LabelAndValue data={serviceManagement} />
      </Layout>
      <Layout title="Diagnostic Information"></Layout>
      <Layout title="Study Logs"></Layout>
      <Layout title="Physician Amendment notes"></Layout>
    </div>
  );
};

// Right.js

const Right = () => {
  const { physicianData, editablePhysicianData, setPhysicianData } =
    usePhysicianData();

  // Use state to manage edited data
  const [editedPhysicianData, setEditedPhysicianData] = useState([]);

  // Function to handle input change for physician
  const handlePhysicianInputChange = (index, newValue) => {
    const updatedData = [...editedPhysicianData];
    updatedData[index].value = newValue;
    setEditedPhysicianData(updatedData);
  };

  // Function to save edited physician data
  const handlePhysicianSave = () => {
    setPhysicianData(editedPhysicianData);
    
  };

  useEffect(() => {
    setEditedPhysicianData(physicianData);
  }, [physicianData]);

  return (
    <div className="flex flex-col gap-[9px] w-1/2">
      <Layout title="Doctor Information">
        {editedPhysicianData.map(({ label, value }, index) => (
          <div key={index} className="flex w-[46%] justify-between items-start">
            <label className="font-semibold whitespace-nowrap">{label} :</label>
            <input
              type="text"
              value={value}
              onChange={(e) =>
                handlePhysicianInputChange(index, e.target.value)
              }
            />
          </div>
        ))}
        {/* Save button for physician data */}
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handlePhysicianSave}>Save</button>
      </Layout>
      <Layout title="Study Setting">
        <LabelAndValue data={studySetting} />
      </Layout>
      <Layout title="Study Notes"></Layout>
      <Layout title="Study History"></Layout>
      
    </div>
  );
};

// function Left() {
//   return (
//     <div className="flex flex-col gap-[9px] w-1/2">
//       <Layout title="Patient Information">
//         <LabelAndValue data={patientInformation} />
//       </Layout>
//       <Layout title="Service Management">
//         <LabelAndValue data={serviceManagement} />
//       </Layout>
//       <Layout title="Diagnostic Information"></Layout>
//       <Layout title="Study Logs"></Layout>
//       <Layout title="Physician Amendment notes"></Layout>
//     </div>
//   )
// }

// function Right() {
//   return (
//     <div className="flex flex-col gap-[9px] w-1/2">
//       <Layout title="Doctor Information">
//         <h1 className="font-semibold pl-[68px] mt-4">Referring physician</h1>
//         <LabelAndValue data={referringPhysician} />
//         <h1 className="font-semibold pl-[68px]">Referring physician</h1>
//         <LabelAndValue data={referringPhysician} />
//       </Layout>
//       <Layout title="Study Setting">
//         <LabelAndValue data={studySetting} />
//       </Layout>
//       <Layout title="Study Notes"></Layout>
//       <Layout title="Study History"></Layout>
//     </div>
//   )
// }
