import classnames from 'classnames'
import { useState } from 'react'
import useOnclickOutside from 'react-cool-onclickoutside'
import { CgCloseO } from 'react-icons/cg'
import SummaryECG from './Graphs'
import Container from './../Container'

export default function ToggleOptions() {
  const [openToggleOptions, setToggleOptions] = useState(false)
  const ref = useOnclickOutside(() => {
    setToggleOptions(false)
  })

  return (
    <>
      <ToggleBtn
        for={openToggleOptions && 'my-modal'}
        onClick={() => setToggleOptions(true)}
      />
      <label
        for={!openToggleOptions && 'my-modal'}
        className="modal flex  items-start justify-start "
      >
        {/* <div ref={ref} className=" w-[766px] ml-28 mt-[340px]  "> */}
        <div
          ref={ref}
          className="  h-full    w-[766px] ml-28 pt-[340px] pb-10   flex gap-4 flex-col overflow-y-scroll scrollbar-hide  "
        >
          {/* //////// */}
          {/* This checkBox is hidden but use to handle the sideEffect of DaisyUI model */}
          <input type="checkbox" name="" id="" className="hidden" />
          {/* //////// */}

          <HeaderFooterSection />
          <SummaryECG />
        </div>
      </label>
    </>
  )
}

function ToggleBtn({ className, ...props }) {
  return (
    <>
      <label
        {...props}
        className={classnames(
          'h-[38px] font-medium shadow-[0px_20px_40px_rgba(153,153,153,0.25)] text-white rounded  w-[157px]  bg-[#8085E9] flex items-center justify-center cursor-pointer',
          className,
        )}
      >
        Toggle Options
      </label>
      <input type="checkbox" id="my-modal" className="modal-toggle" />
    </>
  )
}

function HeaderFooterSection() {
  return (
    <Container px="px-[54px]" className=" pt-[35px] pb-12">
      <section className="flex items-center justify-between mb-10">
        <h2 className="font-bold text-xl">Header / Footer</h2>
        <label for="my-modal" className="cursor-pointer">
          <CgCloseO className="text-3xl" />
        </label>
      </section>
      {/* section 2 */}
      <section>
        {/* Header */}
        <div className="flex mb-4">
          <p className="font-medium text-[#525252]">Header</p>
          <div className="flex items-center gap-10 ml-[78px]">
            <CheckBoxAndLabel label="None" />
            <CheckBoxAndLabel label="Small" />
            <CheckBoxAndLabel label="Standard" />
          </div>
        </div>
        <div className="flex ">
          <p className="font-medium text-[#525252]">Footer</p>
          <div className="flex items-center gap-10 ml-[82px]">
            <CheckBoxAndLabel label="None" />
            <CheckBoxAndLabel label="Standard" />
          </div>
        </div>
      </section>
    </Container>
  )
}

function CheckBoxAndLabel({ label }) {
  return (
    <div className="flex items-center gap-4 ">
      {/* <input type="checkbox" id={id} className="rounded-md h-6 w-6" /> */}
      <input type="checkbox" id={label} class="checkbox border-[#616161] " />
      <label htmlFor={label} className="text-sm cursor-pointer">
        {label}
      </label>
    </div>
  )
}
