export const paylaod = {
    "reportName": "Holter Report",
    "date": new Date().toLocaleDateString(),
    "patientDetails":{
        "name": "Robert Downey",
        "gender": "male",
        "age": "67",
        "DOB": "12/02/1985",
        "indication": "ICD-10-CM-150.20 (CHF)",
        "Pacemaker": "ICO",
        "monitoredTime": "336 hrs",
        "analysisTime": "321 hrs"
    },
    "orderingPhysicianDetails":{
        "name": "Dr. William Smith",
        "addLine1": "D#1, 5th Street,",
        "addLine2": "Haddows Road - 552 612"
    },
    "interpretingPhysicianDetails":{
        "name": "Dr. Samuel Mickey",
        "addLine1": "#1, 7th Street,",
        "addLine2": "Frost Hills - 521 362"
    },
    "monitoringSummary": {
        "heartRateData": {
            "totalBeats": "1401476",
            "minHr": "56 bpm 01/08 @02:14:29",
            "maxHr": "133 bpm 01/05 @21:43:47",
            "avgHr": "86 bpm",
            "dayAvg": "90 bpm",
            "nightAvg": "82 bpm"
        },
        "atrialFibrillation": {
            "totalAf": "5 episodes (0.01 % Burden)",
            "afDuration": "30 Minutes",
            "fastestAf": "130 bpm",
            "totalAfl": "2",
            "aflDuration": "2 Minutes",
            "fastestAfl": "90 bpm"
        },
        "patientReported": {
            "reportedEvents": "10"
        },
        "ventricular": {
            "total": "1401 (0.10 % Burden)",
            "single": "5",
            "couplet": "5",
            "run3beats": "10",
            "fastestRun": "130 bpm (5 beats)",
            "longestRun": "120 bpm (10 beats)",
            "bigeminy": "10 episodes",
            "trigeminy": "189 episodes"

        },
        "pauseAsystole": {
            "pause3s": "102 episodes",
            "asystole3s": "--"
        },
        "supraventricular": {
            "total": "981 (&lt;0.07 % Burden)",
            "single": "5",
            "couplet": "10",
            "run3beats": "2",
            "fastest": "1750 bpm (6 beats)",
            "longest": "1680 bpm (10 beats)",
            "bigeminy": "10 episodes",
            "trigeminy": "5 episodes"
        },
        "other": {
            "thirdDegreeHb": "102 episodes",
            "secondDegreeType1": "--",
            "junctional": "60 beats",
            "pacemaker": "5 beats"
        }
    },
    "age": 30,
    "city": "New York",
    "isStudent": false,
    "grades": [90, 85, 92],
    "address": {
        "street": "123 Main St",
        "zipCode": "10001",
        "state": "NY"
    },
    "analysis": {
        "technicianComments": "1. Predominant rhythm was sinus.\n2. Min HR of 56 bpm Max HR of 98 bpm and Avg HR of 55 bpm.\n3. Supraventricular ectopics were noted.\n",
        "physicianInterpretation": "Abnormality tendencies spotted, immediate diagnosis required"
    },
    "beatSummary": [
        {
            "classification": "Afib",
            "percent": 20
        },
        {
            "classification": "SR",
            "percent": 30
        },
        {
            "classification": "ST",
            "percent": 10
        },
        {
            "classification": "N",
            "percent": 40
        },
        {
            "classification": "Other",
            "percent": 10
        }
    ],
    "documentedStrips": [
        {
            "ecgValues": [
                666,
                672,
                664,
                661,
                650,
                646,
                654,
                661,
                669,
                651,
                649,
                665,
                668,
                658,
                655,
                671,
                709,
                734,
                753,
                752,
                717,
                679,
                643,
                615,
                600,
                599,
                603,
                600,
                549,
                461,
                693,
                1212,
                1216,
                759,
                485,
                487,
                556,
                592,
                591,
                596,
                599,
                600,
                615,
                629,
                628,
                633,
                635,
                639,
                644,
                651,
                666,
                671,
                675,
                686,
                691,
                694,
                690,
                693,
                708,
                716,
                701,
                673,
                657,
                646,
                633,
                618,
                611,
                607,
                599,
                615,
                631,
                632,
                635,
                639,
                640,
                655,
                653,
                628,
                635,
                646,
                650,
                655,
                651,
                658,
                665,
                653,
                637,
                628,
                651,
                684,
                716,
                749,
                775,
                777,
                755,
                708,
                677,
                658,
                646,
                640,
                632,
                633,
                639,
                600,
                524,
                733,
                1200,
                1208,
                800,
                540,
                522,
                585,
                631,
                653,
                658,
                653,
                660,
                669,
                660,
                669,
                690,
                702,
                717,
                728,
                731,
                741,
                759,
                759,
                730,
                711,
                701,
                711,
                719,
                738,
                748,
                733,
                728,
                715,
                671,
                654,
                642,
                635,
                642,
                632,
                624,
                625,
                640,
                658,
                664,
                662,
                658,
                665,
                672,
                664,
                655,
                657,
                649,
                651,
                660,
                655,
                650,
                649,
                668,
                679,
                682,
                694,
                719,
                745,
                761,
                760,
                730,
                677,
                640,
                633,
                628,
                624,
                624,
                606,
                533,
                533,
                901,
                1275,
                1037,
                600,
                468,
                533,
                578,
                602,
                621,
                637,
                639,
                632,
                646,
                647,
                649,
                662,
                669,
                666,
                675,
                690,
                716,
                741,
                738,
                733,
                724,
                728,
                727,
                741,
                746,
                752,
                737,
                711,
                698,
                694,
                676,
                655,
                658,
                642,
                669,
                680,
                677,
                675,
                682,
                687,
                684,
                675,
                699,
                701,
                698,
                693,
                699,
                733,
                730,
                702,
                660,
                687,
                686,
                706,
                722,
                781,
                779,
                782,
                771,
                698,
                695,
                657,
                639,
                649,
                647,
                632,
                640,
                602,
                552,
                814,
                1253,
                1164,
                505,
                504,
                523,
                588,
                636,
                635,
                642,
                647,
                637,
                636,
                635,
                639,
                651,
                650,
                662,
                669,
                677,
                680,
                673,
                679,
                680,
                695,
                694,
                706,
                699,
                695,
                694,
                682,
                655,
                626,
                625,
                614,
                602,
                581,
                580,
                599,
                610,
                609,
                609,
                607,
                604,
                607,
                611,
                615,
                614,
                610,
                625,
                625,
                624,
                611,
                600,
                615,
                614,
                629,
                669,
                687,
                686,
                699,
                686,
                613,
                611,
                570,
                556,
                529,
                519,
                502,
                501,
                384,
                661,
                1114,
                1025,
                570,
                333,
                337,
                395,
                420,
                424,
                431,
                421,
                420,
                387,
                381,
                383,
                381,
                365,
                363,
                356,
                349,
                366,
                370,
                363,
                363,
                362,
                351,
                343,
                345,
                338,
                322,
                299,
                270,
                241,
                208,
                191,
                173,
                150,
                147,
                143,
                132,
                114,
                103,
                111,
                117,
                108,
                95,
                82,
                81,
                86,
                79,
                64,
                48,
                34,
                34,
                34,
                34,
                41,
                50,
                52,
                50,
                48,
                27,
                -23,
                -78,
                -104,
                -125,
                -141,
                -153,
                -162,
                -196,
                -277,
                -120,
                362,
                439,
                438,
                -333,
                -359,
                -323,
                -396,
                -475,
                -572,
                -674,
                -713,
                -713,
                -640,
                -556,
                -535,
                -531,
                -534,
                -537,
                -532,
                -525,
                -516,
                -508,
                -506,
                -516,
                -520,
                -512,
                -506,
                -505,
                -501,
                -509,
                -530,
                -550,
                -571,
                -590,
                -605,
                -616,
                -625,
                -621,
                -619,
                -622,
                -621,
                -615,
                -614,
                -616,
                -629,
                -634,
                -634,
                -636,
                -641,
                -645,
                -647,
                -594,
                -490,
                -444,
                -428,
                -406,
                -381,
                -357,
                -348,
                -355,
                -379,
                -425,
                -472,
                -530,
                -603,
                -560,
                -524,
                -521,
                -543,
                -615,
                -497,
                -64,
                53,
                -316,
                -619,
                -670,
                -612,
                -576,
                -564,
                -563,
                -565,
                -567,
                -561,
                -554,
                -552,
                -548,
                -538,
                -538,
                -530,
                -512,
                -499,
                -492,
                -499,
                -502,
                -491,
                -503,
                -538,
                -548,
                -537,
                -531,
                -541,
                -556,
                -570,
                -583,
                -593,
                -601,
                -614,
                -615,
                -614,
                -614,
                -608,
                -605,
                -614,
                -618,
                -614,
                -611,
                -608,
                -607,
                -604,
                -603,
                -604,
                -605,
                -600,
                -593,
                -583,
                -578,
                -565,
                -543,
                -514,
                -503,
                -517,
                -554,
                -592,
                -625,
                -648,
                -665,
                -670,
                -672,
                -676,
                -721,
                -768,
                -527,
                -107,
                -178,
                -579,
                -797,
                -780,
                -717,
                -698,
                -705,
                -703,
                -691,
                -677,
                -669,
                -666,
                -666,
                -656,
                -638,
                -625,
                -622,
                -623,
                -618,
                -592,
                -585,
                -607,
                -614,
                -614,
                -614,
                -610,
                -618,
                -626,
                -636,
                -654,
                -670,
                -684,
                -702,
                -713,
                -720,
                -718,
                -699,
                -683,
                -677,
                -674,
                -670,
                -665,
                -659,
                -662,
                -673,
                -685,
                -681,
                -677,
                -673,
                -673,
                -685,
                -680,
                -689,
                -692,
                -678,
                -658,
                -633,
                -616,
                -619,
                -645,
                -683,
                -725,
                -754,
                -760,
                -762,
                -767,
                -764,
                -791,
                -874,
                -760,
                -262,
                -56,
                -465,
                -831,
                -875,
                -804,
                -769,
                -768,
                -774,
                -774,
                -778,
                -778,
                -768,
                -765,
                -769,
                -765,
                -756,
                -760,
                -774,
                -764,
                -758,
                -765,
                -764,
                -771,
                -780,
                -772,
                -758,
                -753,
                -754,
                -761,
                -772,
                -798,
                -825,
                -847,
                -853,
                -848,
                -842,
                -849,
                -853,
                -859,
                -859,
                -852,
                -844,
                -844,
                -851,
                -853,
                -862,
                -863,
                -859,
                -853,
                -849,
                -845,
                -849,
                -855,
                -848,
                -833,
                -813,
                -796,
                -776,
                -772,
                -787,
                -818,
                -856,
                -884,
                -898,
                -898,
                -903,
                -902,
                -910,
                -998,
                -946,
                -470,
                -137,
                -505,
                -950,
                -1050,
                -987,
                -926,
                -910,
                -900,
                -898,
                -904,
                -911,
                -903,
                -903,
                -902,
                -899,
                -893,
                -884,
                -882,
                -875,
                -864,
                -859,
                -860,
                -867,
                -867,
                -866,
                -864,
                -862,
                -864,
                -871,
                -887,
                -904,
                -925,
                -937,
                -946,
                -958,
                -964,
                -969,
                -972,
                -969,
                -969,
                -975,
                -962,
                -951,
                -957,
                -955,
                -953,
                -955,
                -955,
                -958,
                -961,
                -966,
                -969,
                -953,
                -935,
                -926,
                -909,
                -898,
                -893,
                -852,
                -863,
                -904,
                -939,
                -961,
                -972,
                -980,
                -986,
                -987,
                -991,
                -1063,
                -1060,
                -648,
                -246,
                -521,
                -980,
                -1115,
                -1057,
                -1006,
                -997,
                -995,
                -994,
                -990,
                -971,
                -951,
                -954,
                -955,
                -954,
                -950,
                -936,
                -925,
                -911,
                -904,
                -907,
                -911,
                -914,
                -926,
                -929,
                -928,
                -915,
                -907,
                -909,
                -910,
                -920,
                -936,
                -954,
                -960,
                -960,
                -965,
                -973,
                -972,
                -960,
                -947,
                -946,
                -942,
                -924,
                -920,
                -925,
                -926,
                -925,
                -921,
                -918,
                -915,
                -909,
                -942,
                -991,
                -980,
                -960,
                -936,
                -909,
                -878,
                -848,
                -849,
                -867,
                -900,
                -936,
                -960,
                -973,
                -976,
                -980,
                -976,
                -990,
                -1060,
                -1026,
                -618,
                -305,
                -592,
                -964,
                -1067,
                -1011,
                -964,
                -954,
                -958,
                -944,
                -947,
                -953,
                -943,
                -926,
                -914,
                -904,
                -896,
                -896,
                -899,
                -895,
                -884,
                -874,
                -873,
                -874,
                -874,
                -882,
                -895,
                -877,
                -860,
                -860,
                -864,
                -867,
                -880,
                -903,
                -926,
                -932,
                -928,
                -918,
                -904,
                -906,
                -903,
                -880,
                -867,
                -870,
                -884,
                -887,
                -881,
                -887,
                -884,
                -871,
                -862,
                -855,
                -863,
                -875,
                -880,
                -864,
                -842,
                -820,
                -785,
                -747,
                -731,
                -743,
                -787,
                -859,
                -891,
                -882,
                -871,
                -874,
                -889,
                -893,
                -946,
                -929,
                -559,
                -246,
                -516,
                -881,
                -993,
                -936,
                -881,
                -856,
                -841,
                -830,
                -820,
                -807,
                -812,
                -809,
                -793,
                -786,
                -774,
                -772,
                -772,
                -772,
                -761,
                -751,
                -742,
                -728,
                -745,
                -756,
                -745,
                -717,
                -714,
                -721,
                -728,
                -754,
                -783,
                -815,
                -820,
                -823,
                -844,
                -845,
                -838,
                -837,
                -831,
                -825,
                -820,
                -815,
                -802,
                -802,
                -812,
                -813,
                -811,
                -808,
                -813,
                -826,
                -834,
                -819,
                -809,
                -790,
                -767,
                -751,
                -736,
                -734,
                -750,
                -790,
                -831,
                -842,
                -852,
                -855,
                -864,
                -880,
                -893,
                -966,
                -937,
                -512,
                -191,
                -501,
                -915,
                -1008,
                -939,
                -899,
                -895,
                -893,
                -900,
                -898,
                -866,
                -878,
                -904,
                -899,
                -896,
                -920,
                -913,
                -888,
                -878,
                -878,
                -871,
                -871,
                -892,
                -903,
                -900,
                -885,
                -874,
                -869,
                -877,
                -898,
                -924,
                -944,
                -962,
                -957,
                -984,
                -1099,
                -1158,
                -1079,
                -1008,
                -1011,
                -998,
                -988,
                -991,
                -984,
                -980,
                -976,
                -977,
                -984,
                -1011,
                -1038,
                -1004,
                -988,
                -986,
                -971,
                -951,
                -928,
                -913,
                -884,
                -877,
                -940,
                -993,
                -1013,
                -1024,
                -1037,
                -1024,
                -1022,
                -1035,
                -1121,
                -1157,
                -791,
                -364,
                -572,
                -1001,
                -1170,
                -1148,
                -1084,
                -1060,
                -1067,
                -1056,
                -1046,
                -1042,
                -1038,
                -1035,
                -1033,
                -1033,
                -1035,
                -1041,
                -1049,
                -1038,
                -1022,
                -1009,
                -1005,
                -1017,
                -1022,
                -1009,
                -1009,
                -1013,
                -1006,
                -1008,
                -1009,
                -1039,
                -1075,
                -1081,
                -1092,
                -1111,
                -1106,
                -1092,
                -1095,
                -1095,
                -1089,
                -1095,
                -1097,
                -1077,
                -1064,
                -1066,
                -1064,
                -1068,
                -1073,
                -1068,
                -1070,
                -1074,
                -1064,
                -1046,
                -1045,
                -1019,
                -998,
                -1009,
                -997,
                -997,
                -1030,
                -1074,
                -1099,
                -1108,
                -1124,
                -1118,
                -1097,
                -1107,
                -1186,
                -1198,
                -837,
                -425,
                -647,
                -1071,
                -1205,
                -1172,
                -1141,
                -1122,
                -1100,
                -1086,
                -1086,
                -1088,
                -1086,
                -1075,
                -1066,
                -1057,
                -1039,
                -1020,
                -1017,
                -1031,
                -1028,
                -1019,
                -1015,
                -1006,
                -995,
                -993,
                -994,
                -977,
                -964,
                -968,
                -977,
                -994,
                -1001,
                -1011,
                -1027,
                -1034,
                -1033,
                -1037,
                -1028,
                -1026,
                -1033,
                -1027,
                -1016,
                -1009,
                -1008,
                -1002,
                -990,
                -984,
                -1026,
                -1070,
                -1011,
                -988,
                -995,
                -987,
                -973,
                -950,
                -913,
                -892,
                -884,
                -882,
                -914,
                -947,
                -980,
                -1002,
                -1009,
                -1015,
                -1009,
                -1008,
                -1042,
                -1106,
                -920,
                -473,
                -439,
                -822,
                -1075,
                -1082,
                -1026,
                -1004,
                -1001,
                -999,
                -994,
                -997,
                -993,
                -988,
                -977,
                -964,
                -951,
                -944,
                -950,
                -949,
                -936,
                -928,
                -928,
                -931,
                -928,
                -918,
                -915,
                -918,
                -917,
                -909,
                -917,
                -943,
                -955,
                -968,
                -987,
                -986,
                -986,
                -988,
                -998,
                -1005,
                -1001,
                -1001,
                -999,
                -994,
                -984,
                -982,
                -991,
                -993,
                -991,
                -991,
                -993,
                -994,
                -988,
                -977,
                -957,
                -939,
                -922,
                -903,
                -884,
                -878,
                -899,
                -929,
                -962,
                -990,
                -997,
                -997,
                -998,
                -997,
                -1006,
                -1078,
                -1052,
                -629,
                -326,
                -633
            ],
            "bpm": 88,
            "nextStrip": {
                "ecgValues": [
                    -1022,
                    -1112,
                    -1037,
                    -984,
                    -977,
                    -972,
                    -957,
                    -951,
                    -942,
                    -933,
                    -929,
                    -920,
                    -910,
                    -909,
                    -898,
                    -885,
                    -880,
                    -871,
                    -866,
                    -862,
                    -851,
                    -844,
                    -840,
                    -834,
                    -827,
                    -823,
                    -826,
                    -841,
                    -859,
                    -871,
                    -885,
                    -899,
                    -907,
                    -913,
                    -909,
                    -896,
                    -885,
                    -881,
                    -884,
                    -881,
                    -873,
                    -873,
                    -877,
                    -895,
                    -914,
                    -891,
                    -884,
                    -892,
                    -892,
                    -882,
                    -880,
                    -864,
                    -840,
                    -815,
                    -797,
                    -782,
                    -791,
                    -827,
                    -871,
                    -895,
                    -900,
                    -900,
                    -891,
                    -892,
                    -924,
                    -998,
                    -928,
                    -455,
                    -140,
                    -508,
                    -940,
                    -1024,
                    -960,
                    -918,
                    -914,
                    -904,
                    -889,
                    -881,
                    -875,
                    -862,
                    -848,
                    -838,
                    -827,
                    -818,
                    -801,
                    -786,
                    -785,
                    -789,
                    -786,
                    -778,
                    -771,
                    -772,
                    -765,
                    -762,
                    -754,
                    -745,
                    -749,
                    -765,
                    -778,
                    -789,
                    -805,
                    -816,
                    -820,
                    -829,
                    -825,
                    -809,
                    -796,
                    -787,
                    -785,
                    -786,
                    -776,
                    -758,
                    -749,
                    -749,
                    -747,
                    -740,
                    -746,
                    -753,
                    -751,
                    -746,
                    -740,
                    -720,
                    -698,
                    -680,
                    -658,
                    -647,
                    -670,
                    -706,
                    -736,
                    -756,
                    -757,
                    -756,
                    -747,
                    -735,
                    -760,
                    -845,
                    -720,
                    -189,
                    48,
                    -382,
                    -775,
                    -818,
                    -739,
                    -700,
                    -706,
                    -703,
                    -692,
                    -688,
                    -688,
                    -677,
                    -661,
                    -659,
                    -658,
                    -650,
                    -630,
                    -618,
                    -605,
                    -603,
                    -600,
                    -586,
                    -575,
                    -568,
                    -557,
                    -546,
                    -542,
                    -552,
                    -567,
                    -581,
                    -590,
                    -599,
                    -607,
                    -622,
                    -623,
                    -619,
                    -614,
                    -612,
                    -607,
                    -593,
                    -587,
                    -590,
                    -582,
                    -572,
                    -568,
                    -578,
                    -579,
                    -572,
                    -571,
                    -576,
                    -572,
                    -561,
                    -560,
                    -560,
                    -532,
                    -501,
                    -476,
                    -465,
                    -468,
                    -494,
                    -537,
                    -564,
                    -582,
                    -592,
                    -587,
                    -587,
                    -597,
                    -612,
                    -680,
                    -630,
                    -211,
                    46,
                    -284,
                    -655,
                    -742,
                    -685,
                    -640,
                    -633,
                    -632,
                    -632,
                    -623,
                    -601,
                    -594,
                    -581,
                    -565,
                    -556,
                    -552,
                    -552,
                    -538,
                    -534,
                    -527,
                    -512,
                    -505,
                    -501,
                    -495,
                    -495,
                    -487,
                    -473,
                    -469,
                    -486,
                    -502,
                    -516,
                    -528,
                    -538,
                    -543,
                    -556,
                    -561,
                    -554,
                    -550,
                    -546,
                    -541,
                    -530,
                    -513,
                    -512,
                    -503,
                    -487,
                    -477,
                    -480,
                    -492,
                    -492,
                    -488,
                    -488,
                    -484,
                    -480,
                    -480,
                    -473,
                    -461,
                    -435,
                    -403,
                    -384,
                    -388,
                    -408,
                    -439,
                    -476,
                    -499,
                    -508,
                    -509,
                    -502,
                    -514,
                    -523,
                    -572,
                    -581,
                    -231,
                    122,
                    -105,
                    -502,
                    -641,
                    -585,
                    -527,
                    -514,
                    -519,
                    -517,
                    -506,
                    -492,
                    -477,
                    -465,
                    -459,
                    -448,
                    -437,
                    -436,
                    -439,
                    -441,
                    -432,
                    -424,
                    -415,
                    -404,
                    -404,
                    -397,
                    -388,
                    -382,
                    -381,
                    -397,
                    -408,
                    -422,
                    -440,
                    -448,
                    -458,
                    -468,
                    -473,
                    -470,
                    -465,
                    -473,
                    -476,
                    -465,
                    -484,
                    -473,
                    -447,
                    -433,
                    -421,
                    -421,
                    -430,
                    -440,
                    -443,
                    -443,
                    -454,
                    -458,
                    -451,
                    -452,
                    -446,
                    -428,
                    -400,
                    -359,
                    -326,
                    -311,
                    -326,
                    -360,
                    -395,
                    -418,
                    -419,
                    -408,
                    -406,
                    -413,
                    -421,
                    -499,
                    -594,
                    -313,
                    141,
                    30,
                    -375,
                    -538,
                    -495,
                    -424,
                    -397,
                    -392,
                    -390,
                    -379,
                    -364,
                    -359,
                    -349,
                    -328,
                    -312,
                    -305,
                    -301,
                    -287,
                    -279,
                    -286,
                    -284,
                    -258,
                    -235,
                    -226,
                    -222,
                    -218,
                    -221,
                    -213,
                    -207,
                    -220,
                    -240,
                    -247,
                    -257,
                    -273,
                    -273,
                    -271,
                    -272,
                    -275,
                    -269,
                    -257,
                    -244,
                    -238,
                    -233,
                    -225,
                    -221,
                    -207,
                    -198,
                    -192,
                    -185,
                    -192,
                    -203,
                    -203,
                    -189,
                    -180,
                    -177,
                    -166,
                    -148,
                    -112,
                    -89,
                    -90,
                    -116,
                    -152,
                    -180,
                    -200,
                    -204,
                    -203,
                    -206,
                    -204,
                    -235,
                    -323,
                    -215,
                    249,
                    421,
                    50,
                    -268,
                    -335,
                    -269,
                    -206,
                    -184,
                    -180,
                    -169,
                    -160,
                    -152,
                    -145,
                    -131,
                    -118,
                    -108,
                    -101,
                    -89,
                    -78,
                    -74,
                    -58,
                    -47,
                    -53,
                    -50,
                    -43,
                    -34,
                    -13,
                    -6,
                    -13,
                    -31,
                    -54,
                    -74,
                    -87,
                    -102,
                    -112,
                    -118,
                    -126,
                    -134,
                    -131,
                    -126,
                    -119,
                    -112,
                    -109,
                    -108,
                    -102,
                    -93,
                    -82,
                    -79,
                    -80,
                    -74,
                    -64,
                    -56,
                    -46,
                    -36,
                    -35,
                    -29,
                    -9,
                    13,
                    46,
                    67,
                    64,
                    37,
                    0,
                    -31,
                    -50,
                    -51,
                    -51,
                    -49,
                    -46,
                    -98,
                    -163,
                    81,
                    498,
                    429,
                    55,
                    -147,
                    -133,
                    -56,
                    -16,
                    -9,
                    -14,
                    -13,
                    -2,
                    5,
                    12,
                    30,
                    34,
                    33,
                    30,
                    34,
                    42,
                    44,
                    46,
                    52,
                    50,
                    59,
                    68,
                    70,
                    70,
                    70,
                    59,
                    37,
                    15,
                    0,
                    -16,
                    -23,
                    -31,
                    -36,
                    -40,
                    -39,
                    -31,
                    -29,
                    -34,
                    -27,
                    -20,
                    -7,
                    -7,
                    -2,
                    -9,
                    -14,
                    2,
                    1,
                    -10,
                    -12,
                    -12,
                    9,
                    27,
                    49,
                    70,
                    92,
                    128,
                    122,
                    79,
                    33,
                    -9,
                    -23,
                    -21,
                    -20,
                    -29,
                    -32,
                    -46,
                    -119,
                    -64,
                    337,
                    558,
                    263,
                    -60,
                    -162,
                    -109,
                    -51,
                    -29,
                    -25,
                    -16,
                    -6,
                    1,
                    13,
                    22,
                    37,
                    42,
                    45,
                    53,
                    64,
                    71,
                    81,
                    101,
                    107,
                    100,
                    110,
                    119,
                    117,
                    117,
                    117,
                    106,
                    70,
                    -17,
                    -39,
                    1,
                    4,
                    -7,
                    -12,
                    -2,
                    4,
                    11,
                    17,
                    20,
                    22,
                    28,
                    27,
                    31,
                    35,
                    34,
                    33,
                    30,
                    33,
                    28,
                    33,
                    46,
                    62,
                    84,
                    107,
                    129,
                    147,
                    154,
                    155,
                    132,
                    106,
                    86,
                    66,
                    60,
                    59,
                    62,
                    71,
                    50,
                    -25,
                    104,
                    538,
                    650,
                    289,
                    -6,
                    -50,
                    31,
                    86,
                    106,
                    117,
                    125,
                    130,
                    141,
                    148,
                    157,
                    174,
                    191,
                    188,
                    195,
                    213,
                    225,
                    234,
                    248,
                    257,
                    267,
                    272,
                    281,
                    293,
                    303,
                    290,
                    267,
                    242,
                    214,
                    214,
                    213,
                    208,
                    199,
                    206,
                    213,
                    214,
                    221,
                    221,
                    228,
                    241,
                    242,
                    248,
                    264,
                    279,
                    283,
                    286,
                    290,
                    293,
                    297,
                    307,
                    312,
                    314,
                    316,
                    336,
                    367,
                    394,
                    395,
                    377,
                    344,
                    304,
                    286,
                    276,
                    272,
                    275,
                    279,
                    272,
                    221,
                    197,
                    496,
                    927,
                    815,
                    378,
                    155,
                    161,
                    235,
                    274,
                    289,
                    293,
                    293,
                    294,
                    301,
                    304,
                    307,
                    308,
                    315,
                    325,
                    338,
                    345,
                    349,
                    356,
                    365,
                    378,
                    389,
                    399,
                    405,
                    403,
                    406,
                    396,
                    396,
                    396,
                    388,
                    362,
                    332,
                    318,
                    311,
                    310,
                    301,
                    300,
                    304,
                    299,
                    299,
                    296,
                    297,
                    304,
                    304,
                    307,
                    308,
                    305,
                    308,
                    299,
                    283,
                    265,
                    260,
                    276,
                    312,
                    343,
                    361,
                    380,
                    388,
                    383,
                    356,
                    326,
                    292,
                    265,
                    250,
                    248,
                    239,
                    224,
                    157,
                    135,
                    480,
                    890,
                    702,
                    271,
                    88,
                    115,
                    192,
                    221,
                    223,
                    225,
                    223,
                    220,
                    224,
                    224,
                    230,
                    245,
                    253,
                    265,
                    285,
                    299,
                    301,
                    308,
                    315,
                    326,
                    337,
                    351,
                    359,
                    359,
                    354,
                    330,
                    307,
                    293,
                    272,
                    252,
                    237,
                    237,
                    231,
                    223,
                    217,
                    220,
                    228,
                    238,
                    245,
                    250,
                    263,
                    270,
                    267,
                    263,
                    276,
                    290,
                    303,
                    300,
                    301,
                    307,
                    311,
                    307,
                    312,
                    316,
                    322,
                    347,
                    365,
                    376,
                    384,
                    365,
                    336,
                    310,
                    290,
                    285,
                    282,
                    281,
                    276,
                    285,
                    249,
                    173,
                    370,
                    868,
                    921,
                    487,
                    191,
                    168,
                    243,
                    290,
                    303,
                    312,
                    319,
                    326,
                    338,
                    347,
                    352,
                    372,
                    387,
                    396,
                    416,
                    429,
                    412,
                    431,
                    458,
                    467,
                    474,
                    489,
                    509,
                    519,
                    513,
                    504,
                    480,
                    456,
                    438,
                    427,
                    412,
                    400,
                    395,
                    384,
                    374,
                    376,
                    373,
                    376,
                    378,
                    383,
                    389,
                    403,
                    416,
                    427,
                    432,
                    442,
                    449,
                    457,
                    465,
                    467,
                    474,
                    469,
                    468,
                    476,
                    480,
                    491,
                    511,
                    527,
                    548,
                    573,
                    604,
                    651,
                    676,
                    679,
                    665,
                    626,
                    584,
                    573,
                    574,
                    564,
                    570,
                    592,
                    556,
                    454,
                    618,
                    1094,
                    1154,
                    723,
                    434,
                    407,
                    457,
                    507,
                    530,
                    534,
                    537,
                    553,
                    589,
                    617,
                    631,
                    632,
                    626,
                    552,
                    467,
                    400,
                    301,
                    263,
                    272,
                    326,
                    429,
                    496,
                    537,
                    548,
                    547,
                    538,
                    522,
                    502,
                    486,
                    476,
                    462,
                    446,
                    436,
                    438,
                    442,
                    457,
                    469,
                    469,
                    465,
                    472,
                    474,
                    493,
                    527,
                    570,
                    575,
                    552,
                    555,
                    564,
                    558,
                    540,
                    540,
                    542,
                    537,
                    541,
                    563,
                    573,
                    571,
                    574,
                    591,
                    702,
                    803,
                    839,
                    857,
                    884,
                    914,
                    906,
                    880,
                    855,
                    830,
                    819,
                    824,
                    825,
                    821,
                    812,
                    752,
                    730,
                    1083,
                    1537,
                    1375,
                    910,
                    708,
                    730,
                    775,
                    797,
                    803,
                    814,
                    832,
                    846,
                    841,
                    835,
                    843,
                    848,
                    858,
                    876,
                    888,
                    903,
                    913,
                    930,
                    956,
                    985,
                    1012,
                    1015,
                    1021,
                    1023,
                    1003,
                    961,
                    921,
                    897,
                    880,
                    862,
                    852,
                    846,
                    840,
                    830,
                    814,
                    800,
                    814,
                    836,
                    851,
                    863,
                    881,
                    890,
                    887,
                    886,
                    879,
                    863,
                    863,
                    879,
                    884,
                    884,
                    876,
                    873,
                    883,
                    883,
                    881,
                    892,
                    898,
                    912,
                    917,
                    924,
                    936,
                    953,
                    976,
                    1007,
                    1018,
                    1044,
                    1118,
                    1161,
                    1128,
                    1083,
                    1051,
                    1038,
                    1027,
                    1016,
                    1012,
                    1014,
                    1011,
                    945,
                    908,
                    1249,
                    1745,
                    1620,
                    1149,
                    931,
                    953,
                    1027,
                    1056,
                    1045,
                    1044,
                    1059,
                    1061,
                    1055,
                    1052,
                    1061,
                    1088,
                    1135,
                    1167,
                    1182,
                    1187,
                    1162,
                    1135,
                    1121,
                    1120,
                    1132,
                    1138,
                    1128,
                    1117,
                    1132,
                    1153,
                    1124,
                    1074,
                    1049,
                    1041,
                    1021,
                    1007,
                    1011,
                    1030,
                    1036,
                    1037,
                    1021,
                    1007,
                    1014,
                    1027,
                    1044,
                    1066,
                    1100,
                    1125,
                    1149,
                    1185,
                    1238,
                    1296,
                    1313,
                    1344,
                    1380,
                    1395,
                    1383,
                    1361,
                    1379,
                    1391,
                    1402,
                    1420,
                    1434,
                    1428,
                    1420,
                    1435,
                    1461,
                    1444,
                    1416,
                    1411,
                    1424,
                    1434,
                    1412,
                    1386,
                    1351,
                    1298,
                    1270,
                    1248,
                    1233,
                    1230,
                    1224,
                    1216,
                    1216,
                    1161,
                    1169,
                    1569,
                    2022,
                    1796,
                    1293,
                    1106,
                    1146,
                    1212,
                    1240,
                    1240,
                    1236,
                    1231,
                    1231,
                    1233,
                    1219,
                    1216,
                    1226,
                    1241,
                    1256,
                    1252,
                    1242,
                    1236,
                    1222,
                    1209,
                    1212,
                    1224,
                    1231,
                    1220,
                    1202,
                    1182,
                    1157,
                    1135,
                    1121,
                    1091,
                    1067,
                    1049,
                    1008,
                    985,
                    989,
                    985,
                    974,
                    985,
                    994,
                    998,
                    994,
                    992,
                    993,
                    996,
                    1003,
                    1012,
                    1014,
                    1005,
                    997,
                    997,
                    993,
                    985,
                    976,
                    967,
                    963,
                    964,
                    964,
                    970,
                    964,
                    952,
                    945,
                    945,
                    942,
                    938
                ]
            },
            "previousStrip": {
                "ecgValues": [
                    938,
                    938,
                    948,
                    956,
                    952,
                    943,
                    941,
                    949,
                    992,
                    1026,
                    1027,
                    1014,
                    993,
                    959,
                    898,
                    874,
                    890,
                    901,
                    902,
                    897,
                    859,
                    795,
                    1027,
                    1518,
                    1495,
                    1044,
                    785,
                    775,
                    832,
                    852,
                    854,
                    852,
                    851,
                    851,
                    868,
                    888,
                    888,
                    886,
                    887,
                    892,
                    906,
                    910,
                    909,
                    897,
                    883,
                    876,
                    913,
                    928,
                    931,
                    941,
                    957,
                    956,
                    938,
                    901,
                    866,
                    862,
                    855,
                    840,
                    825,
                    825,
                    821,
                    808,
                    806,
                    812,
                    814,
                    818,
                    822,
                    824,
                    835,
                    850,
                    833,
                    832,
                    835,
                    836,
                    846,
                    866,
                    862,
                    826,
                    818,
                    818,
                    818,
                    825,
                    814,
                    800,
                    796,
                    801,
                    806,
                    792,
                    795,
                    807,
                    785,
                    775,
                    768,
                    775,
                    790,
                    800,
                    807,
                    821,
                    855,
                    890,
                    903,
                    877,
                    835,
                    793,
                    763,
                    748,
                    753,
                    753,
                    745,
                    735,
                    658,
                    720,
                    1179,
                    1449,
                    1087,
                    698,
                    609,
                    672,
                    717,
                    728,
                    730,
                    738,
                    752,
                    755,
                    757,
                    763,
                    759,
                    759,
                    774,
                    790,
                    799,
                    812,
                    814,
                    814,
                    830,
                    858,
                    880,
                    912,
                    909,
                    892,
                    897,
                    899,
                    884,
                    848,
                    835,
                    811,
                    771,
                    757,
                    763,
                    759,
                    760,
                    766,
                    771,
                    779,
                    884,
                    1114,
                    993,
                    727,
                    668,
                    657,
                    675,
                    713,
                    750,
                    771,
                    807,
                    874,
                    930,
                    852,
                    767,
                    737,
                    708,
                    715,
                    748,
                    793,
                    817,
                    837,
                    829,
                    821,
                    830,
                    846,
                    865,
                    873,
                    851,
                    851,
                    858,
                    810,
                    775,
                    773,
                    790,
                    828,
                    850,
                    824,
                    724,
                    698,
                    1081,
                    1585,
                    1428,
                    917,
                    693,
                    705,
                    761,
                    792,
                    803,
                    797,
                    788,
                    800,
                    818,
                    826,
                    839,
                    843,
                    843,
                    847,
                    857,
                    868,
                    880,
                    891,
                    902,
                    921,
                    932,
                    954,
                    976,
                    981,
                    965,
                    960,
                    957,
                    923,
                    902,
                    886,
                    879,
                    883,
                    888,
                    883,
                    857,
                    851,
                    854,
                    858,
                    840,
                    825,
                    828,
                    832,
                    841,
                    858,
                    870,
                    873,
                    866,
                    851,
                    861,
                    866,
                    865,
                    874,
                    873,
                    858,
                    848,
                    858,
                    865,
                    862,
                    855,
                    862,
                    873,
                    883,
                    931,
                    952,
                    945,
                    921,
                    883,
                    837,
                    811,
                    815,
                    801,
                    773,
                    777,
                    781,
                    719,
                    695,
                    1058,
                    1526,
                    1351,
                    902,
                    709,
                    720,
                    767,
                    775,
                    785,
                    807,
                    833,
                    835,
                    832,
                    829,
                    818,
                    824,
                    819,
                    828,
                    840,
                    836,
                    835,
                    841,
                    866,
                    881,
                    884,
                    880,
                    884,
                    874,
                    786,
                    644,
                    617,
                    708,
                    757,
                    749,
                    733,
                    712,
                    701,
                    713,
                    720,
                    701,
                    695,
                    694,
                    690,
                    683,
                    666,
                    664,
                    675,
                    677,
                    668,
                    664,
                    662,
                    651,
                    642,
                    639,
                    637,
                    629,
                    609,
                    622,
                    653,
                    653,
                    639,
                    637,
                    643,
                    643,
                    647,
                    684,
                    704,
                    699,
                    687,
                    664,
                    636,
                    607,
                    573,
                    553,
                    556,
                    570,
                    553,
                    482,
                    439,
                    738,
                    1191,
                    1078,
                    631,
                    403,
                    405,
                    443,
                    461,
                    464,
                    474,
                    472,
                    476,
                    487,
                    494,
                    490,
                    505,
                    518,
                    511,
                    490,
                    494,
                    511,
                    523,
                    541,
                    544,
                    526,
                    534,
                    553,
                    566,
                    573,
                    566,
                    531,
                    515,
                    497,
                    460,
                    447,
                    420,
                    388,
                    370,
                    378,
                    389,
                    381,
                    374,
                    372,
                    388,
                    392,
                    365,
                    366,
                    389,
                    388,
                    366,
                    362,
                    366,
                    358,
                    341,
                    329,
                    304,
                    292,
                    308,
                    311,
                    310,
                    312,
                    300,
                    316,
                    343,
                    351,
                    356,
                    340,
                    315,
                    303,
                    281,
                    237,
                    224,
                    209,
                    184,
                    163,
                    101,
                    77,
                    440,
                    928,
                    748,
                    254,
                    45,
                    52,
                    100,
                    135,
                    147,
                    143,
                    139,
                    118,
                    110,
                    119,
                    126,
                    146,
                    166,
                    165,
                    155,
                    148,
                    158,
                    162,
                    169,
                    184,
                    198,
                    199,
                    199,
                    191,
                    172,
                    133,
                    84,
                    73,
                    66,
                    63,
                    50,
                    34,
                    44,
                    62,
                    56,
                    42,
                    46,
                    59,
                    50,
                    52,
                    67,
                    64,
                    68,
                    81,
                    82,
                    86,
                    89,
                    89,
                    84,
                    86,
                    85,
                    70,
                    64,
                    64,
                    74,
                    79,
                    99,
                    129,
                    170,
                    197,
                    184,
                    165,
                    140,
                    112,
                    82,
                    67,
                    64,
                    56,
                    55,
                    68,
                    24,
                    -47,
                    214,
                    746,
                    717,
                    231,
                    -31,
                    -12,
                    57,
                    68,
                    53,
                    57,
                    63,
                    71,
                    77,
                    74,
                    60,
                    55,
                    68,
                    75,
                    88,
                    106,
                    93,
                    82,
                    85,
                    100,
                    106,
                    103,
                    110,
                    136,
                    155,
                    157,
                    140,
                    86,
                    60,
                    62,
                    52,
                    48,
                    38,
                    20,
                    23,
                    20,
                    4,
                    1,
                    0,
                    -7,
                    5,
                    22,
                    22,
                    13,
                    11,
                    12,
                    15,
                    0,
                    -16,
                    -18,
                    -20,
                    -23,
                    -27,
                    -28,
                    -32,
                    -27,
                    -9,
                    12,
                    26,
                    42,
                    55,
                    45,
                    22,
                    -14,
                    -40,
                    -60,
                    -78,
                    -85,
                    -82,
                    -100,
                    -131,
                    -214,
                    -145,
                    279,
                    502,
                    170,
                    -182,
                    -257,
                    -187,
                    -136,
                    -136,
                    -153,
                    -164,
                    -162,
                    -160,
                    -169,
                    -174,
                    -169,
                    -162,
                    -156,
                    -147,
                    -155,
                    -162,
                    -160,
                    -140,
                    -115,
                    -116,
                    -136,
                    -126,
                    -138,
                    -159,
                    -163,
                    -178,
                    -202,
                    -226,
                    -239,
                    -233,
                    -235,
                    -254,
                    -276,
                    -271,
                    -269,
                    -266,
                    -276,
                    -272,
                    -257,
                    -266,
                    -272,
                    -265,
                    -257,
                    -258,
                    -260,
                    -255,
                    -240,
                    -246,
                    -262,
                    -271,
                    -266,
                    -260,
                    -264,
                    -276,
                    -268,
                    -254,
                    -247,
                    -226,
                    -200,
                    -178,
                    -178,
                    -244,
                    -302,
                    -316,
                    -327,
                    -350,
                    -362,
                    -359,
                    -368,
                    -415,
                    -481,
                    -304,
                    166,
                    242,
                    -175,
                    -480,
                    -525,
                    -470,
                    -430,
                    -428,
                    -424,
                    -435,
                    -446,
                    -436,
                    -429,
                    -414,
                    -390,
                    -385,
                    -385,
                    -375,
                    -377,
                    -393,
                    -396,
                    -379,
                    -366,
                    -360,
                    -352,
                    -352,
                    -345,
                    -337,
                    -335,
                    -337,
                    -353,
                    -382,
                    -407,
                    -429,
                    -436,
                    -448,
                    -461,
                    -458,
                    -457,
                    -452,
                    -436,
                    -421,
                    -424,
                    -439,
                    -451,
                    -444,
                    -443,
                    -451,
                    -446,
                    -428,
                    -429,
                    -446,
                    -444,
                    -433,
                    -428,
                    -422,
                    -424,
                    -432,
                    -429,
                    -410,
                    -392,
                    -359,
                    -337,
                    -341,
                    -367,
                    -404,
                    -443,
                    -458,
                    -455,
                    -457,
                    -480,
                    -486,
                    -492,
                    -572,
                    -516,
                    -74,
                    206,
                    -126,
                    -514,
                    -678,
                    -699,
                    -572,
                    -506,
                    -503,
                    -495,
                    -491,
                    -494,
                    -480,
                    -470,
                    -468,
                    -463,
                    -468,
                    -462,
                    -455,
                    -452,
                    -454,
                    -448,
                    -440,
                    -430,
                    -413,
                    -406,
                    -406,
                    -388,
                    -406,
                    -441,
                    -451,
                    -475,
                    -497,
                    -502,
                    -502,
                    -516,
                    -521,
                    -534,
                    -532,
                    -534,
                    -548,
                    -534,
                    -517,
                    -512,
                    -505,
                    -491,
                    -498,
                    -506,
                    -512,
                    -508,
                    -513,
                    -519,
                    -514,
                    -508,
                    -514,
                    -520,
                    -514,
                    -512,
                    -516,
                    -514,
                    -501,
                    -470,
                    -439,
                    -426,
                    -441,
                    -458,
                    -480,
                    -521,
                    -564,
                    -579,
                    -583,
                    -579,
                    -578,
                    -601,
                    -673,
                    -645,
                    -251,
                    52,
                    -222,
                    -587,
                    -694,
                    -648,
                    -600,
                    -570,
                    -567,
                    -574,
                    -565,
                    -574,
                    -575,
                    -568,
                    -585,
                    -581,
                    -559,
                    -546,
                    -539,
                    -541,
                    -527,
                    -510,
                    -505,
                    -505,
                    -499,
                    -484,
                    -481,
                    -492,
                    -498,
                    -514,
                    -534,
                    -545,
                    -568,
                    -596,
                    -605,
                    -614,
                    -612,
                    -623,
                    -627,
                    -619,
                    -614,
                    -612,
                    -619,
                    -626,
                    -619,
                    -612,
                    -610,
                    -605,
                    -594,
                    -603,
                    -607,
                    -605,
                    -605,
                    -614,
                    -625,
                    -638,
                    -651,
                    -654,
                    -663,
                    -663,
                    -654,
                    -616,
                    -564,
                    -553,
                    -560,
                    -563,
                    -583,
                    -636,
                    -684,
                    -683,
                    -665,
                    -659,
                    -652,
                    -641,
                    -685,
                    -782,
                    -611,
                    -120,
                    -34,
                    -458,
                    -765,
                    -786,
                    -728,
                    -689,
                    -681,
                    -685,
                    -689,
                    -681,
                    -665,
                    -648,
                    -630,
                    -636,
                    -706,
                    -801,
                    -800,
                    -692,
                    -621,
                    -608,
                    -604,
                    -600,
                    -605,
                    -600,
                    -579,
                    -568,
                    -567,
                    -565,
                    -585,
                    -611,
                    -618,
                    -627,
                    -645,
                    -661,
                    -670,
                    -669,
                    -672,
                    -674,
                    -676,
                    -677,
                    -667,
                    -655,
                    -648,
                    -645,
                    -647,
                    -648,
                    -645,
                    -640,
                    -637,
                    -638,
                    -647,
                    -654,
                    -661,
                    -659,
                    -658,
                    -655,
                    -636,
                    -623,
                    -608,
                    -582,
                    -553,
                    -537,
                    -541,
                    -574,
                    -612,
                    -637,
                    -654,
                    -663,
                    -674,
                    -677,
                    -666,
                    -703,
                    -783,
                    -636,
                    -138,
                    19,
                    -415,
                    -765,
                    -802,
                    -732,
                    -684,
                    -688,
                    -703,
                    -684,
                    -658,
                    -648,
                    -644,
                    -640,
                    -636,
                    -643,
                    -636,
                    -619,
                    -608,
                    -614,
                    -616,
                    -612,
                    -603,
                    -590,
                    -574,
                    -560,
                    -565,
                    -571,
                    -576,
                    -585,
                    -600,
                    -616,
                    -641,
                    -655,
                    -652,
                    -651,
                    -663,
                    -662,
                    -663,
                    -666,
                    -663,
                    -645,
                    -644,
                    -658,
                    -651,
                    -645,
                    -640,
                    -629,
                    -640,
                    -656,
                    -663,
                    -663,
                    -655,
                    -669,
                    -689,
                    -665,
                    -648,
                    -625,
                    -601,
                    -583,
                    -564,
                    -575,
                    -615,
                    -661,
                    -703,
                    -718,
                    -725,
                    -740,
                    -738,
                    -740,
                    -771,
                    -841,
                    -723,
                    -171,
                    133,
                    -327,
                    -764,
                    -829,
                    -764,
                    -713,
                    -694,
                    -696,
                    -707,
                    -703,
                    -685,
                    -663,
                    -658,
                    -655,
                    -651,
                    -651,
                    -661,
                    -654,
                    -647,
                    -636,
                    -643,
                    -652,
                    -625,
                    -600,
                    -596,
                    -597,
                    -593,
                    -601,
                    -630,
                    -658,
                    -662,
                    -666,
                    -698,
                    -718,
                    -720,
                    -723,
                    -727,
                    -731,
                    -729,
                    -721,
                    -720,
                    -718,
                    -707,
                    -705,
                    -710,
                    -716,
                    -709,
                    -696,
                    -698,
                    -705,
                    -698,
                    -696,
                    -692,
                    -696,
                    -700,
                    -687,
                    -665,
                    -645,
                    -627,
                    -612,
                    -614,
                    -636,
                    -689,
                    -743,
                    -771,
                    -785,
                    -796,
                    -804,
                    -798,
                    -798,
                    -878,
                    -838,
                    -407,
                    -147,
                    -481,
                    -858,
                    -955,
                    -900,
                    -855,
                    -845,
                    -849,
                    -849,
                    -849,
                    -842,
                    -845,
                    -842,
                    -831,
                    -808,
                    -807,
                    -805,
                    -797,
                    -775,
                    -775,
                    -787,
                    -791,
                    -791,
                    -779,
                    -774,
                    -768,
                    -769,
                    -775,
                    -783,
                    -807,
                    -830,
                    -853,
                    -860,
                    -869,
                    -903,
                    -906,
                    -902,
                    -918,
                    -935,
                    -953,
                    -965,
                    -957,
                    -950,
                    -954,
                    -960,
                    -961,
                    -964,
                    -958,
                    -961,
                    -980,
                    -986,
                    -976,
                    -972,
                    -966,
                    -957,
                    -939,
                    -931,
                    -915,
                    -896,
                    -877,
                    -859,
                    -858,
                    -898,
                    -950,
                    -979,
                    -987,
                    -997,
                    -1001,
                    -1001,
                    -1006,
                    -1037,
                    -1103,
                    -925,
                    -495,
                    -488,
                    -862,
                    -1093,
                    -1099,
                    -1033,
                    -1004,
                    -1006,
                    -1005,
                    -1001,
                    -1009,
                    -1011,
                    -1011,
                    -1012,
                    -1005,
                    -1002,
                    -987,
                    -960,
                    -954,
                    -957,
                    -949,
                    -944,
                    -943,
                    -939,
                    -928,
                    -909,
                    -906,
                    -920,
                    -935,
                    -947,
                    -968,
                    -986,
                    -1012,
                    -1039,
                    -1046,
                    -1041,
                    -1034,
                    -1045,
                    -1056,
                    -1050,
                    -1045,
                    -1041,
                    -1031,
                    -1028,
                    -1024,
                    -1009,
                    -1001,
                    -1015,
                    -1028,
                    -1027,
                    -1013,
                    -1004,
                    -1002,
                    -1015,
                    -1005,
                    -988,
                    -982,
                    -962,
                    -946,
                    -920,
                    -904,
                    -922,
                    -957,
                    -990,
                    -1015,
                    -1033,
                    -1049,
                    -1066
                ]
            },
            "classification": "PAC",
            "sampleRate": 125,
            "triggerTime": "2022-03-10T04:40:10.000Z"
        },
        {
            "ecgValues": [
                -1338,
                -1362,
                -1384,
                -1413,
                -1429,
                -1440,
                -1447,
                -1461,
                -1478,
                -1485,
                -1486,
                -1490,
                -1502,
                -1500,
                -1501,
                -1511,
                -1518,
                -1522,
                -1530,
                -1534,
                -1542,
                -1555,
                -1562,
                -1569,
                -1570,
                -1553,
                -1544,
                -1522,
                -1496,
                -1487,
                -1513,
                -1563,
                -1593,
                -1611,
                -1625,
                -1633,
                -1639,
                -1644,
                -1655,
                -1723,
                -1720,
                -1359,
                -1024,
                -1274,
                -1660,
                -1786,
                -1748,
                -1711,
                -1702,
                -1695,
                -1693,
                -1693,
                -1690,
                -1684,
                -1672,
                -1661,
                -1651,
                -1647,
                -1639,
                -1632,
                -1629,
                -1624,
                -1611,
                -1610,
                -1606,
                -1592,
                -1580,
                -1569,
                -1559,
                -1556,
                -1567,
                -1586,
                -1611,
                -1635,
                -1643,
                -1646,
                -1664,
                -1673,
                -1679,
                -1672,
                -1661,
                -1647,
                -1637,
                -1631,
                -1614,
                -1599,
                -1591,
                -1581,
                -1571,
                -1553,
                -1537,
                -1530,
                -1537,
                -1522,
                -1501,
                -1483,
                -1465,
                -1447,
                -1417,
                -1394,
                -1373,
                -1369,
                -1389,
                -1427,
                -1461,
                -1485,
                -1496,
                -1504,
                -1497,
                -1494,
                -1520,
                -1595,
                -1516,
                -1099,
                -896,
                -1217,
                -1547,
                -1622,
                -1578,
                -1548,
                -1541,
                -1541,
                -1536,
                -1533,
                -1533,
                -1527,
                -1518,
                -1509,
                -1500,
                -1486,
                -1483,
                -1485,
                -1472,
                -1458,
                -1447,
                -1436,
                -1427,
                -1427,
                -1421,
                -1399,
                -1388,
                -1402,
                -1428,
                -1461,
                -1487,
                -1509,
                -1530,
                -1551,
                -1569,
                -1573,
                -1581,
                -1589,
                -1591,
                -1603,
                -1614,
                -1620,
                -1628,
                -1640,
                -1653,
                -1668,
                -1679,
                -1698,
                -1727,
                -1730,
                -1733,
                -1755,
                -1768,
                -1781,
                -1778,
                -1777,
                -1773,
                -1755,
                -1752,
                -1770,
                -1804,
                -1847,
                -1892,
                -1913,
                -1927,
                -1943,
                -1954,
                -1963,
                -2016,
                -2089,
                -1880,
                -1440,
                -1501,
                -1919,
                -2123,
                -2118,
                -2056,
                -2037,
                -2048,
                -2037,
                -2027,
                -2032,
                -2044,
                -2049,
                -2045,
                -2043,
                -2048,
                -2054,
                -2055,
                -2055,
                -2062,
                -2058,
                -2052,
                -2048,
                -2043,
                -2048,
                -2051,
                -2041,
                -2033,
                -2038,
                -2054,
                -2078,
                -2107,
                -2129,
                -2151,
                -2172,
                -2186,
                -2194,
                -2198,
                -2200,
                -2202,
                -2211,
                -2205,
                -2194,
                -2200,
                -2201,
                -2202,
                -2208,
                -2212,
                -2213,
                -2212,
                -2213,
                -2223,
                -2220,
                -2212,
                -2200,
                -2176,
                -2146,
                -2120,
                -2111,
                -2120,
                -2151,
                -2197,
                -2229,
                -2237,
                -2236,
                -2237,
                -2237,
                -2240,
                -2293,
                -2339,
                -2058,
                -1632,
                -1738,
                -2120,
                -2306,
                -2286,
                -2220,
                -2186,
                -2176,
                -2168,
                -2161,
                -2157,
                -2135,
                -2114,
                -2100,
                -2095,
                -2088,
                -2073,
                -2059,
                -2052,
                -2047,
                -2037,
                -2018,
                -1998,
                -1985,
                -1981,
                -1975,
                -1956,
                -1952,
                -1959,
                -1970,
                -1985,
                -1994,
                -1998,
                -1998,
                -1994,
                -1990,
                -1978,
                -1967,
                -1956,
                -1942,
                -1924,
                -1909,
                -1901,
                -1894,
                -1883,
                -1887,
                -1899,
                -1909,
                -1913,
                -1913,
                -1920,
                -1917,
                -1894,
                -1876,
                -1862,
                -1843,
                -1826,
                -1815,
                -1815,
                -1841,
                -1880,
                -1914,
                -1934,
                -1941,
                -1948,
                -1952,
                -1956,
                -1976,
                -2065,
                -2040,
                -1599,
                -1221,
                -1511,
                -1941,
                -2061,
                -1998,
                -1938,
                -1916,
                -1906,
                -1895,
                -1880,
                -1862,
                -1844,
                -1818,
                -1790,
                -1764,
                -1744,
                -1727,
                -1708,
                -1691,
                -1680,
                -1662,
                -1643,
                -1628,
                -1617,
                -1604,
                -1578,
                -1552,
                -1538,
                -1534,
                -1548,
                -1564,
                -1577,
                -1580,
                -1580,
                -1571,
                -1558,
                -1540,
                -1520,
                -1502,
                -1483,
                -1457,
                -1440,
                -1427,
                -1402,
                -1372,
                -1348,
                -1337,
                -1332,
                -1323,
                -1308,
                -1289,
                -1268,
                -1245,
                -1223,
                -1194,
                -1157,
                -1128,
                -1100,
                -1093,
                -1111,
                -1136,
                -1159,
                -1174,
                -1173,
                -1166,
                -1162,
                -1159,
                -1219,
                -1297,
                -1050,
                -520,
                -499,
                -960,
                -1220,
                -1220,
                -1144,
                -1100,
                -1084,
                -1071,
                -1049,
                -1028,
                -1009,
                -977,
                -949,
                -922,
                -898,
                -871,
                -845,
                -820,
                -796,
                -772,
                -742,
                -714,
                -689,
                -666,
                -643,
                -616,
                -593,
                -578,
                -564,
                -576,
                -583,
                -583,
                -578,
                -565,
                -554,
                -542,
                -531,
                -514,
                -497,
                -477,
                -462,
                -459,
                -447,
                -428,
                -406,
                -392,
                -388,
                -379,
                -366,
                -350,
                -344,
                -334,
                -313,
                -284,
                -251,
                -218,
                -181,
                -152,
                -152,
                -174,
                -192,
                -200,
                -200,
                -189,
                -185,
                -192,
                -250,
                -288,
                -268,
                49,
                505,
                410,
                9,
                -171,
                -136,
                -64,
                -28,
                -16,
                -2,
                17,
                33,
                46,
                64,
                75,
                79,
                89,
                107,
                126,
                139,
                158,
                180,
                197,
                219,
                239,
                260,
                281,
                297,
                310,
                312,
                301,
                290,
                278,
                271,
                253,
                170,
                74,
                -9,
                -123,
                -159,
                -156,
                -123,
                -18,
                53,
                74,
                86,
                100,
                111,
                115,
                114,
                114,
                119,
                122,
                129,
                141,
                150,
                158,
                186,
                224,
                252,
                264,
                254,
                224,
                195,
                181,
                174,
                177,
                188,
                199,
                197,
                121,
                146,
                564,
                909,
                701,
                429,
                356,
                424,
                489,
                505,
                511,
                516,
                520,
                534,
                544,
                537,
                560,
                573,
                559,
                578,
                603,
                621,
                621,
                624,
                635,
                654,
                665,
                679,
                693,
                713,
                723,
                727,
                704,
                694,
                702,
                693,
                690,
                693,
                688,
                688,
                688,
                699,
                709,
                716,
                726,
                734,
                735,
                739,
                745,
                752,
                750,
                739,
                734,
                727,
                728,
                739,
                738,
                748,
                768,
                792,
                817,
                844,
                859,
                846,
                821,
                789,
                759,
                745,
                739,
                741,
                739,
                733,
                673,
                618,
                888,
                1296,
                1180,
                808,
                626,
                649,
                715,
                741,
                753,
                770,
                777,
                779,
                785,
                790,
                792,
                796,
                804,
                811,
                817,
                825,
                826,
                824,
                826,
                825,
                829,
                836,
                847,
                858,
                858,
                840,
                814,
                793,
                773,
                755,
                738,
                716,
                706,
                694,
                694,
                695,
                705,
                716,
                723,
                724,
                713,
                704,
                694,
                680,
                664,
                657,
                644,
                631,
                626,
                618,
                610,
                618,
                631,
                643,
                671,
                698,
                706,
                693,
                658,
                625,
                603,
                595,
                596,
                604,
                602,
                592,
                527,
                507,
                872,
                1315,
                1143,
                737,
                588,
                647,
                730,
                766,
                788,
                812,
                824,
                841,
                861,
                879,
                898,
                919,
                930,
                932,
                942,
                960,
                975,
                992,
                1004,
                1015,
                1019,
                1026,
                1034,
                1047,
                1055,
                1043,
                1015,
                994,
                981,
                970,
                961,
                949,
                945,
                948,
                953,
                960,
                968,
                972,
                976,
                978,
                990,
                1001,
                1001,
                1005,
                1016,
                1021,
                1023,
                1023,
                1021,
                1026,
                1037,
                1048,
                1065,
                1087,
                1103,
                1089,
                1096,
                1085,
                1052,
                1025,
                1007,
                996,
                993,
                990,
                987,
                950,
                873,
                1027,
                1482,
                1568,
                1176,
                888,
                846,
                916,
                967,
                987,
                992,
                993,
                996,
                1003,
                1016,
                1029,
                1041,
                1049,
                1061,
                1073,
                1077,
                1084,
                1095,
                1114,
                1120,
                1121,
                1135,
                1150,
                1151,
                1154,
                1151,
                1131,
                1109,
                1083,
                1066,
                1059,
                1051,
                1047,
                1036,
                1023,
                1022,
                1034,
                1044,
                1048,
                1044,
                1040,
                1052,
                1058,
                1061,
                1059,
                1048,
                1045,
                1051,
                1058,
                1062,
                1063,
                1074,
                1087,
                1102,
                1121,
                1139,
                1156,
                1145,
                1106,
                1074,
                1055,
                1041,
                1027,
                1021,
                1029,
                1029,
                965,
                902,
                1161,
                1616,
                1552,
                1143,
                927,
                927,
                965,
                979,
                983,
                981,
                976,
                983,
                993,
                1001,
                996,
                996,
                1016,
                1027,
                1025,
                1021,
                1030,
                1038,
                1036,
                1037,
                1051,
                1061,
                1063,
                1066,
                1073,
                1065,
                1043,
                1018,
                1007,
                998,
                985,
                974,
                975,
                976,
                970,
                968,
                971,
                972,
                971,
                970,
                974,
                982,
                986,
                989,
                989,
                986,
                976,
                970,
                971,
                974,
                975,
                979,
                989,
                998,
                1014,
                1044,
                1066,
                1069,
                1052,
                1025,
                997,
                971,
                956,
                956,
                959,
                943,
                916,
                847,
                888,
                1296,
                1591,
                1286,
                910,
                796,
                848,
                902,
                916,
                930,
                943,
                950,
                957,
                964,
                959,
                964,
                975,
                979,
                978,
                972,
                967,
                964,
                971,
                978,
                985,
                987,
                987,
                982,
                978,
                990,
                989,
                963,
                936,
                919,
                902,
                886,
                877,
                869,
                873,
                877,
                891,
                905,
                920,
                935,
                949,
                960,
                976,
                979,
                982,
                987,
                994,
                1003,
                1004,
                996,
                1001,
                1007,
                1015,
                1027,
                1044,
                1065,
                1078,
                1056,
                1016,
                979,
                961,
                957,
                954,
                953,
                943,
                908,
                821,
                948,
                1446,
                1635,
                1231,
                874,
                792,
                854,
                921,
                938,
                942,
                949,
                957,
                963,
                964,
                967,
                976,
                979,
                978,
                976,
                983,
                996,
                1010,
                1023,
                1032,
                1038,
                1043,
                1049,
                1052,
                1052,
                1033,
                993,
                957,
                931,
                899,
                886,
                880,
                872,
                869,
                863,
                859,
                863,
                866,
                865,
                872,
                891,
                902,
                898,
                898,
                887,
                886,
                906,
                881,
                847,
                876,
                899,
                932,
                957,
                961,
                978,
                990,
                959,
                919,
                887,
                863,
                858,
                847,
                841,
                846,
                811,
                737,
                905,
                1442,
                1632,
                1200,
                833,
                767,
                815,
                859,
                879,
                876,
                874,
                881,
                874,
                881,
                884,
                886,
                892,
                909,
                916,
                905,
                898,
                928,
                959,
                974,
                985,
                986,
                994,
                996,
                987,
                975,
                943,
                917,
                895,
                870,
                848,
                835,
                828,
                822,
                822,
                819,
                818,
                814,
                819,
                824,
                825,
                829,
                833,
                835,
                840,
                836,
                830,
                832,
                833,
                833,
                847,
                858,
                876,
                898,
                898,
                883,
                844,
                806,
                768,
                683,
                569,
                556,
                650,
                694,
                628,
                570,
                910,
                1441,
                1318,
                800,
                562,
                574,
                644,
                671,
                664,
                666,
                666,
                682,
                702,
                711,
                698,
                701,
                723,
                727,
                719,
                727,
                739,
                756,
                760,
                768,
                775,
                785,
                793,
                797,
                788,
                773,
                756,
                730,
                701,
                682,
                668,
                649,
                631,
                626,
                624,
                624,
                615,
                615,
                620,
                613,
                600,
                603,
                607,
                609,
                599,
                569,
                584,
                596,
                599,
                607,
                615,
                637,
                665,
                684,
                683,
                666,
                635,
                595,
                553,
                540,
                529,
                518,
                520,
                509,
                464,
                381,
                558,
                1077,
                1169,
                726,
                420,
                399,
                461,
                493,
                496,
                500,
                498,
                472,
                482,
                496,
                491,
                507,
                537,
                571,
                586,
                593,
                599,
                611,
                610,
                607,
                614,
                628,
                631,
                635,
                635,
                624,
                604,
                581,
                555,
                551,
                540,
                522,
                515,
                518,
                519,
                526,
                524,
                520,
                523,
                530,
                545,
                559,
                564,
                559,
                566,
                573,
                577,
                585,
                584,
                585,
                593,
                607,
                624,
                649,
                676,
                684,
                680,
                660,
                626,
                595,
                574,
                569,
                569,
                564,
                560,
                547,
                467
            ],
            "bpm": 88,
            "nextStrip": {
                "ecgValues": [
                    530,
                    960,
                    1198,
                    868,
                    537,
                    458,
                    509,
                    560,
                    577,
                    573,
                    580,
                    595,
                    604,
                    610,
                    625,
                    635,
                    639,
                    637,
                    643,
                    650,
                    657,
                    665,
                    669,
                    679,
                    686,
                    690,
                    705,
                    716,
                    724,
                    720,
                    706,
                    684,
                    665,
                    647,
                    626,
                    613,
                    600,
                    584,
                    582,
                    588,
                    591,
                    589,
                    595,
                    602,
                    606,
                    603,
                    596,
                    575,
                    547,
                    564,
                    585,
                    599,
                    604,
                    606,
                    603,
                    602,
                    610,
                    633,
                    653,
                    673,
                    705,
                    731,
                    742,
                    728,
                    704,
                    679,
                    651,
                    637,
                    629,
                    625,
                    631,
                    632,
                    598,
                    519,
                    686,
                    1135,
                    1189,
                    815,
                    563,
                    536,
                    598,
                    643,
                    654,
                    654,
                    658,
                    665,
                    677,
                    694,
                    713,
                    731,
                    748,
                    742,
                    742,
                    753,
                    767,
                    777,
                    785,
                    808,
                    815,
                    814,
                    819,
                    828,
                    840,
                    833,
                    814,
                    790,
                    767,
                    744,
                    715,
                    704,
                    699,
                    694,
                    691,
                    698,
                    709,
                    706,
                    699,
                    701,
                    719,
                    730,
                    730,
                    722,
                    720,
                    720,
                    722,
                    719,
                    708,
                    705,
                    709,
                    713,
                    715,
                    720,
                    722,
                    726,
                    741,
                    771,
                    799,
                    811,
                    797,
                    768,
                    738,
                    715,
                    698,
                    690,
                    691,
                    687,
                    690,
                    632,
                    556,
                    801,
                    1264,
                    1224,
                    811,
                    569,
                    559,
                    621,
                    651,
                    655,
                    658,
                    666,
                    666,
                    662,
                    675,
                    687,
                    693,
                    697,
                    699,
                    711,
                    717,
                    730,
                    741,
                    744,
                    742,
                    752,
                    771,
                    782,
                    785,
                    782,
                    778,
                    756,
                    731,
                    715,
                    704,
                    697,
                    677,
                    675,
                    677,
                    683,
                    684,
                    676,
                    669,
                    671,
                    673,
                    676,
                    687,
                    693,
                    698,
                    694,
                    690,
                    687,
                    686,
                    683,
                    687,
                    691,
                    694,
                    688,
                    697,
                    709,
                    716,
                    733,
                    775,
                    803,
                    803,
                    789,
                    744,
                    715,
                    695,
                    683,
                    680,
                    679,
                    676,
                    675,
                    610,
                    566,
                    910,
                    1388,
                    1231,
                    770,
                    581,
                    611,
                    666,
                    688,
                    698,
                    702,
                    702,
                    701,
                    712,
                    716,
                    724,
                    733,
                    741,
                    759,
                    771,
                    775,
                    788,
                    797,
                    807,
                    815,
                    825,
                    832,
                    840,
                    844,
                    846,
                    830,
                    800,
                    775,
                    763,
                    753,
                    744,
                    727,
                    723,
                    727,
                    726,
                    728,
                    733,
                    737,
                    739,
                    750,
                    752,
                    756,
                    764,
                    768,
                    766,
                    767,
                    764,
                    767,
                    768,
                    761,
                    757,
                    759,
                    766,
                    779,
                    786,
                    796,
                    826,
                    847,
                    854,
                    847,
                    814,
                    773,
                    744,
                    728,
                    722,
                    727,
                    722,
                    709,
                    661,
                    584,
                    786,
                    1241,
                    1247,
                    832,
                    599,
                    582,
                    644,
                    691,
                    695,
                    683,
                    693,
                    698,
                    701,
                    712,
                    722,
                    724,
                    735,
                    730,
                    728,
                    739,
                    749,
                    763,
                    778,
                    790,
                    793,
                    801,
                    814,
                    824,
                    797,
                    778,
                    792,
                    773,
                    750,
                    733,
                    724,
                    719,
                    701,
                    699,
                    697,
                    698,
                    699,
                    708,
                    720,
                    722,
                    734,
                    741,
                    738,
                    746,
                    755,
                    755,
                    750,
                    748,
                    756,
                    759,
                    757,
                    753,
                    750,
                    752,
                    764,
                    763,
                    766,
                    793,
                    822,
                    844,
                    844,
                    815,
                    774,
                    746,
                    730,
                    716,
                    705,
                    694,
                    701,
                    693,
                    602,
                    662,
                    1083,
                    1317,
                    989,
                    636,
                    534,
                    582,
                    636,
                    660,
                    660,
                    655,
                    661,
                    671,
                    683,
                    687,
                    693,
                    704,
                    717,
                    731,
                    744,
                    755,
                    764,
                    778,
                    784,
                    789,
                    790,
                    788,
                    786,
                    784,
                    778,
                    760,
                    735,
                    713,
                    690,
                    684,
                    677,
                    675,
                    675,
                    679,
                    683,
                    693,
                    698,
                    705,
                    708,
                    715,
                    723,
                    726,
                    723,
                    717,
                    726,
                    730,
                    727,
                    728,
                    727,
                    724,
                    723,
                    720,
                    722,
                    726,
                    731,
                    738,
                    756,
                    788,
                    824,
                    841,
                    839,
                    806,
                    767,
                    741,
                    745,
                    749,
                    744,
                    748,
                    745,
                    693,
                    615,
                    825,
                    1270,
                    1256,
                    819,
                    469,
                    421,
                    570,
                    687,
                    713,
                    716,
                    719,
                    728,
                    733,
                    737,
                    748,
                    749,
                    756,
                    760,
                    763,
                    767,
                    773,
                    788,
                    801,
                    819,
                    830,
                    847,
                    868,
                    880,
                    887,
                    881,
                    865,
                    843,
                    824,
                    815,
                    804,
                    795,
                    789,
                    792,
                    795,
                    797,
                    792,
                    796,
                    799,
                    799,
                    801,
                    806,
                    807,
                    801,
                    795,
                    790,
                    785,
                    778,
                    770,
                    771,
                    779,
                    767,
                    756,
                    766,
                    782,
                    788,
                    803,
                    829,
                    854,
                    865,
                    852,
                    810,
                    775,
                    759,
                    757,
                    763,
                    759,
                    763,
                    744,
                    657,
                    694,
                    1113,
                    1430,
                    1139,
                    746,
                    628,
                    673,
                    723,
                    739,
                    750,
                    755,
                    756,
                    756,
                    760,
                    773,
                    781,
                    788,
                    796,
                    803,
                    804,
                    808,
                    812,
                    815,
                    818,
                    829,
                    835,
                    839,
                    859,
                    880,
                    873,
                    855,
                    833,
                    808,
                    788,
                    770,
                    755,
                    750,
                    744,
                    735,
                    733,
                    730,
                    723,
                    727,
                    738,
                    739,
                    741,
                    748,
                    745,
                    745,
                    748,
                    742,
                    744,
                    742,
                    734,
                    731,
                    731,
                    728,
                    735,
                    750,
                    773,
                    799,
                    828,
                    847,
                    844,
                    815,
                    788,
                    766,
                    741,
                    726,
                    723,
                    719,
                    709,
                    693,
                    603,
                    661,
                    1118,
                    1411,
                    1074,
                    677,
                    556,
                    534,
                    580,
                    655,
                    671,
                    684,
                    687,
                    688,
                    688,
                    684,
                    688,
                    691,
                    688,
                    687,
                    691,
                    695,
                    698,
                    705,
                    720,
                    734,
                    742,
                    756,
                    763,
                    761,
                    750,
                    733,
                    711,
                    690,
                    671,
                    651,
                    643,
                    631,
                    610,
                    599,
                    599,
                    610,
                    611,
                    609,
                    614,
                    618,
                    624,
                    622,
                    618,
                    621,
                    618,
                    618,
                    607,
                    599,
                    607,
                    611,
                    609,
                    617,
                    620,
                    618,
                    632,
                    653,
                    677,
                    698,
                    697,
                    675,
                    642,
                    609,
                    575,
                    567,
                    578,
                    553,
                    545,
                    549,
                    476,
                    462,
                    822,
                    1220,
                    1019,
                    599,
                    424,
                    440,
                    500,
                    531,
                    549,
                    560,
                    559,
                    552,
                    552,
                    553,
                    545,
                    551,
                    563,
                    569,
                    564,
                    575,
                    592,
                    624,
                    615,
                    588,
                    602,
                    625,
                    639,
                    657,
                    661,
                    646,
                    620,
                    584,
                    552,
                    544,
                    534,
                    541,
                    519,
                    493,
                    490,
                    493,
                    493,
                    490,
                    497,
                    512,
                    516,
                    496,
                    487,
                    497,
                    507,
                    507,
                    505,
                    511,
                    520,
                    520,
                    513,
                    507,
                    501,
                    494,
                    507,
                    542,
                    586,
                    611,
                    604,
                    581,
                    552,
                    526,
                    497,
                    474,
                    476,
                    478,
                    476,
                    458,
                    373,
                    458,
                    928,
                    1178,
                    821,
                    465,
                    399,
                    446,
                    474,
                    490,
                    498,
                    490,
                    497,
                    527,
                    522,
                    507,
                    519,
                    527,
                    520,
                    531,
                    551,
                    553,
                    552,
                    574,
                    609,
                    626,
                    636,
                    625,
                    628,
                    649,
                    655,
                    639,
                    617,
                    606,
                    589,
                    574,
                    559,
                    548,
                    544,
                    545,
                    548,
                    553,
                    563,
                    564,
                    571,
                    575,
                    578,
                    584,
                    596,
                    610,
                    622,
                    615,
                    607,
                    609,
                    618,
                    625,
                    629,
                    636,
                    649,
                    664,
                    684,
                    704,
                    709,
                    702,
                    662,
                    611,
                    588,
                    577,
                    569,
                    570,
                    566,
                    564,
                    529,
                    449,
                    654,
                    1197,
                    1277,
                    799,
                    476,
                    454,
                    509,
                    540,
                    544,
                    547,
                    552,
                    548,
                    537,
                    541,
                    562,
                    567,
                    569,
                    574,
                    577,
                    586,
                    598,
                    610,
                    624,
                    633,
                    644,
                    649,
                    658,
                    658,
                    662,
                    658,
                    635,
                    609,
                    584,
                    556,
                    541,
                    537,
                    526,
                    518,
                    511,
                    504,
                    508,
                    520,
                    513,
                    491,
                    482,
                    486,
                    493,
                    494,
                    494,
                    493,
                    486,
                    480,
                    479,
                    471,
                    467,
                    472,
                    475,
                    485,
                    498,
                    519,
                    529,
                    507,
                    465,
                    420,
                    356,
                    341,
                    343,
                    332,
                    326,
                    318,
                    270,
                    179,
                    361,
                    883,
                    970,
                    507,
                    190,
                    157,
                    203,
                    219,
                    208,
                    197,
                    188,
                    179,
                    172,
                    168,
                    161,
                    158,
                    150,
                    136,
                    132,
                    133,
                    126,
                    125,
                    133,
                    140,
                    151,
                    157,
                    152,
                    157,
                    148,
                    136,
                    107,
                    75,
                    55,
                    33,
                    12,
                    -9,
                    -31,
                    -39,
                    -42,
                    -47,
                    -51,
                    -54,
                    -64,
                    -72,
                    -74,
                    -78,
                    -87,
                    -105,
                    -113,
                    -116,
                    -122,
                    -129,
                    -133,
                    -152,
                    -174,
                    -181,
                    -181,
                    -175,
                    -166,
                    -153,
                    -145,
                    -148,
                    -180,
                    -226,
                    -268,
                    -295,
                    -319,
                    -342,
                    -367,
                    -375,
                    -390,
                    -470,
                    -479,
                    -96,
                    268,
                    -10,
                    -428,
                    -567,
                    -541,
                    -497,
                    -481,
                    -488,
                    -495,
                    -502,
                    -510,
                    -512,
                    -508,
                    -512,
                    -523,
                    -524,
                    -520,
                    -523,
                    -531,
                    -528,
                    -530,
                    -539,
                    -546,
                    -553,
                    -548,
                    -542,
                    -542,
                    -559,
                    -592,
                    -618,
                    -640,
                    -673,
                    -698,
                    -712,
                    -725,
                    -747,
                    -757,
                    -761,
                    -764,
                    -764,
                    -762,
                    -768,
                    -771,
                    -767,
                    -762,
                    -762,
                    -767,
                    -769,
                    -769,
                    -768,
                    -765,
                    -753,
                    -747,
                    -743,
                    -739,
                    -742,
                    -728,
                    -698,
                    -674,
                    -661,
                    -676,
                    -710,
                    -750,
                    -791,
                    -813,
                    -826,
                    -837,
                    -841,
                    -842,
                    -885,
                    -971,
                    -797,
                    -300,
                    -226,
                    -661,
                    -951,
                    -971,
                    -911,
                    -882,
                    -888,
                    -884,
                    -873,
                    -863,
                    -855,
                    -845,
                    -838,
                    -827,
                    -819,
                    -842,
                    -940,
                    -1011,
                    -1110,
                    -1214,
                    -1230,
                    -1203,
                    -1104,
                    -1012,
                    -991,
                    -997,
                    -987,
                    -979,
                    -990,
                    -1015,
                    -1026,
                    -1031,
                    -1038,
                    -1055,
                    -1062,
                    -1062,
                    -1062,
                    -1056,
                    -1042,
                    -1030,
                    -1022,
                    -1012,
                    -1002,
                    -988,
                    -999,
                    -1008,
                    -999,
                    -990,
                    -990,
                    -994,
                    -991,
                    -987,
                    -983,
                    -975,
                    -964,
                    -944,
                    -913,
                    -866,
                    -864,
                    -863,
                    -875,
                    -745,
                    -743,
                    -728,
                    -724,
                    -710,
                    -707,
                    -699,
                    -721,
                    -684,
                    -683,
                    -151,
                    88,
                    -723,
                    -721,
                    -764,
                    -698,
                    -655,
                    -640,
                    -629,
                    -618,
                    -615,
                    -610,
                    -601,
                    -587,
                    -571,
                    -559,
                    -549,
                    -538,
                    -523,
                    -509,
                    -499,
                    -480,
                    -454,
                    -433,
                    -432,
                    -421,
                    -406,
                    -403,
                    -401,
                    -414,
                    -425,
                    -455,
                    -454,
                    -468,
                    -472,
                    -487,
                    -486,
                    -469,
                    -458,
                    -448,
                    -437,
                    -426,
                    -418,
                    -408,
                    -381,
                    -381,
                    -375,
                    -374,
                    -364,
                    -363,
                    -366,
                    -395,
                    -346,
                    -345,
                    -330,
                    -311,
                    -264,
                    -262,
                    -235,
                    -218,
                    -255,
                    -254,
                    -287,
                    -315,
                    -334,
                    -328,
                    -323,
                    -322,
                    -422,
                    -426,
                    9,
                    9,
                    192,
                    -288,
                    -429,
                    -428,
                    -377,
                    -306,
                    -305,
                    -304,
                    -298,
                    -280,
                    -280,
                    -279,
                    -271,
                    -243,
                    -236,
                    -224,
                    -217,
                    -207,
                    -200,
                    -195,
                    -188,
                    -182,
                    -180,
                    -171,
                    -163,
                    -147,
                    -138,
                    -141,
                    -156,
                    -173,
                    -189,
                    -198,
                    -206,
                    -207,
                    -198,
                    -193,
                    -195,
                    -192,
                    -175,
                    -167,
                    -158,
                    -152,
                    -147,
                    -147,
                    -144,
                    -130,
                    -122,
                    -120,
                    -113,
                    -105,
                    -98,
                    -87,
                    -74,
                    -63,
                    -40,
                    -12,
                    13,
                    30,
                    26,
                    1,
                    -34,
                    -57,
                    -68,
                    -76,
                    -76,
                    -72,
                    -68,
                    -125,
                    -187,
                    126
                ]
            },
            "previousStrip": {
                "ecgValues": [
                    680,
                    602,
                    85,
                    -149,
                    -119,
                    -50,
                    -27,
                    -20,
                    -9,
                    0,
                    1,
                    8,
                    19,
                    27,
                    41,
                    57,
                    67,
                    73,
                    77,
                    86,
                    96,
                    107,
                    117,
                    124,
                    130,
                    137,
                    148,
                    159,
                    162,
                    148,
                    126,
                    107,
                    99,
                    95,
                    85,
                    74,
                    75,
                    88,
                    92,
                    95,
                    103,
                    111,
                    117,
                    117,
                    122,
                    126,
                    121,
                    85,
                    101,
                    125,
                    126,
                    133,
                    136,
                    135,
                    143,
                    157,
                    169,
                    195,
                    231,
                    250,
                    242,
                    212,
                    166,
                    135,
                    124,
                    119,
                    117,
                    110,
                    96,
                    45,
                    -18,
                    232,
                    733,
                    691,
                    235,
                    4,
                    24,
                    85,
                    101,
                    104,
                    112,
                    122,
                    124,
                    124,
                    128,
                    139,
                    146,
                    151,
                    159,
                    170,
                    170,
                    172,
                    174,
                    183,
                    194,
                    199,
                    202,
                    209,
                    217,
                    231,
                    238,
                    225,
                    209,
                    194,
                    180,
                    172,
                    163,
                    152,
                    155,
                    169,
                    173,
                    168,
                    177,
                    192,
                    198,
                    201,
                    212,
                    216,
                    219,
                    221,
                    225,
                    224,
                    225,
                    223,
                    223,
                    224,
                    227,
                    234,
                    248,
                    275,
                    305,
                    332,
                    336,
                    312,
                    279,
                    249,
                    228,
                    219,
                    216,
                    217,
                    213,
                    197,
                    132,
                    194,
                    628,
                    870,
                    529,
                    188,
                    119,
                    170,
                    205,
                    213,
                    213,
                    214,
                    220,
                    225,
                    230,
                    241,
                    249,
                    259,
                    264,
                    274,
                    281,
                    281,
                    282,
                    287,
                    300,
                    308,
                    308,
                    319,
                    332,
                    343,
                    343,
                    336,
                    332,
                    316,
                    300,
                    293,
                    285,
                    276,
                    270,
                    268,
                    275,
                    282,
                    289,
                    283,
                    253,
                    276,
                    299,
                    305,
                    307,
                    311,
                    307,
                    305,
                    300,
                    301,
                    308,
                    307,
                    307,
                    314,
                    322,
                    337,
                    365,
                    396,
                    417,
                    406,
                    372,
                    338,
                    310,
                    293,
                    286,
                    286,
                    289,
                    289,
                    246,
                    194,
                    436,
                    868,
                    796,
                    406,
                    205,
                    217,
                    267,
                    289,
                    289,
                    299,
                    305,
                    310,
                    316,
                    333,
                    352,
                    363,
                    369,
                    376,
                    381,
                    391,
                    400,
                    400,
                    407,
                    417,
                    423,
                    429,
                    438,
                    450,
                    460,
                    447,
                    428,
                    412,
                    396,
                    385,
                    369,
                    354,
                    343,
                    336,
                    330,
                    338,
                    349,
                    356,
                    355,
                    356,
                    363,
                    363,
                    356,
                    356,
                    362,
                    366,
                    367,
                    366,
                    366,
                    359,
                    351,
                    355,
                    366,
                    380,
                    405,
                    424,
                    432,
                    421,
                    387,
                    349,
                    315,
                    294,
                    285,
                    293,
                    301,
                    290,
                    217,
                    186,
                    524,
                    974,
                    799,
                    336,
                    151,
                    194,
                    256,
                    270,
                    267,
                    261,
                    272,
                    283,
                    281,
                    274,
                    285,
                    293,
                    287,
                    296,
                    301,
                    301,
                    304,
                    307,
                    315,
                    318,
                    315,
                    326,
                    332,
                    330,
                    334,
                    326,
                    300,
                    271,
                    248,
                    228,
                    224,
                    210,
                    201,
                    197,
                    197,
                    205,
                    205,
                    199,
                    203,
                    192,
                    183,
                    179,
                    170,
                    170,
                    166,
                    162,
                    161,
                    151,
                    148,
                    154,
                    165,
                    174,
                    192,
                    208,
                    216,
                    212,
                    179,
                    139,
                    95,
                    71,
                    63,
                    55,
                    46,
                    45,
                    2,
                    -90,
                    73,
                    540,
                    603,
                    190,
                    -97,
                    -137,
                    -68,
                    -23,
                    -16,
                    -21,
                    -23,
                    -17,
                    -10,
                    -16,
                    -27,
                    -20,
                    -12,
                    -14,
                    -13,
                    -10,
                    -17,
                    -18,
                    -9,
                    -9,
                    -17,
                    -18,
                    -18,
                    -10,
                    -9,
                    -24,
                    -40,
                    -61,
                    -91,
                    -107,
                    -109,
                    -119,
                    -149,
                    -159,
                    -155,
                    -156,
                    -155,
                    -156,
                    -153,
                    -174,
                    -276,
                    -355,
                    -300,
                    -211,
                    -185,
                    -171,
                    -173,
                    -175,
                    -187,
                    -180,
                    -162,
                    -142,
                    -113,
                    -104,
                    -109,
                    -134,
                    -167,
                    -198,
                    -218,
                    -236,
                    -243,
                    -258,
                    -261,
                    -276,
                    -367,
                    -286,
                    172,
                    374,
                    22,
                    -306,
                    -360,
                    -293,
                    -254,
                    -258,
                    -272,
                    -280,
                    -268,
                    -257,
                    -258,
                    -251,
                    -240,
                    -224,
                    -210,
                    -199,
                    -198,
                    -203,
                    -193,
                    -195,
                    -218,
                    -202,
                    -175,
                    -175,
                    -167,
                    -152,
                    -153,
                    -175,
                    -192,
                    -214,
                    -225,
                    -229,
                    -240,
                    -251,
                    -280,
                    -297,
                    -275,
                    -258,
                    -253,
                    -254,
                    -235,
                    -232,
                    -229,
                    -222,
                    -217,
                    -206,
                    -206,
                    -210,
                    -220,
                    -210,
                    -191,
                    -178,
                    -155,
                    -125,
                    -105,
                    -112,
                    -136,
                    -164,
                    -196,
                    -220,
                    -222,
                    -224,
                    -229,
                    -226,
                    -243,
                    -330,
                    -300,
                    84,
                    337,
                    68,
                    -250,
                    -346,
                    -313,
                    -272,
                    -255,
                    -262,
                    -266,
                    -254,
                    -236,
                    -222,
                    -217,
                    -210,
                    -202,
                    -188,
                    -177,
                    -164,
                    -164,
                    -151,
                    -136,
                    -113,
                    -104,
                    -93,
                    -78,
                    -71,
                    -61,
                    -54,
                    -74,
                    -98,
                    -116,
                    -130,
                    -142,
                    -155,
                    -164,
                    -166,
                    -160,
                    -145,
                    -130,
                    -123,
                    -105,
                    -89,
                    -80,
                    -75,
                    -68,
                    -63,
                    -58,
                    -56,
                    -49,
                    -45,
                    -43,
                    -29,
                    -17,
                    5,
                    39,
                    52,
                    42,
                    24,
                    -9,
                    -46,
                    -54,
                    -40,
                    -42,
                    -45,
                    -32,
                    -65,
                    -153,
                    8,
                    478,
                    552,
                    154,
                    -131,
                    -152,
                    -65,
                    -18,
                    -18,
                    -21,
                    -14,
                    -3,
                    0,
                    20,
                    35,
                    33,
                    34,
                    59,
                    64,
                    74,
                    96,
                    110,
                    125,
                    135,
                    141,
                    159,
                    191,
                    202,
                    210,
                    184,
                    162,
                    146,
                    132,
                    114,
                    101,
                    95,
                    88,
                    85,
                    88,
                    89,
                    85,
                    82,
                    89,
                    107,
                    119,
                    129,
                    136,
                    144,
                    148,
                    155,
                    177,
                    198,
                    213,
                    230,
                    248,
                    270,
                    278,
                    268,
                    248,
                    220,
                    188,
                    170,
                    172,
                    169,
                    168,
                    168,
                    99,
                    37,
                    310,
                    756,
                    680,
                    263,
                    52,
                    56,
                    132,
                    165,
                    169,
                    172,
                    180,
                    194,
                    208,
                    220,
                    230,
                    248,
                    264,
                    274,
                    287,
                    296,
                    300,
                    314,
                    322,
                    330,
                    345,
                    351,
                    362,
                    367,
                    365,
                    343,
                    316,
                    301,
                    287,
                    274,
                    259,
                    245,
                    242,
                    238,
                    234,
                    235,
                    234,
                    241,
                    245,
                    253,
                    256,
                    259,
                    260,
                    263,
                    264,
                    272,
                    275,
                    275,
                    290,
                    314,
                    338,
                    370,
                    392,
                    400,
                    388,
                    354,
                    315,
                    296,
                    292,
                    300,
                    305,
                    305,
                    292,
                    216,
                    216,
                    592,
                    935,
                    687,
                    303,
                    172,
                    203,
                    253,
                    278,
                    294,
                    290,
                    282,
                    282,
                    283,
                    297,
                    300,
                    301,
                    318,
                    337,
                    352,
                    355,
                    365,
                    380,
                    388,
                    395,
                    395,
                    413,
                    429,
                    436,
                    440,
                    429,
                    398,
                    370,
                    347,
                    332,
                    329,
                    326,
                    325,
                    321,
                    316,
                    318,
                    263,
                    246,
                    305,
                    338,
                    356,
                    354,
                    351,
                    355,
                    369,
                    380,
                    385,
                    378,
                    372,
                    381,
                    395,
                    414,
                    440,
                    468,
                    469,
                    451,
                    420,
                    395,
                    372,
                    352,
                    343,
                    333,
                    332,
                    329,
                    303,
                    223,
                    272,
                    661,
                    872,
                    591,
                    283,
                    188,
                    238,
                    296,
                    307,
                    311,
                    316,
                    321,
                    321,
                    325,
                    341,
                    349,
                    345,
                    347,
                    355,
                    365,
                    374,
                    380,
                    385,
                    395,
                    396,
                    403,
                    418,
                    431,
                    432,
                    423,
                    396,
                    366,
                    337,
                    321,
                    304,
                    297,
                    290,
                    285,
                    286,
                    279,
                    272,
                    267,
                    271,
                    279,
                    290,
                    296,
                    303,
                    307,
                    304,
                    304,
                    303,
                    297,
                    292,
                    294,
                    297,
                    299,
                    297,
                    296,
                    300,
                    312,
                    321,
                    336,
                    352,
                    376,
                    399,
                    405,
                    391,
                    374,
                    356,
                    340,
                    333,
                    333,
                    333,
                    332,
                    327,
                    278,
                    206,
                    434,
                    914,
                    919,
                    482,
                    202,
                    188,
                    265,
                    314,
                    323,
                    326,
                    329,
                    326,
                    330,
                    338,
                    352,
                    365,
                    365,
                    361,
                    373,
                    385,
                    395,
                    402,
                    413,
                    445,
                    467,
                    475,
                    496,
                    518,
                    527,
                    524,
                    494,
                    469,
                    456,
                    435,
                    434,
                    442,
                    435,
                    429,
                    431,
                    432,
                    438,
                    442,
                    447,
                    449,
                    453,
                    467,
                    475,
                    474,
                    485,
                    491,
                    490,
                    489,
                    496,
                    490,
                    490,
                    498,
                    494,
                    497,
                    498,
                    496,
                    493,
                    498,
                    512,
                    524,
                    545,
                    574,
                    599,
                    599,
                    586,
                    564,
                    541,
                    520,
                    513,
                    512,
                    502,
                    493,
                    469,
                    396,
                    358,
                    675,
                    1113,
                    974,
                    544,
                    334,
                    333,
                    398,
                    425,
                    431,
                    443,
                    445,
                    445,
                    453,
                    461,
                    474,
                    479,
                    479,
                    485,
                    500,
                    511,
                    522,
                    541,
                    553,
                    564,
                    581,
                    588,
                    598,
                    610,
                    618,
                    603,
                    573,
                    544,
                    515,
                    489,
                    465,
                    451,
                    442,
                    436,
                    432,
                    416,
                    409,
                    409,
                    414,
                    432,
                    446,
                    447,
                    446,
                    443,
                    454,
                    460,
                    460,
                    465,
                    460,
                    450,
                    447,
                    446,
                    453,
                    457,
                    454,
                    456,
                    461,
                    451,
                    446,
                    449,
                    449,
                    446,
                    449,
                    451,
                    469,
                    496,
                    501,
                    498,
                    478,
                    443,
                    337,
                    199,
                    119,
                    0,
                    -65,
                    -39,
                    44,
                    2,
                    60,
                    507,
                    779,
                    460,
                    106,
                    9,
                    60,
                    205,
                    333,
                    372,
                    369,
                    351,
                    374,
                    392,
                    406,
                    421,
                    431,
                    435,
                    440,
                    446,
                    450,
                    458,
                    465,
                    476,
                    496,
                    512,
                    519,
                    526,
                    531,
                    529,
                    507,
                    474,
                    446,
                    417,
                    394,
                    380,
                    372,
                    356,
                    354,
                    351,
                    347,
                    341,
                    337,
                    343,
                    347,
                    351,
                    361,
                    367,
                    366,
                    374,
                    384,
                    383,
                    380,
                    378,
                    378,
                    374,
                    370,
                    369,
                    372,
                    374,
                    366,
                    361,
                    359,
                    358,
                    362,
                    356,
                    352,
                    355,
                    361,
                    372,
                    402,
                    435,
                    467,
                    479,
                    472,
                    453,
                    435,
                    413,
                    399,
                    392,
                    389,
                    392,
                    392,
                    359,
                    278,
                    424,
                    919,
                    1056,
                    637,
                    301,
                    241,
                    300,
                    343,
                    344,
                    338,
                    332,
                    332,
                    334,
                    344,
                    352,
                    355,
                    363,
                    373,
                    385,
                    389,
                    387,
                    394,
                    403,
                    421,
                    438,
                    443,
                    454,
                    469,
                    476,
                    472,
                    453,
                    427,
                    402,
                    383,
                    362,
                    347,
                    333,
                    322,
                    323,
                    325,
                    323,
                    326,
                    323,
                    327,
                    332,
                    338,
                    351,
                    356,
                    365,
                    369,
                    377,
                    380,
                    374,
                    372,
                    367,
                    370,
                    378,
                    384,
                    388,
                    388,
                    384,
                    385,
                    385,
                    391,
                    402,
                    407,
                    412,
                    431,
                    449,
                    478,
                    508,
                    520,
                    520,
                    500,
                    472,
                    445,
                    429,
                    421,
                    421,
                    423,
                    418,
                    374,
                    300,
                    513,
                    1044,
                    1132,
                    680,
                    355,
                    297,
                    349,
                    402,
                    418,
                    414,
                    412,
                    414,
                    420,
                    427,
                    424,
                    424,
                    427,
                    429,
                    434,
                    440,
                    451,
                    460,
                    474,
                    494,
                    511,
                    527,
                    544,
                    556,
                    560,
                    545,
                    513,
                    475,
                    451,
                    435,
                    424,
                    416,
                    407,
                    399,
                    392,
                    392,
                    391,
                    388,
                    383,
                    381,
                    388,
                    395,
                    394,
                    398,
                    407,
                    409,
                    409,
                    407,
                    410,
                    409,
                    407,
                    407,
                    406,
                    407,
                    409,
                    402,
                    399,
                    400,
                    402,
                    405,
                    414,
                    431,
                    450,
                    487,
                    513,
                    515,
                    498,
                    474
                ]
            },
            "classification": "V",
            "sampleRate": 125,
            "triggerTime": "2022-06-04T23:35:05.000Z"
        },
        {
            "ecgValues": [
                249,
                243,
                184,
                38,
                -90,
                -250,
                -395,
                -446,
                -440,
                -384,
                -338,
                -319,
                -300,
                -290,
                -272,
                -236,
                -189,
                -142,
                -108,
                -86,
                -51,
                -21,
                6,
                49,
                99,
                141,
                199,
                289,
                367,
                392,
                351,
                315,
                337,
                391,
                417,
                410,
                392,
                366,
                352,
                361,
                367,
                367,
                348,
                238,
                206,
                662,
                1189,
                932,
                453,
                363,
                449,
                523,
                526,
                456,
                429,
                447,
                439,
                443,
                476,
                500,
                447,
                403,
                406,
                423,
                451,
                464,
                443,
                425,
                427,
                413,
                403,
                420,
                423,
                435,
                412,
                352,
                326,
                319,
                300,
                290,
                281,
                268,
                268,
                261,
                252,
                256,
                216,
                172,
                159,
                147,
                128,
                110,
                96,
                81,
                64,
                50,
                41,
                41,
                50,
                59,
                57,
                37,
                -36,
                -63,
                -54,
                -65,
                -94,
                -134,
                -156,
                -169,
                -193,
                -204,
                -209,
                -225,
                -251,
                -355,
                -393,
                16,
                544,
                384,
                -79,
                -276,
                -271,
                -224,
                -203,
                -180,
                -160,
                -145,
                -129,
                -100,
                -27,
                46,
                46,
                74,
                119,
                191,
                190,
                212,
                241,
                315,
                315,
                312,
                333,
                351,
                367,
                380,
                374,
                344,
                319,
                275,
                274,
                271,
                128,
                128,
                89,
                66,
                28,
                28,
                22,
                9,
                -43,
                -43,
                -53,
                -51,
                -43,
                -43,
                -25,
                -9,
                -18,
                -18,
                -32,
                -9,
                -13,
                -32,
                -43,
                -43,
                -39,
                -27,
                22,
                22,
                97,
                150,
                172,
                172,
                257,
                308,
                351,
                351,
                366,
                388,
                793,
                790,
                1008,
                607,
                468,
                530,
                570,
                563,
                538,
                520,
                507,
                449,
                378,
                300,
                242,
                212,
                174,
                146,
                135,
                82,
                39,
                31,
                20,
                2,
                -2,
                15,
                26,
                38,
                24,
                26,
                55,
                93,
                173,
                264,
                373,
                507,
                643,
                819,
                965,
                1058,
                1154,
                1226,
                1298,
                1368,
                1413,
                1419,
                1406,
                1406,
                1411,
                1412,
                1366,
                1313,
                1252,
                1185,
                1113,
                1001,
                846,
                715,
                610,
                518,
                424,
                351,
                279,
                256,
                242,
                192,
                135,
                96,
                81,
                60,
                17,
                -13,
                -14,
                -54,
                -159,
                15,
                577,
                742,
                429,
                283,
                389,
                580,
                766,
                939,
                1095,
                1245,
                1433,
                1597,
                1730,
                1871,
                1992,
                2075,
                2149,
                2207,
                2236,
                2240,
                2193,
                2126,
                2033,
                1878,
                1686,
                1455,
                1213,
                1015,
                814,
                637,
                529,
                445,
                363,
                292,
                257,
                209,
                170,
                173,
                177,
                144,
                100,
                89,
                95,
                112,
                141,
                308,
                640,
                992,
                1315,
                1598,
                1843,
                2053,
                2235,
                2385,
                2500,
                2641,
                2787,
                2917,
                3057,
                3191,
                3388,
                3590,
                3745,
                3928,
                4133,
                4314,
                4447,
                4587,
                4755,
                4957,
                5158,
                5310,
                5418,
                5462,
                5620,
                6146,
                6524,
                6321,
                6053,
                6047,
                6171,
                6284,
                6370,
                6444,
                6507,
                6530,
                6545,
                6509,
                6385,
                6266,
                6131,
                5944,
                5745,
                5580,
                5411,
                5238,
                5085,
                4944,
                4822,
                4684,
                4519,
                4347,
                4183,
                4005,
                3825,
                3655,
                3533,
                3544,
                3646,
                3793,
                3954,
                4153,
                4399,
                4643,
                4862,
                5085,
                5303,
                5518,
                5725,
                5909,
                6094,
                6310,
                6498,
                6651,
                6844,
                7028,
                7177,
                7342,
                7512,
                7669,
                7814,
                7954,
                8092,
                8222,
                8354,
                8516,
                8682,
                8820,
                8926,
                9000,
                9026,
                9041,
                9058,
                9041,
                8996,
                8905,
                8759,
                8545,
                8211,
                7982,
                8155,
                8231,
                7728,
                7114,
                6749,
                6556,
                6363,
                6155,
                5951,
                5733,
                5540,
                5360,
                5183,
                5063,
                4941,
                4810,
                4679,
                4566,
                4485,
                4417,
                4306,
                4164,
                4099,
                4143,
                4289,
                4452,
                4592,
                4724,
                4835,
                4912,
                4994,
                5091,
                5190,
                5252,
                5317,
                5372,
                5408,
                5409,
                5373,
                5302,
                5194,
                5107,
                4992,
                4814,
                4654,
                4503,
                4343,
                4148,
                3989,
                3887,
                3819,
                3851,
                3942,
                4064,
                4326,
                4609,
                4908,
                5183,
                5394,
                5590,
                5751,
                5865,
                5933,
                5954,
                5945,
                5907,
                5839,
                5751,
                5650,
                5503,
                5317,
                5054,
                4749,
                4840,
                5161,
                4853,
                4143,
                3703,
                3534,
                3406,
                3258,
                3118,
                3002,
                2903,
                2816,
                2744,
                2680,
                2604,
                2542,
                2478,
                2416,
                2356,
                2302,
                2250,
                2193,
                2142,
                2091,
                2048,
                2003,
                1951,
                1905,
                1872,
                1834,
                1784,
                1734,
                1686,
                1636,
                1594,
                1555,
                1519,
                1485,
                1455,
                1423,
                1397,
                1376,
                1353,
                1331,
                1303,
                1284,
                1269,
                1247,
                1226,
                1205,
                1189,
                1176,
                1157,
                1142,
                1134,
                1118,
                1105,
                1096,
                1091,
                1099,
                1117,
                1118,
                1094,
                1045,
                994,
                946,
                913,
                890,
                869,
                841,
                815,
                746,
                636,
                835,
                1324,
                1325,
                892,
                611,
                577,
                603,
                591,
                573,
                553,
                533,
                516,
                504,
                489,
                476,
                468,
                457,
                446,
                436,
                425,
                409,
                389,
                381,
                363,
                340,
                327,
                312,
                312,
                312,
                296,
                263,
                241,
                216,
                186,
                162,
                152,
                141,
                130,
                124,
                103,
                0,
                -79,
                -17,
                88,
                121,
                128,
                128,
                118,
                110,
                104,
                96,
                86,
                84,
                64,
                57,
                62,
                59,
                62,
                64,
                78,
                99,
                106,
                90,
                56,
                28,
                5,
                -2,
                -3,
                -18,
                -24,
                -45,
                -147,
                -119,
                305,
                617,
                336,
                -34,
                -156,
                -108,
                -51,
                -49,
                -60,
                -54,
                -51,
                -60,
                -54,
                -56,
                -60,
                -58,
                -58,
                -65,
                -78,
                -76,
                -83,
                -91,
                -94,
                -94,
                -104,
                -113,
                -116,
                -115,
                -118,
                -126,
                -147,
                -163,
                -177,
                -204,
                -221,
                -233,
                -243,
                -247,
                -250,
                -257,
                -265,
                -268,
                -268,
                -276,
                -280,
                -291,
                -300,
                -306,
                -316,
                -330,
                -335,
                -339,
                -357,
                -371,
                -367,
                -356,
                -348,
                -328,
                -308,
                -284,
                -271,
                -283,
                -322,
                -360,
                -377,
                -385,
                -390,
                -395,
                -406,
                -428,
                -530,
                -548,
                -127,
                301,
                57,
                -371,
                -524,
                -508,
                -488,
                -499,
                -516,
                -517,
                -513,
                -512,
                -512,
                -521,
                -527,
                -525,
                -520,
                -520,
                -524,
                -520,
                -523,
                -528,
                -528,
                -532,
                -530,
                -521,
                -517,
                -509,
                -505,
                -521,
                -539,
                -559,
                -575,
                -582,
                -587,
                -600,
                -616,
                -625,
                -636,
                -644,
                -648,
                -656,
                -684,
                -703,
                -678,
                -677,
                -678,
                -681,
                -692,
                -698,
                -700,
                -712,
                -717,
                -718,
                -731,
                -739,
                -739,
                -724,
                -703,
                -687,
                -689,
                -718,
                -757,
                -793,
                -809,
                -818,
                -831,
                -841,
                -842,
                -871,
                -977,
                -953,
                -484,
                -148,
                -463,
                -859,
                -966,
                -917,
                -885,
                -888,
                -887,
                -885,
                -874,
                -867,
                -873,
                -870,
                -860,
                -856,
                -858,
                -855,
                -849,
                -845,
                -844,
                -849,
                -849,
                -845,
                -851,
                -852,
                -849,
                -844,
                -844,
                -853,
                -867,
                -885,
                -904,
                -921,
                -940,
                -947,
                -951,
                -962,
                -965,
                -965,
                -957,
                -951,
                -961,
                -962,
                -961,
                -957,
                -955,
                -962,
                -968,
                -972,
                -973,
                -976,
                -980,
                -980,
                -976,
                -966,
                -947,
                -918,
                -896,
                -892,
                -913,
                -954,
                -997,
                -1022,
                -1039,
                -1050,
                -1060,
                -1070,
                -1088,
                -1169,
                -1183,
                -801,
                -415,
                -637,
                -1038,
                -1184,
                -1161,
                -1118,
                -1107,
                -1110,
                -1114,
                -1124,
                -1126,
                -1124,
                -1124,
                -1126,
                -1130,
                -1137,
                -1137,
                -1141,
                -1144,
                -1143,
                -1146,
                -1146,
                -1148,
                -1152,
                -1147,
                -1141,
                -1133,
                -1133,
                -1147,
                -1168,
                -1176,
                -1188,
                -1206,
                -1225,
                -1242,
                -1252,
                -1254,
                -1263,
                -1264,
                -1259,
                -1259,
                -1259,
                -1264,
                -1265,
                -1263,
                -1264,
                -1263,
                -1264,
                -1267,
                -1265,
                -1263,
                -1264,
                -1264,
                -1252,
                -1237,
                -1214,
                -1190,
                -1172,
                -1180,
                -1205,
                -1238,
                -1265,
                -1287,
                -1293,
                -1297,
                -1299,
                -1292,
                -1312,
                -1395,
                -1351,
                -932,
                -655,
                -932,
                -1272,
                -1372,
                -1330,
                -1293,
                -1285,
                -1281,
                -1283,
                -1290,
                -1294,
                -1297,
                -1285,
                -1278,
                -1275,
                -1271,
                -1279,
                -1287,
                -1289,
                -1289,
                -1281,
                -1286,
                -1292,
                -1285,
                -1289,
                -1282,
                -1281,
                -1285,
                -1307,
                -1316,
                -1325,
                -1337,
                -1351,
                -1363,
                -1372,
                -1384,
                -1376,
                -1363,
                -1352,
                -1354,
                -1347,
                -1344,
                -1351,
                -1347,
                -1343,
                -1333,
                -1333,
                -1333,
                -1330,
                -1334,
                -1337,
                -1338,
                -1338,
                -1329,
                -1307,
                -1282,
                -1248,
                -1235,
                -1254,
                -1282,
                -1311,
                -1345,
                -1366,
                -1377,
                -1387,
                -1385,
                -1392,
                -1436,
                -1524,
                -1348,
                -906,
                -859,
                -1205,
                -1461,
                -1505,
                -1446,
                -1400,
                -1399,
                -1406,
                -1399,
                -1403,
                -1405,
                -1395,
                -1396,
                -1399,
                -1398,
                -1388,
                -1381,
                -1377,
                -1367,
                -1358,
                -1352,
                -1358,
                -1355,
                -1351,
                -1349,
                -1344,
                -1334,
                -1325,
                -1337,
                -1359,
                -1378,
                -1391,
                -1406,
                -1416,
                -1434,
                -1469,
                -1461,
                -1440,
                -1425,
                -1407,
                -1400,
                -1406,
                -1411,
                -1413,
                -1416,
                -1414,
                -1407,
                -1398,
                -1391,
                -1395,
                -1398,
                -1394,
                -1396,
                -1400,
                -1383,
                -1349,
                -1323,
                -1310,
                -1312,
                -1334,
                -1362,
                -1381,
                -1385,
                -1388,
                -1384,
                -1377,
                -1402,
                -1493,
                -1494,
                -1125,
                -738,
                -944,
                -1332,
                -1497,
                -1472,
                -1403,
                -1372,
                -1359,
                -1348,
                -1332,
                -1318,
                -1305,
                -1290,
                -1274,
                -1257,
                -1248,
                -1235,
                -1220,
                -1213,
                -1208,
                -1202,
                -1191,
                -1184,
                -1183,
                -1179,
                -1172,
                -1163,
                -1165,
                -1172,
                -1191,
                -1203,
                -1209,
                -1224,
                -1243,
                -1248,
                -1252,
                -1249,
                -1252,
                -1256,
                -1250,
                -1245,
                -1245,
                -1242,
                -1241,
                -1239,
                -1231,
                -1223,
                -1219,
                -1225,
                -1228,
                -1227,
                -1231,
                -1242,
                -1231,
                -1219,
                -1203,
                -1180,
                -1172,
                -1191,
                -1223,
                -1256,
                -1278,
                -1296,
                -1301,
                -1286,
                -1286,
                -1308,
                -1406,
                -1416,
                -995,
                -578,
                -807,
                -1225,
                -1388,
                -1340,
                -1268,
                -1235,
                -1217,
                -1203,
                -1194,
                -1183,
                -1168,
                -1158,
                -1140,
                -1125,
                -1119,
                -1107,
                -1095,
                -1088,
                -1079,
                -1068,
                -1053,
                -1042,
                -1042,
                -1034,
                -1017,
                -1005,
                -993,
                -999,
                -1019,
                -1037,
                -1055,
                -1052,
                -1049,
                -1050,
                -1055,
                -1057,
                -1059,
                -1055,
                -1050,
                -1044,
                -1030,
                -1013,
                -1001,
                -993,
                -986,
                -980,
                -980,
                -969,
                -955,
                -960,
                -960,
                -939,
                -915,
                -891,
                -867,
                -859,
                -881,
                -918,
                -953,
                -979,
                -991,
                -999,
                -1011,
                -1022,
                -1041,
                -1130,
                -1143,
                -723,
                -349,
                -684,
                -1133,
                -1205,
                -1125,
                -1073,
                -1063,
                -1056,
                -1046,
                -1044,
                -1039,
                -1030,
                -1022,
                -1017,
                -1009,
                -998,
                -993,
                -993,
                -993,
                -990,
                -984,
                -979,
                -972,
                -962,
                -957,
                -951,
                -943,
                -939,
                -947,
                -962,
                -969,
                -982,
                -988,
                -993,
                -995,
                -991,
                -984,
                -982,
                -975,
                -965,
                -958,
                -950,
                -944,
                -940,
                -936,
                -932,
                -929,
                -929
            ],
            "bpm": 88,
            "nextStrip": {
                "ecgValues": [
                    -924,
                    -922,
                    -917,
                    -914,
                    -909,
                    -896,
                    -874,
                    -851,
                    -833,
                    -838,
                    -869,
                    -907,
                    -936,
                    -946,
                    -951,
                    -950,
                    -954,
                    -954,
                    -1005,
                    -1096,
                    -875,
                    -346,
                    -293,
                    -728,
                    -1019,
                    -1039,
                    -982,
                    -960,
                    -955,
                    -954,
                    -951,
                    -949,
                    -939,
                    -929,
                    -921,
                    -918,
                    -914,
                    -904,
                    -896,
                    -892,
                    -885,
                    -877,
                    -864,
                    -852,
                    -841,
                    -830,
                    -819,
                    -807,
                    -800,
                    -802,
                    -822,
                    -838,
                    -848,
                    -855,
                    -864,
                    -888,
                    -918,
                    -900,
                    -887,
                    -887,
                    -881,
                    -873,
                    -864,
                    -858,
                    -853,
                    -845,
                    -842,
                    -840,
                    -834,
                    -825,
                    -823,
                    -818,
                    -811,
                    -798,
                    -790,
                    -775,
                    -749,
                    -717,
                    -694,
                    -683,
                    -695,
                    -725,
                    -756,
                    -776,
                    -787,
                    -791,
                    -783,
                    -779,
                    -787,
                    -853,
                    -913,
                    -599,
                    -127,
                    -236,
                    -661,
                    -866,
                    -847,
                    -796,
                    -772,
                    -771,
                    -765,
                    -760,
                    -756,
                    -756,
                    -750,
                    -740,
                    -731,
                    -725,
                    -718,
                    -713,
                    -706,
                    -696,
                    -687,
                    -680,
                    -677,
                    -670,
                    -656,
                    -643,
                    -633,
                    -632,
                    -632,
                    -644,
                    -662,
                    -685,
                    -699,
                    -712,
                    -717,
                    -720,
                    -725,
                    -728,
                    -724,
                    -724,
                    -727,
                    -720,
                    -721,
                    -729,
                    -724,
                    -716,
                    -706,
                    -702,
                    -702,
                    -707,
                    -710,
                    -714,
                    -718,
                    -721,
                    -725,
                    -720,
                    -713,
                    -706,
                    -688,
                    -663,
                    -641,
                    -648,
                    -678,
                    -707,
                    -739,
                    -756,
                    -764,
                    -772,
                    -771,
                    -769,
                    -789,
                    -874,
                    -809,
                    -355,
                    -116,
                    -433,
                    -772,
                    -852,
                    -790,
                    -743,
                    -743,
                    -739,
                    -742,
                    -758,
                    -751,
                    -756,
                    -751,
                    -706,
                    -710,
                    -747,
                    -754,
                    -713,
                    -725,
                    -760,
                    -743,
                    -698,
                    -676,
                    -689,
                    -703,
                    -696,
                    -667,
                    -648,
                    -654,
                    -666,
                    -689,
                    -700,
                    -677,
                    -672,
                    -683,
                    -702,
                    -712,
                    -712,
                    -696,
                    -676,
                    -640,
                    -619,
                    -630,
                    -616,
                    -612,
                    -615,
                    -599,
                    -593,
                    -590,
                    -564,
                    -530,
                    -505,
                    -514,
                    -531,
                    -538,
                    -528,
                    -503,
                    -461,
                    -441,
                    -437,
                    -411,
                    -399,
                    -403,
                    -436,
                    -497,
                    -525,
                    -520,
                    -516,
                    -523,
                    -508,
                    -488,
                    -575,
                    -643,
                    -359,
                    38,
                    -78,
                    -450,
                    -650,
                    -636,
                    -561,
                    -521,
                    -532,
                    -517,
                    -524,
                    -523,
                    -510,
                    -476,
                    -455,
                    -503,
                    -548,
                    -505,
                    -483,
                    -470,
                    -443,
                    -424,
                    -437,
                    -424,
                    -408,
                    -426,
                    -363,
                    -350,
                    -370,
                    -357,
                    -413,
                    -457,
                    -479,
                    -470,
                    -450,
                    -422,
                    -399,
                    -426,
                    -450,
                    -479,
                    -458,
                    -425,
                    -415,
                    -410,
                    -390,
                    -400,
                    -374,
                    -348,
                    -367,
                    -349,
                    -308,
                    -280,
                    -266,
                    -239,
                    -200,
                    -166,
                    -181,
                    -130,
                    -111,
                    -108,
                    -63,
                    -7,
                    33,
                    -16,
                    -63,
                    -78,
                    -91,
                    -91,
                    -68,
                    -60,
                    -105,
                    -204,
                    -257,
                    39,
                    519,
                    479,
                    95,
                    -138,
                    -206,
                    -136,
                    -63,
                    -68,
                    -91,
                    -90,
                    -76,
                    -76,
                    -68,
                    -25,
                    -5,
                    -13,
                    16,
                    -21,
                    -54,
                    -39,
                    -29,
                    -29,
                    -6,
                    33,
                    46,
                    41,
                    59,
                    59,
                    15,
                    9,
                    15,
                    -2,
                    4,
                    5,
                    -9,
                    -58,
                    -96,
                    -90,
                    -76,
                    -65,
                    -54,
                    -64,
                    -85,
                    -68,
                    -151,
                    -177,
                    -160,
                    -133,
                    -125,
                    -136,
                    -142,
                    -156,
                    -147,
                    -125,
                    -107,
                    -107,
                    -100,
                    -90,
                    -85,
                    -49,
                    -35,
                    -76,
                    -98,
                    -102,
                    -137,
                    -133,
                    -108,
                    -156,
                    -173,
                    -182,
                    -265,
                    -247,
                    169,
                    571,
                    297,
                    -167,
                    -324,
                    -288,
                    -238,
                    -199,
                    -170,
                    -158,
                    -163,
                    -162,
                    -145,
                    -136,
                    -123,
                    -86,
                    -60,
                    -24,
                    20,
                    42,
                    56,
                    77,
                    128,
                    190,
                    243,
                    303,
                    359,
                    414,
                    460,
                    482,
                    485,
                    491,
                    505,
                    529,
                    536,
                    530,
                    518,
                    519,
                    524,
                    524,
                    540,
                    547,
                    545,
                    545,
                    520,
                    474,
                    432,
                    374,
                    326,
                    281,
                    216,
                    157,
                    135,
                    133,
                    147,
                    135,
                    117,
                    126,
                    111,
                    67,
                    52,
                    37,
                    -10,
                    -105,
                    -163,
                    -175,
                    -178,
                    -189,
                    -204,
                    -231,
                    -330,
                    -418,
                    -72,
                    566,
                    487,
                    -123,
                    -421,
                    -425,
                    -373,
                    -356,
                    -362,
                    -371,
                    -381,
                    -386,
                    -393,
                    -399,
                    -403,
                    -401,
                    -401,
                    -400,
                    -400,
                    -397,
                    -401,
                    -395,
                    -392,
                    -386,
                    -389,
                    -393,
                    -393,
                    -388,
                    -377,
                    -386,
                    -397,
                    -419,
                    -440,
                    -458,
                    -470,
                    -473,
                    -487,
                    -494,
                    -497,
                    -498,
                    -503,
                    -501,
                    -495,
                    -488,
                    -487,
                    -483,
                    -472,
                    -472,
                    -468,
                    -461,
                    -451,
                    -447,
                    -452,
                    -437,
                    -418,
                    -410,
                    -395,
                    -377,
                    -362,
                    -335,
                    -320,
                    -322,
                    -346,
                    -384,
                    -417,
                    -430,
                    -425,
                    -415,
                    -407,
                    -411,
                    -472,
                    -563,
                    -255,
                    520,
                    661,
                    -16,
                    -425,
                    -435,
                    -382,
                    -370,
                    -370,
                    -371,
                    -367,
                    -362,
                    -367,
                    -366,
                    -364,
                    -371,
                    -371,
                    -366,
                    -368,
                    -368,
                    -370,
                    -379,
                    -378,
                    -436,
                    -548,
                    -632,
                    -754,
                    -827,
                    -841,
                    -818,
                    -749,
                    -720,
                    -727,
                    -745,
                    -757,
                    -772,
                    -787,
                    -793,
                    -798,
                    -804,
                    -808,
                    -812,
                    -808,
                    -809,
                    -802,
                    -809,
                    -813,
                    -815,
                    -818,
                    -818,
                    -825,
                    -836,
                    -841,
                    -858,
                    -866,
                    -863,
                    -852,
                    -849,
                    -844,
                    -818,
                    -813,
                    -802,
                    -804,
                    -837,
                    -875,
                    -903,
                    -921,
                    -932,
                    -917,
                    -815,
                    -732,
                    -757,
                    -864,
                    -713,
                    -69,
                    234,
                    -297,
                    -760,
                    -829,
                    -775,
                    -769,
                    -761,
                    -756,
                    -787,
                    -772,
                    -747,
                    -742,
                    -746,
                    -740,
                    -735,
                    -735,
                    -729,
                    -728,
                    -724,
                    -718,
                    -717,
                    -705,
                    -687,
                    -674,
                    -666,
                    -654,
                    -640,
                    -636,
                    -648,
                    -670,
                    -692,
                    -703,
                    -703,
                    -699,
                    -703,
                    -729,
                    -738,
                    -738,
                    -738,
                    -740,
                    -735,
                    -735,
                    -747,
                    -731,
                    -706,
                    -699,
                    -688,
                    -672,
                    -665,
                    -656,
                    -645,
                    -645,
                    -645,
                    -643,
                    -651,
                    -655,
                    -651,
                    -648,
                    -640,
                    -625,
                    -605,
                    -587,
                    -607,
                    -640,
                    -667,
                    -702,
                    -702,
                    -698,
                    -696,
                    -683,
                    -696,
                    -794,
                    -805,
                    -400,
                    38,
                    -181,
                    -618,
                    -800,
                    -794,
                    -742,
                    -720,
                    -728,
                    -743,
                    -746,
                    -747,
                    -756,
                    -762,
                    -771,
                    -782,
                    -778,
                    -775,
                    -774,
                    -775,
                    -780,
                    -782,
                    -790,
                    -808,
                    -808,
                    -798,
                    -797,
                    -793,
                    -785,
                    -791,
                    -811,
                    -838,
                    -859,
                    -874,
                    -896,
                    -910,
                    -914,
                    -917,
                    -914,
                    -914,
                    -918,
                    -921,
                    -922,
                    -924,
                    -928,
                    -922,
                    -924,
                    -918,
                    -895,
                    -882,
                    -869,
                    -851,
                    -848,
                    -840,
                    -836,
                    -830,
                    -829,
                    -822,
                    -820,
                    -816,
                    -808,
                    -786,
                    -775,
                    -790,
                    -822,
                    -870,
                    -914,
                    -935,
                    -950,
                    -962,
                    -972,
                    -973,
                    -1023,
                    -1130,
                    -986,
                    -532,
                    -469,
                    -825,
                    -1073,
                    -1126,
                    -1064,
                    -1015,
                    -1008,
                    -993,
                    -979,
                    -987,
                    -980,
                    -971,
                    -969,
                    -971,
                    -975,
                    -979,
                    -975,
                    -969,
                    -962,
                    -960,
                    -955,
                    -954,
                    -949,
                    -944,
                    -937,
                    -920,
                    -913,
                    -937,
                    -964,
                    -979,
                    -997,
                    -1017,
                    -1024,
                    -1020,
                    -1031,
                    -1041,
                    -1041,
                    -1037,
                    -1034,
                    -1042,
                    -1049,
                    -1044,
                    -1044,
                    -1044,
                    -1030,
                    -1034,
                    -1039,
                    -1026,
                    -1013,
                    -1017,
                    -1012,
                    -1011,
                    -1019,
                    -1016,
                    -1017,
                    -1012,
                    -995,
                    -980,
                    -958,
                    -954,
                    -968,
                    -997,
                    -1045,
                    -1075,
                    -1081,
                    -1085,
                    -1090,
                    -1095,
                    -1106,
                    -1154,
                    -1239,
                    -1084,
                    -637,
                    -570,
                    -950,
                    -1219,
                    -1259,
                    -1206,
                    -1152,
                    -1112,
                    -1079,
                    -1064,
                    -1046,
                    -1033,
                    -1023,
                    -1008,
                    -1011,
                    -1004,
                    -990,
                    -983,
                    -971,
                    -954,
                    -955,
                    -958,
                    -953,
                    -957,
                    -960,
                    -960,
                    -947,
                    -936,
                    -939,
                    -961,
                    -983,
                    -1013,
                    -1038,
                    -1045,
                    -1046,
                    -1039,
                    -1041,
                    -1048,
                    -1049,
                    -1052,
                    -1049,
                    -1049,
                    -1046,
                    -1038,
                    -1039,
                    -1046,
                    -1053,
                    -1060,
                    -1057,
                    -1064,
                    -1075,
                    -1081,
                    -1081,
                    -1079,
                    -1067,
                    -1045,
                    -1030,
                    -1011,
                    -997,
                    -990,
                    -1008,
                    -1039,
                    -1075,
                    -1092,
                    -1101,
                    -1125,
                    -1150,
                    -1126,
                    -1177,
                    -1286,
                    -1099,
                    -583,
                    -498,
                    -864,
                    -1137,
                    -1213,
                    -1174,
                    -1122,
                    -1093,
                    -1071,
                    -1057,
                    -1039,
                    -1019,
                    -993,
                    -966,
                    -950,
                    -928,
                    -907,
                    -900,
                    -900,
                    -891,
                    -882,
                    -878,
                    -871,
                    -877,
                    -884,
                    -885,
                    -887,
                    -882,
                    -892,
                    -914,
                    -928,
                    -944,
                    -950,
                    -957,
                    -960,
                    -960,
                    -966,
                    -969,
                    -969,
                    -976,
                    -972,
                    -977,
                    -984,
                    -986,
                    -983,
                    -979,
                    -982,
                    -969,
                    -944,
                    -924,
                    -896,
                    -873,
                    -841,
                    -804,
                    -779,
                    -756,
                    -731,
                    -734,
                    -767,
                    -811,
                    -866,
                    -913,
                    -942,
                    -966,
                    -1002,
                    -1031,
                    -1041,
                    -1085,
                    -1192,
                    -1088,
                    -623,
                    -469,
                    -860,
                    -1195,
                    -1283,
                    -1242,
                    -1209,
                    -1214,
                    -1234,
                    -1239,
                    -1245,
                    -1254,
                    -1265,
                    -1278,
                    -1283,
                    -1278,
                    -1268,
                    -1260,
                    -1257,
                    -1242,
                    -1224,
                    -1217,
                    -1199,
                    -1187,
                    -1173,
                    -1157,
                    -1144,
                    -1136,
                    -1140,
                    -1166,
                    -1195,
                    -1213,
                    -1224,
                    -1238,
                    -1252,
                    -1259,
                    -1271,
                    -1279,
                    -1301,
                    -1318,
                    -1326,
                    -1327,
                    -1327,
                    -1341,
                    -1356,
                    -1370,
                    -1388,
                    -1392,
                    -1392,
                    -1403,
                    -1399,
                    -1391,
                    -1374,
                    -1361,
                    -1348,
                    -1338,
                    -1345,
                    -1370,
                    -1409,
                    -1451,
                    -1482,
                    -1504,
                    -1513,
                    -1518,
                    -1524,
                    -1526,
                    -1588,
                    -1680,
                    -1451,
                    -953,
                    -922,
                    -1300,
                    -1536,
                    -1549,
                    -1475,
                    -1423,
                    -1396,
                    -1377,
                    -1361,
                    -1330,
                    -1304,
                    -1283,
                    -1263,
                    -1252,
                    -1252,
                    -1238,
                    -1221,
                    -1214,
                    -1194,
                    -1176,
                    -1192,
                    -1165,
                    -1124,
                    -1093,
                    -1073,
                    -1064,
                    -1066,
                    -1078,
                    -1100,
                    -1121,
                    -1141,
                    -1163,
                    -1180,
                    -1190,
                    -1190,
                    -1195,
                    -1212,
                    -1230,
                    -1246,
                    -1281,
                    -1312,
                    -1333,
                    -1343,
                    -1347,
                    -1362,
                    -1378,
                    -1399,
                    -1411,
                    -1420,
                    -1416,
                    -1411,
                    -1402,
                    -1399,
                    -1407,
                    -1428,
                    -1475,
                    -1518,
                    -1549,
                    -1575,
                    -1602,
                    -1624,
                    -1636,
                    -1640,
                    -1680,
                    -1785,
                    -1673,
                    -1220,
                    -1053,
                    -1409,
                    -1720,
                    -1807,
                    -1774,
                    -1730,
                    -1699,
                    -1694,
                    -1695,
                    -1693,
                    -1699,
                    -1704,
                    -1704,
                    -1716,
                    -1717,
                    -1705,
                    -1690,
                    -1688,
                    -1679,
                    -1658,
                    -1642,
                    -1632,
                    -1620,
                    -1593,
                    -1575,
                    -1569,
                    -1564,
                    -1580,
                    -1611,
                    -1636,
                    -1642,
                    -1664,
                    -1682,
                    -1690,
                    -1687,
                    -1688,
                    -1673,
                    -1660,
                    -1653,
                    -1626,
                    -1606,
                    -1598,
                    -1585,
                    -1574,
                    -1559,
                    -1547,
                    -1533,
                    -1519,
                    -1511,
                    -1542,
                    -1625,
                    -1636,
                    -1548,
                    -1471,
                    -1469,
                    -1489,
                    -1527,
                    -1551,
                    -1552,
                    -1559,
                    -1573,
                    -1575,
                    -1603,
                    -1691,
                    -1682,
                    -1329,
                    -1041,
                    -1264,
                    -1595,
                    -1726,
                    -1706,
                    -1651,
                    -1625,
                    -1613,
                    -1607,
                    -1602,
                    -1592,
                    -1592,
                    -1596,
                    -1586,
                    -1581,
                    -1574,
                    -1564,
                    -1566,
                    -1558,
                    -1542,
                    -1531,
                    -1505,
                    -1482,
                    -1465,
                    -1462,
                    -1442,
                    -1420,
                    -1423,
                    -1450,
                    -1479,
                    -1486,
                    -1487,
                    -1493,
                    -1502,
                    -1500,
                    -1497,
                    -1502,
                    -1504,
                    -1502,
                    -1502,
                    -1498,
                    -1498,
                    -1490,
                    -1496,
                    -1494,
                    -1479,
                    -1472,
                    -1469,
                    -1457,
                    -1438,
                    -1410,
                    -1394,
                    -1377,
                    -1361,
                    -1347,
                    -1366,
                    -1405,
                    -1425,
                    -1429,
                    -1436,
                    -1439,
                    -1428,
                    -1434,
                    -1474,
                    -1564,
                    -1468,
                    -1034,
                    -852,
                    -1180,
                    -1487,
                    -1589,
                    -1559,
                    -1522,
                    -1515,
                    -1523,
                    -1527,
                    -1523,
                    -1518,
                    -1508,
                    -1500,
                    -1494
                ]
            },
            "previousStrip": {
                "ecgValues": [
                    -1490,
                    -1486,
                    -1486,
                    -1487,
                    -1479,
                    -1471,
                    -1468,
                    -1457,
                    -1440,
                    -1428,
                    -1406,
                    -1396,
                    -1392,
                    -1403,
                    -1428,
                    -1451,
                    -1469,
                    -1487,
                    -1504,
                    -1509,
                    -1505,
                    -1512,
                    -1522,
                    -1524,
                    -1520,
                    -1523,
                    -1512,
                    -1511,
                    -1512,
                    -1497,
                    -1487,
                    -1489,
                    -1483,
                    -1475,
                    -1458,
                    -1425,
                    -1402,
                    -1392,
                    -1377,
                    -1374,
                    -1372,
                    -1398,
                    -1414,
                    -1420,
                    -1421,
                    -1414,
                    -1411,
                    -1416,
                    -1414,
                    -1410,
                    -1468,
                    -1449,
                    -1064,
                    -713,
                    -943,
                    -1297,
                    -1423,
                    -1392,
                    -1330,
                    -1300,
                    -1290,
                    -1286,
                    -1287,
                    -1283,
                    -1279,
                    -1272,
                    -1259,
                    -1250,
                    -1248,
                    -1245,
                    -1242,
                    -1239,
                    -1238,
                    -1234,
                    -1213,
                    -1194,
                    -1176,
                    -1166,
                    -1154,
                    -1136,
                    -1137,
                    -1168,
                    -1201,
                    -1223,
                    -1242,
                    -1256,
                    -1267,
                    -1272,
                    -1271,
                    -1275,
                    -1278,
                    -1282,
                    -1282,
                    -1276,
                    -1271,
                    -1260,
                    -1257,
                    -1254,
                    -1248,
                    -1239,
                    -1228,
                    -1223,
                    -1219,
                    -1199,
                    -1174,
                    -1155,
                    -1143,
                    -1119,
                    -1081,
                    -1070,
                    -1086,
                    -1110,
                    -1140,
                    -1152,
                    -1144,
                    -1136,
                    -1115,
                    -1095,
                    -1090,
                    -1110,
                    -1194,
                    -1110,
                    -652,
                    -452,
                    -761,
                    -1053,
                    -1125,
                    -1071,
                    -1019,
                    -994,
                    -993,
                    -987,
                    -973,
                    -965,
                    -964,
                    -973,
                    -971,
                    -968,
                    -972,
                    -982,
                    -993,
                    -994,
                    -988,
                    -986,
                    -971,
                    -955,
                    -942,
                    -928,
                    -920,
                    -920,
                    -939,
                    -972,
                    -1039,
                    -1037,
                    -1068,
                    -1079,
                    -1099,
                    -1096,
                    -1106,
                    -1111,
                    -1128,
                    -1125,
                    -1124,
                    -1126,
                    -1132,
                    -1129,
                    -1121,
                    -1115,
                    -1093,
                    -1090,
                    -1095,
                    -1093,
                    -1090,
                    -1096,
                    -1093,
                    -1090,
                    -1070,
                    -1056,
                    -1041,
                    -1038,
                    -1024,
                    -1037,
                    -1066,
                    -1063,
                    -1097,
                    -1132,
                    -1129,
                    -1126,
                    -1128,
                    -1150,
                    -1177,
                    -1174,
                    -1257,
                    -699,
                    -487,
                    -871,
                    -1208,
                    -1303,
                    -1264,
                    -1198,
                    -1177,
                    -1165,
                    -1146,
                    -1124,
                    -1107,
                    -1090,
                    -1077,
                    -1066,
                    -1050,
                    -1030,
                    -1001,
                    -957,
                    -920,
                    -884,
                    -852,
                    -827,
                    -801,
                    -756,
                    -734,
                    -724,
                    -710,
                    -723,
                    -760,
                    -798,
                    -829,
                    -851,
                    -881,
                    -907,
                    -914,
                    -925,
                    -932,
                    -935,
                    -944,
                    -944,
                    -935,
                    -925,
                    -918,
                    -915,
                    -904,
                    -907,
                    -904,
                    -893,
                    -893,
                    -899,
                    -911,
                    -917,
                    -920,
                    -915,
                    -895,
                    -878,
                    -864,
                    -838,
                    -833,
                    -845,
                    -870,
                    -898,
                    -926,
                    -939,
                    -939,
                    -947,
                    -958,
                    -955,
                    -964,
                    -1053,
                    -1097,
                    -734,
                    -284,
                    -447,
                    -871,
                    -1074,
                    -1068,
                    -1005,
                    -982,
                    -980,
                    -971,
                    -961,
                    -951,
                    -946,
                    -932,
                    -922,
                    -918,
                    -915,
                    -913,
                    -902,
                    -889,
                    -881,
                    -863,
                    -837,
                    -811,
                    -782,
                    -756,
                    -731,
                    -713,
                    -699,
                    -710,
                    -736,
                    -762,
                    -787,
                    -813,
                    -838,
                    -853,
                    -864,
                    -870,
                    -878,
                    -891,
                    -888,
                    -880,
                    -870,
                    -858,
                    -847,
                    -844,
                    -833,
                    -816,
                    -811,
                    -804,
                    -796,
                    -791,
                    -796,
                    -801,
                    -807,
                    -819,
                    -823,
                    -816,
                    -800,
                    -786,
                    -772,
                    -757,
                    -753,
                    -768,
                    -800,
                    -827,
                    -847,
                    -853,
                    -855,
                    -852,
                    -855,
                    -853,
                    -895,
                    -966,
                    -758,
                    -277,
                    -236,
                    -625,
                    -870,
                    -893,
                    -825,
                    -762,
                    -740,
                    -729,
                    -725,
                    -720,
                    -710,
                    -709,
                    -714,
                    -707,
                    -700,
                    -709,
                    -713,
                    -713,
                    -713,
                    -707,
                    -692,
                    -692,
                    -687,
                    -667,
                    -638,
                    -632,
                    -625,
                    -623,
                    -655,
                    -688,
                    -703,
                    -734,
                    -762,
                    -767,
                    -761,
                    -762,
                    -762,
                    -750,
                    -731,
                    -712,
                    -692,
                    -680,
                    -674,
                    -674,
                    -681,
                    -678,
                    -674,
                    -667,
                    -666,
                    -669,
                    -676,
                    -673,
                    -676,
                    -681,
                    -687,
                    -689,
                    -687,
                    -683,
                    -676,
                    -665,
                    -640,
                    -610,
                    -586,
                    -593,
                    -610,
                    -629,
                    -644,
                    -661,
                    -676,
                    -677,
                    -666,
                    -652,
                    -643,
                    -667,
                    -771,
                    -684,
                    -207,
                    37,
                    -333,
                    -713,
                    -790,
                    -746,
                    -694,
                    -673,
                    -669,
                    -661,
                    -652,
                    -650,
                    -633,
                    -610,
                    -601,
                    -612,
                    -621,
                    -623,
                    -621,
                    -612,
                    -611,
                    -604,
                    -587,
                    -567,
                    -554,
                    -541,
                    -534,
                    -530,
                    -535,
                    -548,
                    -576,
                    -610,
                    -632,
                    -656,
                    -656,
                    -650,
                    -654,
                    -654,
                    -650,
                    -645,
                    -654,
                    -669,
                    -676,
                    -673,
                    -673,
                    -670,
                    -677,
                    -674,
                    -670,
                    -670,
                    -665,
                    -656,
                    -645,
                    -638,
                    -640,
                    -638,
                    -661,
                    -666,
                    -623,
                    -600,
                    -586,
                    -561,
                    -528,
                    -531,
                    -564,
                    -592,
                    -610,
                    -636,
                    -650,
                    -652,
                    -656,
                    -665,
                    -674,
                    -747,
                    -798,
                    -481,
                    45,
                    -3,
                    -469,
                    -728,
                    -735,
                    -666,
                    -621,
                    -608,
                    -618,
                    -699,
                    -746,
                    -678,
                    -563,
                    -502,
                    -466,
                    -436,
                    -411,
                    -386,
                    -349,
                    -309,
                    -268,
                    -233,
                    -202,
                    -181,
                    -166,
                    -153,
                    -148,
                    -148,
                    -160,
                    -191,
                    -228,
                    -251,
                    -276,
                    -301,
                    -306,
                    -313,
                    -324,
                    -330,
                    -326,
                    -327,
                    -334,
                    -331,
                    -331,
                    -327,
                    -320,
                    -319,
                    -317,
                    -312,
                    -305,
                    -301,
                    -297,
                    -302,
                    -298,
                    -300,
                    -305,
                    -305,
                    -311,
                    -305,
                    -304,
                    -295,
                    -284,
                    -268,
                    -249,
                    -224,
                    -211,
                    -222,
                    -250,
                    -275,
                    -301,
                    -316,
                    -320,
                    -323,
                    -330,
                    -326,
                    -349,
                    -441,
                    -363,
                    121,
                    376,
                    4,
                    -359,
                    -452,
                    -421,
                    -373,
                    -357,
                    -362,
                    -364,
                    -360,
                    -346,
                    -339,
                    -338,
                    -333,
                    -334,
                    -344,
                    -339,
                    -328,
                    -324,
                    -313,
                    -306,
                    -304,
                    -300,
                    -290,
                    -286,
                    -272,
                    -261,
                    -271,
                    -297,
                    -339,
                    -379,
                    -406,
                    -428,
                    -448,
                    -458,
                    -468,
                    -484,
                    -494,
                    -503,
                    -514,
                    -524,
                    -527,
                    -528,
                    -528,
                    -534,
                    -537,
                    -535,
                    -538,
                    -545,
                    -552,
                    -553,
                    -552,
                    -550,
                    -554,
                    -563,
                    -575,
                    -576,
                    -572,
                    -579,
                    -571,
                    -549,
                    -519,
                    -501,
                    -499,
                    -512,
                    -553,
                    -587,
                    -599,
                    -618,
                    -633,
                    -640,
                    -647,
                    -652,
                    -706,
                    -787,
                    -543,
                    1,
                    46,
                    -418,
                    -738,
                    -790,
                    -731,
                    -688,
                    -689,
                    -688,
                    -685,
                    -688,
                    -685,
                    -681,
                    -676,
                    -666,
                    -652,
                    -647,
                    -648,
                    -640,
                    -625,
                    -626,
                    -622,
                    -596,
                    -572,
                    -553,
                    -538,
                    -517,
                    -505,
                    -514,
                    -539,
                    -560,
                    -575,
                    -582,
                    -572,
                    -563,
                    -565,
                    -565,
                    -561,
                    -546,
                    -539,
                    -549,
                    -554,
                    -550,
                    -550,
                    -546,
                    -539,
                    -539,
                    -539,
                    -538,
                    -530,
                    -524,
                    -531,
                    -537,
                    -538,
                    -541,
                    -550,
                    -549,
                    -541,
                    -525,
                    -498,
                    -475,
                    -475,
                    -498,
                    -524,
                    -549,
                    -567,
                    -576,
                    -576,
                    -574,
                    -576,
                    -582,
                    -650,
                    -695,
                    -360,
                    187,
                    114,
                    -375,
                    -647,
                    -688,
                    -637,
                    -597,
                    -590,
                    -581,
                    -575,
                    -571,
                    -570,
                    -571,
                    -564,
                    -560,
                    -556,
                    -552,
                    -548,
                    -549,
                    -545,
                    -537,
                    -528,
                    -516,
                    -505,
                    -495,
                    -487,
                    -484,
                    -543,
                    -567,
                    -572,
                    -605,
                    -597,
                    -605,
                    -614,
                    -614,
                    -618,
                    -623,
                    -621,
                    -621,
                    -622,
                    -614,
                    -603,
                    -597,
                    -585,
                    -571,
                    -561,
                    -560,
                    -552,
                    -537,
                    -523,
                    -516,
                    -510,
                    -510,
                    -503,
                    -491,
                    -481,
                    -468,
                    -450,
                    -428,
                    -399,
                    -373,
                    -363,
                    -368,
                    -396,
                    -426,
                    -451,
                    -463,
                    -470,
                    -476,
                    -476,
                    -468,
                    -501,
                    -600,
                    -466,
                    99,
                    351,
                    -65,
                    -458,
                    -548,
                    -495,
                    -441,
                    -428,
                    -428,
                    -418,
                    -407,
                    -400,
                    -397,
                    -393,
                    -392,
                    -381,
                    -368,
                    -357,
                    -352,
                    -341,
                    -324,
                    -306,
                    -283,
                    -262,
                    -239,
                    -211,
                    -181,
                    -160,
                    -159,
                    -170,
                    -192,
                    -206,
                    -214,
                    -226,
                    -232,
                    -232,
                    -221,
                    -211,
                    -206,
                    -198,
                    -188,
                    -181,
                    -173,
                    -166,
                    -153,
                    -144,
                    -136,
                    -127,
                    -125,
                    -126,
                    -122,
                    -113,
                    -111,
                    -107,
                    -113,
                    -115,
                    -109,
                    -111,
                    -111,
                    -97,
                    -72,
                    -42,
                    -32,
                    -43,
                    -67,
                    -101,
                    -130,
                    -142,
                    -144,
                    -148,
                    -147,
                    -145,
                    -184,
                    -280,
                    -152,
                    356,
                    551,
                    152,
                    -182,
                    -258,
                    -225,
                    -185,
                    -170,
                    -173,
                    -174,
                    -180,
                    -182,
                    -187,
                    -188,
                    -198,
                    -207,
                    -204,
                    -207,
                    -214,
                    -213,
                    -207,
                    -203,
                    -204,
                    -204,
                    -193,
                    -188,
                    -184,
                    -181,
                    -192,
                    -221,
                    -255,
                    -284,
                    -312,
                    -338,
                    -360,
                    -377,
                    -382,
                    -386,
                    -390,
                    -399,
                    -400,
                    -396,
                    -403,
                    -414,
                    -419,
                    -413,
                    -413,
                    -410,
                    -401,
                    -396,
                    -399,
                    -401,
                    -393,
                    -390,
                    -388,
                    -384,
                    -377,
                    -384,
                    -384,
                    -373,
                    -353,
                    -327,
                    -312,
                    -295,
                    -301,
                    -334,
                    -363,
                    -390,
                    -406,
                    -404,
                    -401,
                    -396,
                    -397,
                    -432,
                    -532,
                    -419,
                    125,
                    398,
                    -6,
                    -419,
                    -503,
                    -452,
                    -410,
                    -393,
                    -390,
                    -397,
                    -393,
                    -371,
                    -364,
                    -377,
                    -385,
                    -385,
                    -377,
                    -377,
                    -381,
                    -382,
                    -371,
                    -366,
                    -360,
                    -338,
                    -333,
                    -313,
                    -294,
                    -284,
                    -294,
                    -316,
                    -331,
                    -345,
                    -366,
                    -379,
                    -389,
                    -392,
                    -392,
                    -374,
                    -371,
                    -378,
                    -363,
                    -350,
                    -342,
                    -335,
                    -322,
                    -308,
                    -300,
                    -298,
                    -294,
                    -287,
                    -282,
                    -272,
                    -266,
                    -255,
                    -253,
                    -246,
                    -229,
                    -200,
                    -170,
                    -144,
                    -109,
                    -72,
                    -38,
                    -23,
                    -25,
                    -39,
                    -57,
                    -67,
                    -68,
                    -71,
                    -79,
                    -87,
                    -102,
                    -191,
                    -273,
                    37,
                    575,
                    491,
                    -17,
                    -298,
                    -334,
                    -287,
                    -266,
                    -298,
                    -283,
                    -249,
                    -239,
                    -242,
                    -253,
                    -261,
                    -265,
                    -268,
                    -279,
                    -283,
                    -286,
                    -291,
                    -293,
                    -288,
                    -287,
                    -279,
                    -271,
                    -265,
                    -261,
                    -265,
                    -280,
                    -304,
                    -339,
                    -367,
                    -382,
                    -411,
                    -428,
                    -433,
                    -433,
                    -436,
                    -446,
                    -450,
                    -447,
                    -452,
                    -452,
                    -443,
                    -435,
                    -426,
                    -425,
                    -425,
                    -417,
                    -413,
                    -411,
                    -413,
                    -417,
                    -418,
                    -421,
                    -424,
                    -419,
                    -414,
                    -419,
                    -413,
                    -389,
                    -360,
                    -327,
                    -317,
                    -320,
                    -335,
                    -363,
                    -385,
                    -393,
                    -397,
                    -395,
                    -396,
                    -396,
                    -422,
                    -519,
                    -455,
                    23,
                    338,
                    12,
                    -374,
                    -567,
                    -629,
                    -658,
                    -764,
                    -827,
                    -833,
                    -818,
                    -790,
                    -801,
                    -853,
                    -869,
                    -841,
                    -780,
                    -676,
                    -578,
                    -538,
                    -527,
                    -516,
                    -501,
                    -486,
                    -475,
                    -462,
                    -447,
                    -436,
                    -436,
                    -444,
                    -469,
                    -488,
                    -503,
                    -523,
                    -537,
                    -539,
                    -548,
                    -554,
                    -546,
                    -461,
                    -353,
                    -320,
                    -312,
                    -305,
                    -305,
                    -295,
                    -286,
                    -287,
                    -288,
                    -283,
                    -284,
                    -280,
                    -271,
                    -261,
                    -258,
                    -262,
                    -255,
                    -253,
                    -257,
                    -247,
                    -238,
                    -221,
                    -200,
                    -184,
                    -178,
                    -191,
                    -224,
                    -264,
                    -295,
                    -313,
                    -323,
                    -330,
                    -345,
                    -362,
                    -407,
                    -519,
                    -432,
                    48,
                    257,
                    -113,
                    -459,
                    -542,
                    -501,
                    -461,
                    -450,
                    -451,
                    -452,
                    -446,
                    -436,
                    -428,
                    -424,
                    -419,
                    -413,
                    -410,
                    -406,
                    -396,
                    -385,
                    -371,
                    -362,
                    -355,
                    -344,
                    -330,
                    -311,
                    -295,
                    -284,
                    -287,
                    -295,
                    -315,
                    -330,
                    -348,
                    -362,
                    -373,
                    -381,
                    -386,
                    -393,
                    -404,
                    -410,
                    -413,
                    -415,
                    -419,
                    -422,
                    -430,
                    -430,
                    -429,
                    -437,
                    -444,
                    -451,
                    -458,
                    -461,
                    -469,
                    -480,
                    -484,
                    -492,
                    -494,
                    -497,
                    -499,
                    -495,
                    -484,
                    -465,
                    -436,
                    -422,
                    -430,
                    -458,
                    -492,
                    -525,
                    -548,
                    -564,
                    -572,
                    -578,
                    -581,
                    -600,
                    -705
                ]
            },
            "classification": "Afib",
            "sampleRate": 125,
            "triggerTime": "2022-07-06T04:36:06.000Z"
        }
    ]
}
