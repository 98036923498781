import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {getUrlToDownload, uploadFile} from "../../utils/firebase";
import AppContext from "../Context/Context";
import {EdfDecoder} from "../../EDF/EdfDecoder";
import {createFileEntry, getAllFileEntries} from "../../utils/axios";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "react-bootstrap";
import ModalContext from "react-bootstrap/ModalContext";
import { useGlobalState } from "../Context/GlobalStateContext";
type PatientProps = {
  setPatientName: Function;
  setSelectedView: Function;
  setFileId: Function;
  setDataSetForSelectedPatient: Function;
  DownloadAndReadEDF: any;
};
function isAtLeastTwoMinutesOlder(date: Date) {
  const currentDate = new Date();
  const twoMinutesAgo = new Date(currentDate.getTime() - 2 * 60 * 1000); // 2 minutes ago
  return date < twoMinutesAgo;
}
const dData = [
  {
    "name": "Anders Horton",
    "studyId": "8889103B",
    "deviceId": 650866,
    "studyStartTime": "2022-07-26T03:42:07.171Z",
    "age": 52,
    "gender": "M",
    "clinicName": "123 Hospital",
    "physicianName": "Dr. Johnson"
  },
  {
    "name": "Turner Hicks",
    "studyId": "2410100C",
    "deviceId": 993482,
    "studyStartTime": "2022-11-02T13:32:09.990Z",
    "age": 67,
    "gender": "F",
    "clinicName": "XYZ Medical Center",
    "physicianName": "Dr. Lee"
  },
  {
    "name": "Walker Jacobs",
    "studyId": "65595876",
    "deviceId": 648886,
    "studyStartTime": "2022-06-27T23:57:37.678Z",
    "age": 20,
    "gender": "F",
    "clinicName": "XYZ Medical Center",
    "physicianName": "Dr. Smith"
  },
  {
    "name": "Cyrus Simon",
    "studyId": "4927370F",
    "deviceId": 675585,
    "studyStartTime": "2022-04-23T03:48:17.252Z",
    "age": 40,
    "gender": "M",
    "clinicName": "123 Hospital",
    "physicianName": "Dr. Smith"
  },
  {
    "name": "Marcus Lang",
    "studyId": "666932YA",
    "deviceId": 909732,
    "studyStartTime": "2022-12-20T18:34:30.650Z",
    "age": 29,
    "gender": "F",
    "clinicName": "ABC Clinic",
    "physicianName": "Dr. Brown"
  },
  {
    "name": "Nova Cox",
    "studyId": "304424DB",
    "deviceId": 285278,
    "studyStartTime": "2022-06-20T18:35:11.029Z",
    "age": 64,
    "gender": "M",
    "clinicName": "Medical Associates",
    "physicianName": "Dr. Smith"
  },
  {
    "name": "Patient 7",
    "studyId": "360367IE",
    "deviceId": 978050,
    "studyStartTime": "2022-05-17T12:42:13.693Z",
    "age": 47,
    "gender": "F",
    "clinicName": "ABC Clinic",
    "physicianName": "Dr. Lee"
  },
  {
    "name": "Patient 8",
    "studyId": "4354229G",
    "deviceId": 929217,
    "studyStartTime": "2022-10-14T20:49:41.495Z",
    "age": 20,
    "gender": "F",
    "clinicName": "Medical Associates",
    "physicianName": "Dr. Smith"
  },
  {
    "name": "Patient 9",
    "studyId": "586209OH",
    "deviceId": 944986,
    "studyStartTime": "2022-10-11T23:01:13.687Z",
    "age": 63,
    "gender": "M",
    "clinicName": "XYZ Medical Center",
    "physicianName": "Dr. Johnson"
  },
  {
    "name": "Patient 10",
    "studyId": "533225EH",
    "deviceId": 479158,
    "studyStartTime": "2022-06-19T19:08:48.174Z",
    "age": 49,
    "gender": "F",
    "clinicName": "XYZ Medical Center",
    "physicianName": "Dr. Brown"
  }
]


// @ts-ignore
const FileUploadButton = ({ files, setFiles, toggleIsUploading, addLoadingText, showModal }) => {
  const inpRef = useRef()
  // @ts-ignore
  const handleUpload =  async (event) => {
    const file = event.target.files[0];
    if (file) {
      showModal()
      const uploadTime = new Date().toISOString();

      // Retrieve existing uploaded files from localStorage
      // @ts-ignore
      const uploadedFiles = [...files];

      // Add the new file info to the array
      // uploadedFiles.push(fileInfo);
      // setFiles(uploadedFiles)

      // Store the updated array back to localStorage
      localStorage.setItem('uploadedFiles', JSON.stringify(uploadedFiles));
      addLoadingText(`Found file, ${file.name}`)
      const key = `${
          file.name
      }-${Math.random()
          .toString()
          .substring(3, 10)}.${
          (
              file.type || "text/edf"
          ).split("/")[1]
      }`;
      console.log({ key })
      toggleIsUploading()
      addLoadingText(`Uploading the file to server`)
      const ref = await uploadFile(
          key,
          file,
          file.type
      );
      addLoadingText(`Retrieving information`)
      // @ts-ignore
      const edfUrl = await getUrlToDownload(ref?.ref?.fullPath)
      const reader = new FileReader();
      reader.onload = async function() {
        const arrayBuffer = this.result
            const array = new Uint8Array(arrayBuffer as ArrayBuffer)
        const decoder: any = new EdfDecoder();
        decoder.setInput(array.buffer);
        decoder.decode();
        const output = decoder.getOutput();
        const totalMinutes = (output.getNumberOfRecords()/output.getSignalNumberOfSamplesPerRecord(0))
        const studyHours = (totalMinutes/60).toFixed(2)
        addLoadingText(`File ecg records: ${output.getNumberOfRecords()}`)
        addLoadingText(`File sampleRate: ${output.getSignalNumberOfSamplesPerRecord(0)}`)
        addLoadingText(`File study hours: ${studyHours}`)

        const cont = output._physicalSignals[0]
        addLoadingText(`Shaping the ecg values to queue`)
        const phyFile = new File([new Blob([JSON.stringify(cont)], {
          type: 'text/json'
        })], 'sup.text', {
          type: 'text/json'
        })
        addLoadingText(`Queueing the first physical signal records`)
        const textRef = await uploadFile(
            `physicalSignal__${key}.json`,
            phyFile,
            'text/jsons'
        );
        // @ts-ignore
        const signalValuesFilesUrl = await getUrlToDownload(textRef?.ref?.fullPath)
        const res = await createFileEntry({
          name: file.name,
          edfFileUrl: edfUrl,
          channelDataUrl: signalValuesFilesUrl,
          sampleRate: output.getSignalNumberOfSamplesPerRecord(0),
          studyHours,
        })
        // @ts-ignore
        setFiles(_f => [..._f, res.data.fileEntry] as typeof _f)
        addLoadingText(`Queued. ETA to process complete: ~${((totalMinutes * 9)/60).toFixed(0)} minutes`)
        toggleIsUploading()

      }
      reader.readAsArrayBuffer(file);

    }
  };

  return (
      <label className="upload-button">
        {/* @ts-ignore */}
        <Button className={'btn-warning'} onClick={() => inpRef?.current?.click()} color={'warning'}>Upload
          {/* @ts-ignore */}
          <input ref={r => inpRef.current = r} type="file" accept={'.edf'} style={{ display: 'none' }} onChange={handleUpload} />
        </Button>
      </label>
  );
};
function Patient(props: PatientProps) {
  const navigate = useNavigate();
  const globalState = useGlobalState();
  const [files, setFiles] = useState<any[]>([])
  const [isUploading, setIsUploading] = useState(false)
  const [loadingStatements, setLoadingStatements] = useState<string[]>([])
  const [showModal, setShowModal] = useState(false)
  const toggleIsUploading = () => setIsUploading(_ => !_)

  const addLoadingText = (txt: string) => setLoadingStatements(t => [...t, txt])

  useEffect(() => {
    props.setSelectedView(AppContext.view.Patient);
    // @ts-ignore
    loadFiles()
  }, []);

  const loadFiles = async () => {
    const res = await getAllFileEntries()
    // @ts-ignore
    const uploadedFiles = res.data.fileEntries
    setFiles(uploadedFiles)
  }

  useEffect(() => {
    const t = setInterval(() => {
      loadFiles()
    }, 5000)
    return () => clearInterval(t)
  }, [])


  function redirectToECG(patientName: string, PatientDatasetType: number, clickedIndex: number) {
    props.setPatientName(patientName);
    props.setFileId(files[clickedIndex])
    globalState.setFileId(files[clickedIndex]["_id"]);
    globalState.setFileEntryObject(files[clickedIndex])
    props.setSelectedView(AppContext.view.Analysis);
    props.setDataSetForSelectedPatient(PatientDatasetType);
    navigate("/ecg");
    props.DownloadAndReadEDF('');
  }

  return (
    <>
      <Modal show={showModal} centered>
        <ModalHeader>
          <div className={'p-7'}>
          <h2 className={'font-semibold text-md'}>{isUploading ? 'Your file is being processed': 'The file is in queue!'}</h2>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className={'p-4 flex-col gap-4 max-w-7xl'}>
          {
            loadingStatements.map(statement => <div className={'mt-'}>

              <p>{statement} </p>
              </div>)
          }
        </div>
        </ModalBody>
        <ModalFooter>
          {isUploading ? <Spinner /> : <Button className={'btn-secondary'} onClick={() => {
            setLoadingStatements([])
            setShowModal(false)
          }}>Close</Button>}
        </ModalFooter>

      </Modal>
      {/* <a
        title="Show/Hide Bookmarks Panel"
        className="btn--left__menu pbtn--left__menu "
      >
        <img src="/images/m1.svg" width="26px" height="18px" alt="cross" />
      </a> */}

      <div className="ecg--main patient--main">
        <div className="ecg--left custom--scroll">
          <a className="menu--link">
            <img
              src="/images/patient.png"
              width="32px"
              height="32px"
              alt="review"
            />
          </a>
          {/*<a*/}
          {/*  className="menu--link"*/}
          {/*  onClick={() => redirectToECG("Arlene Wilson", 2, )}*/}
          {/*>*/}
          {/*  <img*/}
          {/*    src="/images/reading.png"*/}
          {/*    width="32px"*/}
          {/*    height="32px"*/}
          {/*    alt="review"*/}
          {/*  />*/}
          {/*</a>*/}
        </div>
        <div className="ecg--right ecg--right--home">
          <div className="patient-head">
            <h1>Study List</h1>
            <FileUploadButton showModal={() => setShowModal(true)} addLoadingText={addLoadingText} toggleIsUploading={toggleIsUploading} files={files} setFiles={setFiles} />
          </div>
          <div className="patient--list">
            <table className="patient--table">
              <thead>
                <tr>
                  <th>File Status</th>
                  <th>Progress</th>
                  <th>File Name</th>
                  <th>Uploaded At</th>
                <th>Entry Id</th>
                  <th>Study Type</th>
                  {/*<th>*/}
                  {/*  Study Start Date & Time{" "}*/}
                  {/*</th>*/}
                  <th>
                    Study Duration{" "}
                  </th>
                </tr>
              </thead>
              <tbody>
              {
                files.length ? files.map((file, i) => {

                  return( <tr onClick={() => redirectToECG(file._id, 2, i)}>
                    <td>
                      {file.status}
                    </td>
                    <td>
                      {file.progressDone >= 1 ?<svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                            d="M8.50004 9.66671L12.4248 12.6103C12.8433 12.9241 13.4329 12.8625 13.7774 12.4688L21.3334 3.83337"
                            stroke="#222222"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        ></path>
                        <path
                            d="M22.5 12C22.5 14.1939 21.8128 16.3327 20.5349 18.116C19.257 19.8993 17.4526 21.2376 15.3751 21.9428C13.2976 22.648 11.0514 22.6847 8.95201 22.0479C6.85257 21.411 5.00536 20.1325 3.66979 18.392C2.33422 16.6515 1.5774 14.5363 1.50562 12.3436C1.43384 10.1508 2.0507 7.99068 3.26957 6.16651C4.48844 4.34235 6.24808 2.94579 8.30137 2.17299C10.3547 1.40018 12.5984 1.28995 14.7176 1.85778"
                            stroke="#222222"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        ></path>
                      </svg>: (file.progressDone * 100).toFixed(2) + '%'}
                    </td>
                    <td>{file.name}</td>
                    <td>{new Date(file.createdAt).toLocaleString()}</td>
                    <td>{file._id}</td>
                    <td>holter</td>
                    <td>{file.studyHours} hours</td>
                  </tr>)
                }) : <>No files found</>
              }
                {/*<tr onClick={() => redirectToECG("Arlene Wilson", 2)}>*/}
                {/*  <td>*/}
                {/*    <span className="dot-green"></span>*/}
                {/*  </td>*/}
                {/*  <td>*/}
                {/*    <svg*/}
                {/*      width="24"*/}
                {/*      height="24"*/}
                {/*      viewBox="0 0 24 24"*/}
                {/*      fill="none"*/}
                {/*      xmlns="http://www.w3.org/2000/svg"*/}
                {/*    >*/}
                {/*      <path*/}
                {/*        d="M8.50004 9.66671L12.4248 12.6103C12.8433 12.9241 13.4329 12.8625 13.7774 12.4688L21.3334 3.83337"*/}
                {/*        stroke="#222222"*/}
                {/*        strokeWidth="1.5"*/}
                {/*        strokeLinecap="round"*/}
                {/*      ></path>*/}
                {/*      <path*/}
                {/*        d="M22.5 12C22.5 14.1939 21.8128 16.3327 20.5349 18.116C19.257 19.8993 17.4526 21.2376 15.3751 21.9428C13.2976 22.648 11.0514 22.6847 8.95201 22.0479C6.85257 21.411 5.00536 20.1325 3.66979 18.392C2.33422 16.6515 1.5774 14.5363 1.50562 12.3436C1.43384 10.1508 2.0507 7.99068 3.26957 6.16651C4.48844 4.34235 6.24808 2.94579 8.30137 2.17299C10.3547 1.40018 12.5984 1.28995 14.7176 1.85778"*/}
                {/*        stroke="#222222"*/}
                {/*        strokeWidth="1.5"*/}
                {/*        strokeLinecap="round"*/}
                {/*      ></path>*/}
                {/*    </svg>*/}
                {/*  </td>*/}
                {/*  <td>HIT2931</td>*/}
                {/*  <td>Arlene Wilson</td>*/}
                {/*  <td>64274-278</td>*/}
                {/*  <td>43829</td>*/}
                {/*  <td>Holter</td>*/}
                {/*  <td>11/12/2022</td>*/}
                {/*  <td>12 hours</td>*/}
                {/*  <td>TI</td>*/}
                {/*  <td>Report Normal</td>*/}
                {/*  <td>38</td>*/}
                {/*  <td>F</td>*/}
                {/*  <td>Cleveland Clinic</td>*/}
                {/*  <td>Dr.Park Ave</td>*/}
                {/*  <td>-</td>*/}
                {/*  <td>-</td>*/}
                {/*</tr>*/}
                {/*<tr onClick={() => redirectToECG("Chris Johnson", 3)}>*/}
                {/*  <td>*/}
                {/*    <span className="dot-yellow"></span>*/}
                {/*  </td>*/}
                {/*  <td>*/}
                {/*    <svg*/}
                {/*      width="24"*/}
                {/*      height="24"*/}
                {/*      viewBox="0 0 24 24"*/}
                {/*      fill="none"*/}
                {/*      xmlns="http://www.w3.org/2000/svg"*/}
                {/*    >*/}
                {/*      <path*/}
                {/*        d="M8.50004 9.66671L12.4248 12.6103C12.8433 12.9241 13.4329 12.8625 13.7774 12.4688L21.3334 3.83337"*/}
                {/*        stroke="#222222"*/}
                {/*        strokeWidth="1.5"*/}
                {/*        strokeLinecap="round"*/}
                {/*      ></path>*/}
                {/*      <path*/}
                {/*        d="M22.5 12C22.5 14.1939 21.8128 16.3327 20.5349 18.116C19.257 19.8993 17.4526 21.2376 15.3751 21.9428C13.2976 22.648 11.0514 22.6847 8.95201 22.0479C6.85257 21.411 5.00536 20.1325 3.66979 18.392C2.33422 16.6515 1.5774 14.5363 1.50562 12.3436C1.43384 10.1508 2.0507 7.99068 3.26957 6.16651C4.48844 4.34235 6.24808 2.94579 8.30137 2.17299C10.3547 1.40018 12.5984 1.28995 14.7176 1.85778"*/}
                {/*        stroke="#222222"*/}
                {/*        strokeWidth="1.5"*/}
                {/*        strokeLinecap="round"*/}
                {/*      ></path>*/}
                {/*    </svg>*/}
                {/*  </td>*/}
                {/*  <td>HIT3945</td>*/}
                {/*  <td>Chris Johnson</td>*/}
                {/*  <td>42859-284</td>*/}
                {/*  <td>28392</td>*/}
                {/*  <td>Holter</td>*/}
                {/*  <td>05/02/2023</td>*/}
                {/*  <td> 18 hours</td>*/}
                {/*  <td> TI</td>*/}
                {/*  <td>Report PVC</td>*/}
                {/*  <td>45</td>*/}
                {/*  <td>M</td>*/}
                {/*  <td>Health Rogh Clinic</td>*/}
                {/*  <td>Dr.Amanda Johnson</td>*/}
                {/*  <td>-</td>*/}
                {/*  <td>-</td>*/}
                {/*</tr>*/}
              </tbody>
            </table>
            <div className="pagination--ecg">
              {/*<div className="pagination--left">*/}
              {/*  <span className="arrow-left">&lsaquo;</span>*/}
              {/*  <span className="page-number">1</span>*/}
              {/*  <span className="arrow-right">&lsaquo;</span>*/}
              {/*  <span className="entries">Showing 1 to 2 of 2 entries</span>*/}
              {/*</div>*/}
              {/*<div className="pagination--right">*/}
              {/*  <span>Rows Per Page</span>*/}
              {/*  <select>*/}
              {/*    <option>10</option>*/}
              {/*  </select>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>
      {/* Patient Page
      <br />
      <Link to={"/ecg"}>Go to ECG</Link> */}
    </>
  );
}

export default Patient;
