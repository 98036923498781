export const heartRateData = [{
        label: 'Total Beats',
        value: '92504',
    },
    {
        label: 'Avg. HR',
        value: '54 bpm',
    },
    {
        label: 'Min. HR',
        value: '68 bpm',
    },
    {
        label: 'Max. HR',
        value: '98 bpm',
    },
    {
        label: 'Tachycardia',
        value: '3time(2.09%)',
    },
    {
        label: 'Bardycardia',
        value: '--',
    },
    {
        label: 'Long RR',
        value: '--',
    },
    {
        label: 'Longest Long RR',
        value: '--',
    },
    {
        label: 'Daytime Average',
        value: '56 bpm @05:33:21',
    },
    {
        label: 'Nighttime Average',
        value: '98 bpm @21:34:09',
    },
]

export const othersData = [{
        label: 'Total Beats',
        value: '121534',
    },
    {
        label: 'Avg. HR',
        value: '86 bpm',
    },
    {
        label: 'Min. HR',
        value: '82 bpm',
    },
]