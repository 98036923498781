import Box from "../Box";
//import PatientReportLayout from './../PatientReportLayout'
import MCTSummaryReport from "./ReportsType/MCTSummaryReport";

export default function ReportsPage2() {
  return (
    //<PatientReportLayout>
    <>
      <TechnicianComments />
      <MCTSummaryReport />
    </>
    // </PatientReportLayout>
  );
}

function TechnicianComments() {
  return (
    <Box width="w-full" pb="pt-8" pt="0" px="0">
      <h2 className="  flex  items-center text-xl font-bold px-[74px]  ">
        Technician Comments
      </h2>
      <div className=" px-[74px] pt-6 pb-16 text-[#616161]  ">
          <p> 1. Atrial Fibrillation is noted.</p>
          <p> 2. Predominant rhythm was sinus.</p>
          <p> 3. Min HR of 68 bpm Max HR of 98 bpm and Avg HR of 54 bpm.</p>
          <p> 4. Supraventricular ectopics were noted.</p>
          <p> 5. Ventricular ectopics were noted.</p>
        <div className="mt-8"> KM.CCT/MCT</div>
      </div>
    </Box>
  );
}
