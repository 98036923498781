import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { PatientDataProvider } from "./components/PatientReportPage/PatientDemographicsPage/context/PatientDataContext";
import { PhysicianDataProvider } from "./components/PatientReportPage/PatientDemographicsPage/context/PhysicianDataContext";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  //<React.StrictMode>
  <BrowserRouter>
    <PatientDataProvider>
      <PhysicianDataProvider>
        <App />
      </PhysicianDataProvider>
    </PatientDataProvider>
  </BrowserRouter>
  // </React.StrictMode>
);
