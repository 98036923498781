import Divider from '../../components/Divider'
import ReportHeader from '../../components/ReportHeader'

export default function MCT_DocumentedStrips() {
  return (
    <div className="min-h-screen">
      <ReportHeader
        boxInfo="Documented strips "
        startDate=" 01/01/2022 "
        patientInfo="Robert Downey/M/12/02/1985    "
        boxInfoBg="#FDE9E9"
      />
      <Divider bgColor="bg-[#A83232]" className="mb-[125px]" />
      <Table />
    </div>
  )
}

function Table() {
  const tableHeadings = ['Date', 'Time', 'Label', 'Rate', 'Page No']
  const data = [
    {
      date: '01/04/2020',
      time: 'Pm',
      label: 'Arrhythmia',
      rate: 'PM',
      pageNo: 19,
    },
    {
      date: '01/05/2020',
      time: 'Am',
      label: 'raventricular Bigeminy',
      rate: 'PM',
      pageNo: 19,
    },
    {
      date: '01/05/2020',
      time: 'Pm',
      label: 'First Degree Av Block',
      rate: 'PM',
      pageNo: 19,
    },
    {
      date: '01/05/2020',
      time: 'Pm',
      label: 'First Degree Av Block',
      rate: 'PM',
      pageNo: 19,
    },
    {
      date: '01/05/2020',
      time: 'Pm',
      label: 'First Degree Av Block',
      rate: 'PM',
      pageNo: 19,
    },
    {
      date: '01/05/2020',
      time: 'Pm',
      label: 'First Degree Av Block',
      rate: 'PM',
      pageNo: 19,
    },
  ]
  return (
    <div className="rounded-xl 2xl:max-w-[90%] mx-auto min-h-[400px] border-[1px] border-[#9CA1AA] text-xl overflow-hidden pb-[54px]">
      <div className="grid grid-cols-5      place-content-evenly w-full  px-20 -pl-8   mx-auto   h-20 border-b-[#9CA1AA] border-[1px] mb-8   ">
        {tableHeadings.map((data) => (
          <th>{data}</th>
        ))}
      </div>
      <dvi className="flex flex-col gap-9 pb-  text-[#414042] ">
        {data.map((data) => (
          <div className="grid grid-cols-5      place-content-evenly w-full pl-40   mx-auto    ">
            <td>{data.date}</td>
            <td>{data.time}</td>
            <td>{data.label}</td>
            <td>{data.rate}</td>
            <td>{data.pageNo}</td>
          </div>
        ))}
      </dvi>
    </div>
  )
}
