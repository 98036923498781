import React, { useState, useRef, useEffect } from "react";
import AppContext from "../../Context/Context";
import { ECGClassification } from "../../Shared/Utility";

type LeftMenuProps = {
  allAnnotations: any;
  summary: any
  allMeasurements: any;
  allEctopies: any;
  ecgTemplates: any;
  setAllMeasurements: Function;
  ViewAnnotation: Function;
  ViewMeasurement: Function;
  ShowAnnotationsDetail: Function;
  minBPM: number;
  maxBPM: number;
  selectedView: any;
  isEventClicked: any;
  setSelectedView: Function;
  setLeftMenuEventSelected: Function;
  leftMenuDataObject: {
    Totalbeats: number;
    MaxHr: number;
    MinHr: number;
    Afib: number;
    Normal: number;
    SinusRhythm: number;
    SinusBradycardia: number;
    SinusTachycardia: number;
    SinusArrhythmia: number;
    SVE: number;
    IsolatedSVE: number;
    SVEPair: number;
    SVERun: number;
    SVERunLongest: number;
    SVEBigeminy: number;
    SVETrigeminy: number;
    SVEQuadrigeminy: number;
    VE: number;
    IsolatedVE: number;
    VEPair: number;
    VERun: number;
    VERunLongest: number;
    VEBigeminy: number;
    VETrigeminy: number;
    VEQuadrigeminy: number;
    Pause: number;
    AVBlocks: number;
    PacedRhythm: number;
    Noise: number;
  };
  setLeftMenuType: Function;
};

function LeftMenu(props: LeftMenuProps) {
  const AnnotationTagType = {
    AF: 1,
    Pause: 2,
    Noise: 3,
    AVB: 4,
    AT: 5,
    SVT: 6,
    VE: 7,
    SVE: 8,
    Normal: 9,
  };

  useEffect(() => {
    if (props.isEventClicked === true) {
      setShowHideHeartRate(true);
    }
  }, [props.isEventClicked]);

  function GetEctopiesCount(p_intType: number) {
    var l_count = 0;
    for (let i = 0; i < props.allEctopies.length; i++) {
      if (props.allEctopies[i].VType === p_intType) {
        l_count++;
      }
    }
    return l_count;
  }

  function ReturnAnnotationsCount(p_intType: any) {
    let l_intCount = 0;
    for (let i = 0; i < props.allAnnotations.length; i++) {
      if (props.allAnnotations[i].Type === p_intType) {
        l_intCount++;
      }
    }
    return l_intCount;
  }

  function ShowDeleteOrClearAllDivMeasurement(p_intID: any) {
    for (let i = 0; i < props.allMeasurements.length; i++) {
      if (p_intID === props.allMeasurements[i].ID) {
        if (props.allMeasurements[i].DeleteOrClearAll) {
          props.allMeasurements[i].DeleteOrClearAll = false;
        } else {
          props.allMeasurements[i].DeleteOrClearAll = true;
        }
      } else {
        props.allMeasurements[i].DeleteOrClearAll = false;
      }
    }
    const MeasurementsList = [...props.allMeasurements];
    props.setAllMeasurements(MeasurementsList);
  }

  function DeleteMeasurement(p_intID: any) {
    for (let i = 0; i < props.allMeasurements.length; i++) {
      if (p_intID === props.allMeasurements[i].ID) {
        props.allMeasurements.splice(i, 1);
      }
    }
    const MeasurementsList = [...props.allMeasurements];
    props.setAllMeasurements(MeasurementsList);
  }

  function ClearAllMeasurement() {
    props.allMeasurements.splice(0, props.allMeasurements.length);
    const MeasurementsList = [...props.allMeasurements];
    props.setAllMeasurements(MeasurementsList);
  }

  const [showHideHeartRate, setShowHideHeartRate] = useState(false);
  const [showHideAfib, setShowHideAfib] = useState(false);
  const [showHideNormal, setShowHideNormal] = useState(false);
  const [showHideSVE, setShowHideSVE] = useState(false);
  const [showHideVE, setShowHideVE] = useState(false);
  const [showHidePause, setShowHidePause] = useState(false);
  const [showHideAVBlocks, setShowHideAVBlocks] = useState(false);
  const [showHideAVPacedRhythm, setShowHidePacedRhythm] = useState(false);
  const [showHideNoise, setShowHideNoise] = useState(false);

  return (
    <div className="ecg--left custom--scroll">
      <a>
        <span className="a--txt">Total Beats</span>
        <span className="a--count">{props.summary?.classification?.N + props.summary?.classification?.Afib}</span>
      </a>
      <div className="ecg--left__measure">Events List</div>

      <div className="accordion-main">
        <div
          onClick={() => {
            setShowHideHeartRate(!showHideHeartRate);
          }}
          className={`name-count ${
            showHideHeartRate === true ? "selected" : ""
          }`}
        >
          <span className="h-l-1 font-400">Heart rate</span>
          <span className="h-l-2"></span>
        </div>
        {showHideHeartRate && (
          <ul className="color-6">
            <li
              onClick={() => {
                props.setSelectedView(AppContext.view.Events);
                props.setLeftMenuEventSelected(1);
              }}
            >
              Max Heart rate <span>{props.leftMenuDataObject.MaxHr}</span>
            </li>
            <li
              onClick={() => {
                props.setSelectedView(AppContext.view.Events);
                props.setLeftMenuEventSelected(2);
              }}
            >
              Min Heart rate<span>{props.leftMenuDataObject.MinHr}</span>
            </li>
          </ul>
        )}
      </div>

      <div className="accordion-main">
        <div
          onClick={() => {
            setShowHideAfib(!showHideAfib);
            props.ShowAnnotationsDetail(AnnotationTagType.AF);
          }}
          className={`name-count color-1 ${
            showHideAfib === true ? "selected" : ""
          }`}
        >
          <span className="h-l-1">A flutter/A fib</span>
          <span>{props.summary?.classification?.Afib}</span>
        </div>
        {showHideAfib && (
          <ul className="color-1">
            {/* <li onClick={() => {
              props.setSelectedView(AppContext.view.Events);props.setLeftMenuEventSelected(3)
            }}>
              Afib – Longest<span></span>
            </li>
            <li onClick={() => {
              props.setSelectedView(AppContext.view.Events);props.setLeftMenuEventSelected(4)
            }}>
              Afib – Fastest<span></span>
            </li> */}
            <li
              onClick={() => {
                props.setSelectedView(AppContext.view.Events);
                props.setLeftMenuEventSelected(6);
                props.setLeftMenuType(ECGClassification.Atrial_Fibrillation);
              }}
            >
              Afib <span>{props.summary?.classification?.Afib}</span>
            </li>
          </ul>
        )}
      </div>

      <div className="accordion-main">
        <div
          onClick={() => {
            setShowHideNormal(!showHideNormal);
          }}
          className={`name-count color-2 ${
            showHideNormal === true ? "selected" : ""
          }`}
        >
          <span className="h-l-1">Normal</span>
          <span className="h-l-2">{props.summary?.classification?.N}</span>
        </div>
        {showHideNormal && (
          <ul className="color-2">
            <li
              onClick={() => {
                props.setSelectedView(AppContext.view.Events);
                props.setLeftMenuEventSelected(7);
                props.setLeftMenuType(ECGClassification.Sinus_Rhythm);
              }}
            >
              Sinus rhythm <span>{props.summary?.subClassification['Sinus rhythm']}</span>
            </li>
            <li
              onClick={() => {
                props.setSelectedView(AppContext.view.Events);
                props.setLeftMenuEventSelected(8);
                props.setLeftMenuType(ECGClassification.Sinus_bradycardia);
              }}
            >
              Sinus Bradycardia
              <span>{props.summary?.subClassification['Sinus bradycardia']}</span>
            </li>
            <li
              onClick={() => {
                props.setSelectedView(AppContext.view.Events);
                props.setLeftMenuEventSelected(9);
                props.setLeftMenuType(ECGClassification.Sinus_Tachycardia);
              }}
            >
              Sinus Tachycardia
              <span>{props.summary?.subClassification['Sinus tachycardia']}</span>
            </li>
            <li>
              Sinus arrhythmia
              <span>{props.summary?.subClassification['Sinus arrhythmia']}</span>
            </li>
          </ul>
        )}
      </div>

      {/*<div className="accordion-main">*/}
      {/*  <div*/}
      {/*    onClick={() => {*/}
      {/*      setShowHideSVE(!showHideSVE);*/}
      {/*      props.ShowAnnotationsDetail(AnnotationTagType.SVE);*/}
      {/*    }}*/}
      {/*    className={`name-count color-3 ${*/}
      {/*      showHideSVE === true ? "selected" : ""*/}
      {/*    }`}*/}
      {/*  >*/}
      {/*    <span className="h-l-1">SVE</span>*/}
      {/*    <span className="h-l-2">{props.leftMenuDataObject.SVE}</span>*/}
      {/*  </div>*/}
      {/*  {showHideSVE && (*/}
      {/*    <ul className="color-3">*/}
      {/*      <li*/}
      {/*        onClick={() => {*/}
      {/*          props.setSelectedView(AppContext.view.Events);*/}
      {/*          props.setLeftMenuEventSelected(10);*/}
      {/*          props.setLeftMenuType(ECGClassification.Supraventricular_Tachycardia_SVT);*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        Isolated SVE<span>{props.leftMenuDataObject.IsolatedSVE}</span>*/}
      {/*      </li>*/}
      {/*      <li>*/}
      {/*        SVE Pair<span>{props.leftMenuDataObject.SVEPair}</span>*/}
      {/*      </li>*/}
      {/*      <li>*/}
      {/*        SVE Run<span>{props.leftMenuDataObject.SVERun}</span>*/}
      {/*      </li>*/}
      {/*      <li>*/}
      {/*        SVE Run Longest*/}
      {/*        <span>{props.leftMenuDataObject.SVERunLongest}</span>*/}
      {/*      </li>*/}
      {/*      <li>*/}
      {/*        SVE Run fastest<span></span>*/}
      {/*      </li>*/}
      {/*      <li>*/}
      {/*        SVE – Bigeminy<span>{props.leftMenuDataObject.SVEBigeminy}</span>*/}
      {/*      </li>*/}
      {/*      <li>*/}
      {/*        SVE – Trigeminy*/}
      {/*        <span>{props.leftMenuDataObject.SVETrigeminy}</span>*/}
      {/*      </li>*/}
      {/*      <li>*/}
      {/*        SVE – Quadrigeminy*/}
      {/*        <span>{props.leftMenuDataObject.SVEQuadrigeminy}</span>*/}
      {/*      </li>*/}
      {/*    </ul>*/}
      {/*  )}*/}
      {/*</div>*/}

      {/*<div className="accordion-main">*/}
      {/*  <div*/}
      {/*    onClick={() => {*/}
      {/*      setShowHideVE(!showHideVE);*/}
      {/*    }}*/}
      {/*    className={`name-count color-4 ${*/}
      {/*      showHideVE === true ? "selected" : ""*/}
      {/*    }`}*/}
      {/*  >*/}
      {/*    <span className="h-l-1">VE</span>*/}
      {/*    <span className="h-l-2">{props.leftMenuDataObject.VE}</span>*/}
      {/*  </div>*/}
      {/*  {showHideVE && (*/}
      {/*    <ul className="color-4">*/}
      {/*      <li*/}
      {/*        onClick={() => {*/}
      {/*          props.setSelectedView(AppContext.view.Events);*/}
      {/*          props.setLeftMenuEventSelected(11);*/}
      {/*          props.setLeftMenuType(ECGClassification.Ventricular_Tachycardia_OR_SVT_with_aberration);*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        Isolated VE<span>{props.leftMenuDataObject.IsolatedVE}</span>*/}
      {/*      </li>*/}
      {/*      <li>*/}
      {/*        VE Pair<span>{props.leftMenuDataObject.VEPair}</span>*/}
      {/*      </li>*/}
      {/*      <li>*/}
      {/*        VE Run<span>{props.leftMenuDataObject.VERun}</span>*/}
      {/*      </li>*/}
      {/*      <li>*/}
      {/*        VE Run Longest<span>{props.leftMenuDataObject.VERunLongest}</span>*/}
      {/*      </li>*/}
      {/*      <li>*/}
      {/*        VE Run fastest<span></span>*/}
      {/*      </li>*/}
      {/*      <li>*/}
      {/*        VE - Bigeminy<span>{props.leftMenuDataObject.VEBigeminy}</span>*/}
      {/*      </li>*/}
      {/*      <li>*/}
      {/*        VE - Trigeminy<span>{props.leftMenuDataObject.VETrigeminy}</span>*/}
      {/*      </li>*/}
      {/*      <li>*/}
      {/*        VE - Quadrigeminy*/}
      {/*        <span>{props.leftMenuDataObject.VEQuadrigeminy}</span>*/}
      {/*      </li>*/}
      {/*    </ul>*/}
      {/*  )}*/}
      {/*</div>*/}

      {/*<div className="accordion-main">*/}
      {/*  <div*/}
      {/*    onClick={() => {*/}
      {/*      setShowHidePause(!showHidePause);*/}
      {/*      props.ShowAnnotationsDetail(AnnotationTagType.Pause);*/}
      {/*    }}*/}
      {/*    className={`name-count color-12 ${*/}
      {/*      showHidePause === true ? "selected" : ""*/}
      {/*    }`}*/}
      {/*  >*/}
      {/*    <span className="h-l-1">Pause</span>*/}
      {/*    <span className="h-l-2">{props.leftMenuDataObject.Pause}</span>*/}
      {/*  </div>*/}
      {/*  {showHidePause && (*/}
      {/*    <ul className="color-6">*/}
      {/*      <li*/}
      {/*        onClick={() => {*/}
      {/*          props.setSelectedView(AppContext.view.Events);*/}
      {/*          props.setLeftMenuEventSelected(11);*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        Longest – Pause<span></span>*/}
      {/*      </li>*/}
      {/*      <li*/}
      {/*        onClick={() => {*/}
      {/*          props.setSelectedView(AppContext.view.Events);*/}
      {/*          props.setLeftMenuEventSelected(11);*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        Longest – RR<span></span>*/}
      {/*      </li>*/}
      {/*      <li*/}
      {/*        onClick={() => {*/}
      {/*          props.setSelectedView(AppContext.view.Events);*/}
      {/*          props.setLeftMenuEventSelected(11);*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        Longest – NN<span></span>*/}
      {/*      </li>*/}
      {/*    </ul>*/}
      {/*  )}*/}
      {/*</div>*/}

      {/*<div className="accordion-main">*/}
      {/*  <div*/}
      {/*    onClick={() => {*/}
      {/*      setShowHideAVBlocks(!showHideAVBlocks);*/}
      {/*      props.ShowAnnotationsDetail(AnnotationTagType.AVB);*/}
      {/*    }}*/}
      {/*    className={`name-count color-8 ${*/}
      {/*      showHideAVBlocks === true ? "selected" : ""*/}
      {/*    }`}*/}
      {/*  >*/}
      {/*    <span className="h-l-1">AV blocks</span>*/}
      {/*    <span className="h-l-2">{props.leftMenuDataObject.AVBlocks}</span>*/}
      {/*  </div>*/}
      {/*  {showHideAVBlocks && (*/}
      {/*    <ul className="color-6">*/}
      {/*      <li*/}
      {/*      onClick={() => {*/}
      {/*          props.setSelectedView(AppContext.view.Events);*/}
      {/*          props.setLeftMenuEventSelected(11);*/}
      {/*          props.setLeftMenuType(ECGClassification.First_Degree_Heart_Block);*/}
      {/*        }}>*/}
      {/*        First degree AV block<span></span>*/}
      {/*      </li>*/}
      {/*      <li>*/}
      {/*        Second degree AV block type 1<span></span>*/}
      {/*      </li>*/}
      {/*      <li>*/}
      {/*        Second degree AV block type 2<span></span>*/}
      {/*      </li>*/}
      {/*      <li onClick={() => {*/}
      {/*          props.setSelectedView(AppContext.view.Events);*/}
      {/*          props.setLeftMenuEventSelected(11);*/}
      {/*          props.setLeftMenuType(ECGClassification.Third_Degree_Heart_Block);*/}
      {/*        }}>*/}
      {/*        Third degree AV block<span></span>*/}
      {/*      </li>*/}
      {/*      <li>*/}
      {/*        Advanced AV block <span></span>*/}
      {/*      </li>*/}
      {/*    </ul>*/}
      {/*  )}*/}
      {/*</div>*/}

      {/*<div className="accordion-main">*/}
      {/*  <div*/}
      {/*    onClick={() => {*/}
      {/*      setShowHidePacedRhythm(!showHideAVPacedRhythm);*/}
      {/*    }}*/}
      {/*    className={`name-count color-9 ${*/}
      {/*      showHideAVPacedRhythm === true ? "selected" : ""*/}
      {/*    }`}*/}
      {/*  >*/}
      {/*    <span className="h-l-1">Paced rhythm</span>*/}
      {/*    <span className="h-l-2">{props.leftMenuDataObject.PacedRhythm}</span>*/}
      {/*  </div>*/}
      {/*  {showHideAVPacedRhythm && (*/}
      {/*    <ul className="color-5">*/}
      {/*      <li>*/}
      {/*        Atrial paced rhythm<span></span>*/}
      {/*      </li>*/}
      {/*      <li>*/}
      {/*        Ventricular paced rhythm<span></span>*/}
      {/*      </li>*/}
      {/*      <li>*/}
      {/*        Dual Paced rhythm<span></span>*/}
      {/*      </li>*/}
      {/*    </ul>*/}
      {/*  )}*/}
      {/*</div>*/}
      {/*<div className="accordion-main">*/}
      {/*  <div*/}
      {/*    onClick={() => {*/}
      {/*      setShowHideNoise(!showHideNoise);*/}
      {/*    }}*/}
      {/*    className={`name-count color-13 ${*/}
      {/*      showHideNoise === true ? "selected" : ""*/}
      {/*    }`}*/}
      {/*  >*/}
      {/*    <span className="h-l-1">Noise</span>*/}
      {/*    <span className="h-l-2">{props.leftMenuDataObject.Noise}</span>*/}
      {/*  </div>*/}
      {/*  {showHideNoise && (*/}
      {/*    <ul className="color-13">*/}
      {/*      <li*/}
      {/*        onClick={() => {*/}
      {/*          props.setSelectedView(AppContext.view.Events);*/}
      {/*          props.setLeftMenuEventSelected(12);*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        X beats/lead disconnected <span></span>*/}
      {/*      </li>*/}
      {/*    </ul>*/}
      {/*  )}*/}
      {/*</div>*/}

      {/* <a onClick={() => props.ShowAnnotationsDetail(AnnotationTagType.AF)}>
        <span className="a--txt">AFib</span>
        <span className="a--count">
          {ReturnAnnotationsCount(AnnotationTagType.AF)}
        </span>
      </a>
      <a onClick={() => props.ShowAnnotationsDetail(AnnotationTagType.Pause)}>
        <span className="a--txt">Pauses</span>
        <span className="a--count">
          {ReturnAnnotationsCount(AnnotationTagType.Pause)}
        </span>
      </a>
      <a onClick={() => props.ShowAnnotationsDetail(AnnotationTagType.Noise)}>
        <span className="a--txt">Noises</span>
        <span className="a--count">
          {ReturnAnnotationsCount(AnnotationTagType.Noise)}
        </span>
      </a>
      <a onClick={() => props.ShowAnnotationsDetail(AnnotationTagType.AVB)}>
        <span className="a--txt">AV Block</span>
        <span className="a--count">
          {ReturnAnnotationsCount(AnnotationTagType.AVB)}
        </span>
      </a>
      <a onClick={() => props.ShowAnnotationsDetail(AnnotationTagType.AT)}>
        <span className="a--txt">AT</span>
        <span className="a--count">
          {ReturnAnnotationsCount(AnnotationTagType.AT)}
        </span>
      </a>
      <a onClick={() => props.ShowAnnotationsDetail(AnnotationTagType.SVT)}>
        <span className="a--txt">SVT</span>
        <span className="a--count">
          {ReturnAnnotationsCount(AnnotationTagType.SVT)}
        </span>
      </a>
      <a onClick={() => props.ShowAnnotationsDetail(AnnotationTagType.VE)}>
        <span className="a--txt">VE</span>
        <span className="a--count--sm">
          {GetEctopiesCount(1)}(00d 00h 00m 48 S)
        </span>
      </a>
      <a onClick={() => props.ShowAnnotationsDetail(AnnotationTagType.SVE)}>
        <span className="a--txt">SVE</span>
        <span className="a--count--sm">
          {GetEctopiesCount(2)}(00d 00h 00m 48 S)
        </span>
      </a>
      <div className="ecg--left__measure no-display">Measurements</div>
      <div className="ecg--left__dimens no-display">
        <div className="ecg--left__dimens__inn custom--scroll2">
          {props.allMeasurements.map((Item: any, index: number) => (
            <React.Fragment key={Item.ID}>
              <span onClick={() => ShowDeleteOrClearAllDivMeasurement(Item.ID)}>
                {Item.Duration} Seconds , {Item.BPM} bpm
                <>
                  {Item.DeleteOrClearAll && (
                    <div className="del--clear del--clear--2">
                      <a
                        onClick={() =>
                          props.ViewMeasurement(Item.StartTime, index)
                        }
                      >
                        View
                      </a>
                      <a onClick={() => DeleteMeasurement(Item.ID)}>Delete</a>
                      <a onClick={() => ClearAllMeasurement()}>Clear All</a>
                    </div>
                  )}
                </>
              </span>
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className="ecg--left__measure no-display">Diagnosis</div>
      <div className="ecg--left__measure">Stats</div>
      <div className="ecg--stats">
        <div className="ecg--stats__row">
          <div className="stats--name">HR</div>
          <div className="stats--min">
            <span className="txt--min">Min</span>
            <span className="txt--min2">{props.minBPM}</span>
          </div>
          <div className="stats--min">
            <span className="txt--min">Max</span>
            <span className="txt--min2">{props.maxBPM}</span>
          </div>
          <div className="stats--bpm">bpm</div>
        </div>
        <div className="ecg--stats__row">
          <div className="stats--name">PR</div>
          <div className="stats--min">
            <span className="txt--min2">0.13</span>
          </div>
          <div className="stats--min">
            <span className="txt--min2">0.14</span>
          </div>
          <div className="stats--sec">sec</div>
        </div>
        <div className="ecg--stats__row">
          <div className="stats--name">QRS</div>
          <div className="stats--min">
            <span className="txt--min2">0.07</span>
          </div>
          <div className="stats--min">
            <span className="txt--min2">0.08</span>
          </div>
          <div className="stats--sec">sec</div>
        </div>
        <div className="ecg--stats__row">
          <div className="stats--name">QT</div>
          <div className="stats--min">
            <span className="txt--min2">0.36</span>
          </div>
          <div className="stats--min">
            <span className="txt--min2">0.37</span>
          </div>
          <div className="stats--sec">sec</div>
        </div>
      </div>
      <div className="ecg--left__measure">Observation</div>
      <span className="observation">The Patient has predominant AFib</span> */}
    </div>
  );
}

export default LeftMenu;
