import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import * as Utility from "../../Shared/Utility";
import { Annotation } from "../../Model/Annotation";
import * as d3 from "d3";
import AppContext from "../../Context/Context";
type StripsPanelProps = {
  allAnnotations: any;
  selectedDataSetAll: any;
  mmvValueSplitView: any;
  showStrips: any;
  selectedView: any;
};

function StripsPanel(props: StripsPanelProps) {
  const AnnotationTagType = {
    AF: 1,
    Pause: 2,
    Noise: 3,
    AVB: 4,
    AT: 5,
    SVT: 6,
    VE: 7,
    SVE: 8,
    N: 9,
  };

  const [StripsArray, setStripsArray] = useState<any>([]);
  const [StripsAllAnnotations, setStripsAllAnnotations] = useState<any>([]);
  const [TotalCountOfSelected, setTotalCountOfSelected] = useState<any>(0);

  useEffect(() => {
    
    let StripsArray = [] as any;

    for (let i = 0; i < props.allAnnotations.length; i++) {
      let StripsObject = {} as any;

      if (props.allAnnotations[i].Type === AnnotationTagType.AF) {
        StripsObject.Type = AnnotationTagType.AF;
        StripsObject.IsShow = false;
        StripsObject.DisplayText = "AFib";
      } else if (props.allAnnotations[i].Type === AnnotationTagType.Pause) {
        StripsObject.Type = AnnotationTagType.Pause;
        StripsObject.IsShow = false;
        StripsObject.DisplayText = "Pauses";
      } else if (props.allAnnotations[i].Type === AnnotationTagType.Noise) {
        StripsObject.Type = AnnotationTagType.Noise;
        StripsObject.IsShow = false;
        StripsObject.DisplayText = "Noises";
      } else if (props.allAnnotations[i].Type === AnnotationTagType.AVB) {
        StripsObject.Type = AnnotationTagType.AVB;
        StripsObject.IsShow = false;
        StripsObject.DisplayText = "AV Block";
      } else if (props.allAnnotations[i].Type === AnnotationTagType.AT) {
        StripsObject.Type = AnnotationTagType.AT;
        StripsObject.IsShow = false;
        StripsObject.DisplayText = "AT";
      } else if (props.allAnnotations[i].Type === AnnotationTagType.SVT) {
        StripsObject.Type = AnnotationTagType.SVT;
        StripsObject.IsShow = false;
        StripsObject.DisplayText = "SVT";
      } else if (props.allAnnotations[i].Type === AnnotationTagType.N) {
        StripsObject.Type = AnnotationTagType.N;
        StripsObject.IsShow = false;
        StripsObject.DisplayText = "Normal";
      }

      StripsArray.push(StripsObject);
    }

    let UniqueStripsArray = StripsArray.filter(
      (a: any, i: any) =>
        StripsArray.findIndex((s: any) => a.Type === s.Type) === i
    );

    setStripsArray(UniqueStripsArray);
    SetArrayForBinding();
  }, [props.allAnnotations]);

  function ShowHideAccordion(p_intIndex: any) {
    const l_StripsArray = [...StripsArray] as any;
    l_StripsArray[p_intIndex].IsShow = !l_StripsArray[p_intIndex].IsShow;
    setStripsArray(l_StripsArray);
  }

  function SetArrayForBinding() {
    const StripsArray = [...props.allAnnotations] as any;
    for (let i = 0; i < StripsArray.length; i++) {
      StripsArray[i].IsChecked = true;

      if ((StripsArray[i].Type !== AnnotationTagType.AF)) {
        StripsArray[i].IsStripe = true;
      }
      if (StripsArray[i].Duration > 10) {
        StripsArray[i].Count = 1;
        StripsArray[i].TotalCount = Math.ceil(
          props.allAnnotations[i].Duration / 10
        );
        let count = 0;
        for (let j = 0; j < StripsArray[i].TotalCount; j++) {
          if (StripsArray[i].TotalCount === j + 1) {
            StripsArray[i].LastValue = Math.floor(
              props.allAnnotations[i].Duration - count
            );
          } else {
            count += 10;
          }
        }

        StripsArray[i].StartingSeconds = 0;
      } else {
        StripsArray[i].Count = 1;
        StripsArray[i].TotalCount = 1;
        StripsArray[i].StartingSeconds = 0;
      }
    }

    setTotalCountOfSelected(ReturnAnnotationsCount(AnnotationTagType.AF)); //Need to bind with left menu
    setStripsAllAnnotations(StripsArray);
  }

  function BindStripPanel(param: any, Id: any) {
    if (param === 1) {
      //Next button click
      for (let i = 0; i < StripsAllAnnotations.length; i++) {
        if (StripsAllAnnotations[i].ID === Id) {
          StripsAllAnnotations[i].Count++;
          if (
            StripsAllAnnotations[i].Count === StripsAllAnnotations[i].TotalCount
          ) {
            StripsAllAnnotations[i].StartingSeconds += 10;
            //              StripsAllAnnotations[i].LastValue;
          } else {
            StripsAllAnnotations[i].StartingSeconds += 10; //StripsAllAnnotations[i].StartTime + 10;
          }
          break;
        }
      }
    } else if (param === -1) {
      //Previous button click
      for (let i = 0; i < StripsAllAnnotations.length; i++) {
        if (StripsAllAnnotations[i].ID === Id) {
          if (
            StripsAllAnnotations[i].Count === StripsAllAnnotations[i].TotalCount
          ) {
            StripsAllAnnotations[i].StartingSeconds -=
              StripsAllAnnotations[i].LastValue;
          } else {
            StripsAllAnnotations[i].StartingSeconds -= 10; //StripsAllAnnotations[i].StartTime - 10;
          }
          StripsAllAnnotations[i].Count--;
          break;
        }
      }
    }
    let l_StripsArray = [...StripsAllAnnotations] as any;
    setStripsAllAnnotations(l_StripsArray);
  }

  useEffect(() => {
    if (
      StripsAllAnnotations.length > 0 &&
      props.showStrips &&
      props.selectedView === AppContext.view.Strips
    ) {
      // setTimeout(() => {

      loadStripsGraph();
      // }, 1000);
    }
  }, [StripsAllAnnotations, StripsArray, props.showStrips, props.selectedView]);

  function loadStripsGraph() {
    for (let index = 0; index < StripsArray.length; index++) {
      for (let i = 0; i < StripsAllAnnotations.length; i++) {
        if (
          StripsArray[index].Type === StripsAllAnnotations[i].Type &&
          StripsArray[index].IsShow
        ) {
          let boxSizeWidth = 440;
          let boxSizeHeight = 100;

          let stripGraph = d3
            .select("#" + "StripsId" + i)
            .attr("width", boxSizeWidth)
            .attr("height", boxSizeHeight)
            .style("overflow", "hidden");

          // These will be the start and End time of the data in the box
          let startTime = 0;
          let endTime = 10;

          let l_startTime =
            StripsAllAnnotations[i].StartTime +
            StripsAllAnnotations[i].StartingSeconds;
          let l_EndTime = l_startTime + 10;
          let Range = getBeatDataInRange(l_startTime, l_EndTime) as any;

          startTime = Range[0][0];
          endTime = Range[Range.length - 1][0];

          // setting the scaling
          let xScaleBox = d3
            .scaleLinear()
            .domain([startTime, endTime])
            .range([0, boxSizeWidth]);

          let localYRange = 30000 / props.mmvValueSplitView;

          let yScaleBox = d3
            .scaleLinear()
            .domain([-localYRange, localYRange])
            .range([boxSizeHeight, 0]);

          let uniqueID = "StripsId" + i; // StripsAllAnnotations[i].ID;
          d3.selectAll("." + uniqueID).remove();

          // Define the pattern for the image
          const pattern = stripGraph
            .append("defs")
            .append("pattern")
            .attr("class", uniqueID)
            .attr("id", "image-pattern")
            .attr("x", 0)
            .attr("y", 0)
            .attr("width", boxSizeHeight * 2)
            .attr("height", boxSizeHeight)
            .attr("patternUnits", "userSpaceOnUse")
            .append("image")
            .attr("xlink:href", "/images/Grid3.png")
            .attr("width", boxSizeHeight * 2)
            .attr("height", boxSizeHeight);

          // Append a rectangle element with the image pattern
          stripGraph
            .append("rect")
            .attr("class", uniqueID)
            .attr("x", 0)
            .attr("y", 0)
            .attr("width", boxSizeWidth)
            .attr("height", boxSizeHeight)
            .attr("fill", "url(#image-pattern)");

          if (Range.length > 0) {
            for (let j = 1; j < Range.length; j++) {
              stripGraph
                .append("line")
                .attr("class", uniqueID)
                .style("stroke", "green")
                .attr("x1", xScaleBox(Range[j - 1][0]))
                .attr("y1", yScaleBox(Range[j - 1][1]))
                .attr("x2", xScaleBox(Range[j][0]))
                .attr("y2", yScaleBox(Range[j][1]));
            }
          }

          let startX = xScaleBox(StripsAllAnnotations[i].StartTime);
          let endX = xScaleBox(
            StripsAllAnnotations[i].StartTime + StripsAllAnnotations[i].Duration
          );
          let width = endX - startX;
          console.log(
            "starting seconds: " + StripsAllAnnotations[i].StartingSeconds
          );
          console.log("startX : " + startX + " , width: " + width);

          stripGraph
            .append("rect")
            .attr("class", uniqueID)
            .attr("x", startX)
            .attr("y", 0)
            .attr("width", width)
            .attr("height", boxSizeHeight)
            .attr("fill", "rgba(250, 160, 160,0.4)");
        }
      }
    }
  }

  function getBeatDataInRange(StartTime: any, EndTime: any) {
    var StartTimeMinDiff = 1000;
    var StartTimeNearestBeat;
    var StartTimeNearestBeatIndex = -1;

    var EndTimeMinDiff = 1000;
    var EndTimeNearestBeat;
    var EndTimeNearestBeatIndex = -1;

    var StartMainIndex = -1;
    var EndMainIndex = -1;

    for (let i = 0; i < props.selectedDataSetAll.length; i++) {
      for (let j = 0; j < props.selectedDataSetAll[i].data.length; j++) {
        var StartTimeMin = Math.abs(
          StartTime - props.selectedDataSetAll[i].data[j][0]
        );
        if (StartTimeMin < StartTimeMinDiff) {
          StartTimeMinDiff = StartTimeMin;
          StartTimeNearestBeat = props.selectedDataSetAll[i].data[j][0];
          StartTimeNearestBeatIndex = j;
          StartMainIndex = i;
        }

        var EndTimeMin = Math.abs(
          EndTime - props.selectedDataSetAll[i].data[j][0]
        );
        if (EndTimeMin < EndTimeMinDiff) {
          EndTimeMinDiff = EndTimeMin;
          EndTimeNearestBeat = props.selectedDataSetAll[i].data[j][0];
          EndTimeNearestBeatIndex = j;
          EndMainIndex = i;
        }
      }
    }
    let ReturnArray = [];
    if (
      StartMainIndex > -1 &&
      EndMainIndex > -1 &&
      StartMainIndex === EndMainIndex
    ) {
      ReturnArray = props.selectedDataSetAll[StartMainIndex].data.slice(
        StartTimeNearestBeatIndex,
        EndTimeNearestBeatIndex + 1
      );
    } else if (
      StartMainIndex > -1 &&
      EndMainIndex > -1 &&
      StartMainIndex !== EndMainIndex
    ) {
      let StartArray = props.selectedDataSetAll[StartMainIndex].data.slice(
        StartTimeNearestBeatIndex,
        props.selectedDataSetAll[StartMainIndex].data.length
      );
      let EndArray = props.selectedDataSetAll[EndMainIndex].data.slice(
        0,
        EndTimeNearestBeatIndex + 1
      );
      ReturnArray = StartArray.concat(EndArray);
    }

    return ReturnArray;
  }

  function getPeakDataInRange(StartTime: any, EndTime: any) {
    var StartTimeMinDiff = 1000;
    var StartTimeNearestBeat;
    var StartTimeNearestBeatIndex = -1;

    var EndTimeMinDiff = 1000;
    var EndTimeNearestBeat;
    var EndTimeNearestBeatIndex = -1;

    var StartMainIndex = -1;
    var EndMainIndex = -1;

    for (let i = 0; i < props.selectedDataSetAll.length; i++) {
      for (let j = 0; j < props.selectedDataSetAll[i].peaks.length; j++) {
        var StartTimeMin = Math.abs(
          StartTime - props.selectedDataSetAll[i].peaks[j].value[0]
        );
        if (StartTimeMin < StartTimeMinDiff) {
          StartTimeMinDiff = StartTimeMin;
          StartTimeNearestBeat = props.selectedDataSetAll[i].peaks[j].value[0];
          StartTimeNearestBeatIndex = j;
          StartMainIndex = i;
        }

        var EndTimeMin = Math.abs(
          EndTime - props.selectedDataSetAll[i].peaks[j].value[0]
        );
        if (EndTimeMin < EndTimeMinDiff) {
          EndTimeMinDiff = EndTimeMin;
          EndTimeNearestBeat = props.selectedDataSetAll[i].peaks[j].value[0];
          EndTimeNearestBeatIndex = j;
          EndMainIndex = i;
        }
      }
    }
    let ReurnArray = [];
    if (
      StartMainIndex > -1 &&
      EndMainIndex > -1 &&
      StartMainIndex === EndMainIndex
    ) {
      ReurnArray = props.selectedDataSetAll[StartMainIndex].peaks.slice(
        StartTimeNearestBeatIndex,
        EndTimeNearestBeatIndex + 1
      );
    } else if (
      StartMainIndex > -1 &&
      EndMainIndex > -1 &&
      StartMainIndex !== EndMainIndex
    ) {
      let StartArray = props.selectedDataSetAll[StartMainIndex].peaks.slice(
        StartTimeNearestBeatIndex,
        props.selectedDataSetAll[StartMainIndex].peaks.length
      );
      let EndArray = props.selectedDataSetAll[EndMainIndex].peaks.slice(
        0,
        EndTimeNearestBeatIndex + 1
      );
      ReurnArray = StartArray.concat(EndArray);
    }

    return ReurnArray;
  }

  function ReturnAnnotationsCount(p_intType: any) {
    let l_intCount = 0;
    for (let i = 0; i < props.allAnnotations.length; i++) {
      if (
        props.allAnnotations[i].Type === p_intType &&
        props.allAnnotations[i].IsStripe
      ) {
        l_intCount++;
      }
    }
    return l_intCount;
  }

  function ReturnSelectedCheckBoxCount(p_intType: any) {
    let l_intCount = 0;
    for (let i = 0; i < StripsAllAnnotations.length; i++) {
      if (
        StripsAllAnnotations[i].Type === p_intType &&
        StripsAllAnnotations[i].IsStripe &&
        StripsAllAnnotations[i].IsChecked
      ) {
        l_intCount++;
      }
    }
    return l_intCount;
  }

  function updateTotalCountOfSelected(event: any, index: any, Type: any) {
    let l_TotalCountOfSelected = TotalCountOfSelected;
    if (event.target.checked) {
      l_TotalCountOfSelected++;
      setTotalCountOfSelected(l_TotalCountOfSelected);
      StripsAllAnnotations[index].IsChecked = true;
    } else {
      l_TotalCountOfSelected--;
      setTotalCountOfSelected(l_TotalCountOfSelected);
      StripsAllAnnotations[index].IsChecked = false;
    }
  }

  return (
    <>
      <div className="strips--accordion custom--scroll2">
        {/* Need to bind with left menu */}
        {StripsArray &&
          StripsArray.map((StripsItem: any, Stripsindex: any) => (
            <React.Fragment key={Stripsindex}>
              <div
                className={`strip--main-no-shadow ${
                  StripsItem.IsShow ? "strip--main-shadow" : ""
                }`}
              >
                <span
                  className="templates--left--head"
                  onClick={() => ShowHideAccordion(Stripsindex)}
                >
                  <span className="template--Title">
                    <span className="type-count">{Stripsindex + 1}</span>
                    <span className="type-name">
                      {StripsItem.DisplayText}{" "}
                      <span className="blue-color">
                        (Total: {ReturnAnnotationsCount(StripsItem.Type)},
                        Selected: {ReturnSelectedCheckBoxCount(StripsItem.Type)}
                        )
                      </span>
                    </span>
                  </span>
                  <a className="template--accord">
                    {StripsItem.IsShow ? (
                      <img
                        src="/images/a-down.svg"
                        className="rotate-180"
                        alt="up"
                        width="6"
                        height="12"
                      />
                    ) : (
                      <img
                        src="/images/a-down.svg"
                        alt="down"
                        width="6"
                        height="12"
                      />
                    )}
                  </a>
                </span>
                {StripsItem.IsShow && (
                  <>
                    {StripsAllAnnotations &&
                      StripsAllAnnotations.map((Item: any, index: any) => (
                        <React.Fragment key={Item.ID}>
                          {Item.Type === StripsItem.Type && Item.IsStripe && (
                            <div className="single--strip">
                              <div className="strip--head">
                                <span>
                                  {/* {Item.StartingSeconds} ---  */}
                                  {Utility.ConvertSecondsIntoTimeFormat(
                                    Item.StartTime
                                  )}
                                  ,{" "}
                                  {Utility.ConvertSecondsIntoDayTimeFormat(
                                    Item.Duration
                                  )}{" "}
                                  &#9202;, {Item.Beats} &#10084;,{" "}
                                  {Item.getBPMWithUnit()} ,
                                  <button
                                    className="btn--prenex"
                                    onClick={() => BindStripPanel(-1, Item.ID)}
                                    disabled={
                                      Item.TotalCount <= 1 || Item.Count <= 1
                                    }
                                  >
                                    <img
                                      src="/images/prenex.svg"
                                      width="15px"
                                      height="15px"
                                      alt="prev"
                                      className="rotate-180"
                                    />
                                  </button>
                                  {Item.Count} / {Item.TotalCount}
                                  <button
                                    className="btn--prenex"
                                    disabled={Item.TotalCount === Item.Count}
                                    onClick={() => BindStripPanel(1, Item.ID)}
                                  >
                                    <img
                                      src="/images/prenex.svg"
                                      width="15px"
                                      height="15px"
                                      alt="next"
                                    />
                                  </button>
                                </span>
                                <input
                                  type="checkbox"
                                  checked={Item.IsChecked}
                                  onClick={(e) =>
                                    updateTotalCountOfSelected(
                                      e,
                                      index,
                                      StripsItem.Type
                                    )
                                  }
                                />
                              </div>
                              <div className="strip--data">
                                <svg id={"StripsId" + index}></svg>
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                  </>
                )}
              </div>
            </React.Fragment>
          ))}
      </div>
    </>
  );
}

export default StripsPanel;
