import Box from "../Box";
import React, { useState } from "react";
//import PatientReportLayout from './../PatientReportLayout'
import MCTSummaryReport from "./ReportsType/MCTSummaryReport";


export default function ReportsPage() {
  return (
    //<PatientReportLayout>
    <>
      <TechnicianComments />
      <MCTSummaryReport />
    </>
    // </PatientReportLayout>
  );
}


function TechnicianComments() {
  const [comments, setComments] = useState("");
  

  const handleChange = (event) => {
    setComments(event.target.value);
  };

  

  return (
    <Box width="w-full" pb="pt-8" pt="0" px="0" style={{ width: "100%" }}>
      <h2 className="flex items-center text-xl font-bold px-[74px]">
        Technician Comments
      </h2>
      <div className="px-[74px] pt-6 pb-16 text-[#616161]">
        <p>
          <textarea
            placeholder="Enter comments separated by line breaks"
            value={comments}
            onChange={handleChange}
            style={{ width: "800px", height: "200px" }}
            className="p-2"
          />
        </p>
       
      </div>
    </Box>
  );
}


