import create from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

export const graphsStore = create(
  persist((set) => ({
    minimumSinusRateState: false,
    toggleMinimumSinusRate: () =>
      set((state) => ({ minimumSinusRateState: !state.minimumSinusRateState })),
    //
    firstDegreeAVBlockState: false,
    toggleFirstDegreeAVBlock: () =>
      set((state) => ({
        firstDegreeAVBlockState: !state.firstDegreeAVBlockState,
      })),
    //
    sinusArrhythmiaState: false,
    toggleSinusArrhythmia: () =>
      set((state) => ({
        sinusArrhythmiaState: !state.sinusArrhythmiaState,
      })),
    //
    sinusRhythmState: false,
    toggleSinusRhythm: () =>
      set((state) => ({
        sinusRhythmState: !state.sinusRhythmState,
      })),
    //
    atrialFibrillationState: false,
    toggleAtrialFibrillation: () =>
      set((state) => ({
        atrialFibrillationState: !state.atrialFibrillationState,
      })),
    //
    dayAndNightBurdenState: false,
    toggleDayAndNightBurden: () =>
      set((state) => ({
        dayAndNightBurdenState: !state.dayAndNightBurdenState,
      })),
    //
    pausesState: false,
    togglePauses: () =>
      set((state) => ({
        pausesState: !state.pausesState,
      })),
    //
    longestPauseState: false,
    toggleLongestPause: () =>
      set((state) => ({
        longestPauseState: !state.longestPauseState,
      })),
    //
  }), {
    storage: createJSONStorage(() => sessionStorage)
  }),
)
